import { Maybe } from 'monet';

import { DIALOG_IN_PROGRESS_INITIAL_STATE, IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';
import { DateRange } from '../../utils/dateRangeFilter';

import { MessageInformationDialogState, RawNotificationsListingState } from './entities';

const rawNotificationsListingState: RawNotificationsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  rawNotifications: Maybe.None(),
  exportedRawNotifications: Maybe.None(),
  filter: Maybe.Some({ updatedAt: Maybe.None<DateRange>() }),
  hiddenColumns: [],
  sort: Maybe.None(),
  id: Maybe.None(),
};

const messageInformationDialogState: MessageInformationDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  rawNotification: Maybe.None(),
  subject: Maybe.None(),
  content: Maybe.None(),
};
export const RAW_NOTIFICATION_INITIAL_STATE = {
  rawNotificationsListingState,
  messageInformationDialogState,
};
