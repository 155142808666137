import { UserRole } from '../../auth/entities';

export enum RouteName {
  HOME = 'Home',
  PROFILE = 'Profile',
  EVENTS = 'Events',
  ALERTS = 'Alerts',
  NOTIFICATIONS_STATUS = 'NotificationsStatus',
  ASSETS = 'Assets',
  MONITORING_SYSTEMS = 'MonitoringSystems',
  DATADOG_DASHBOARD = 'DataDogDashboard',
}

export interface RouteConfig {
  name: RouteName;
  title?: string;
  path: string;
  icon: string;
  componentName: string;
  layout: string;
  allowedForRoles: UserRole[];
}

const DASHBOARD_LAYOUT_PATH = '/dashboard';

/**
 * Take icons here: https://demos.creative-tim.com/light-bootstrap-dashboard-pro/examples/components/icons.html
 */

export const routesList: RouteConfig[] = [
  {
    path: '',
    name: RouteName.HOME,
    icon: 'nc-icon nc-bank',
    componentName: RouteName.HOME.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  /*
  {
    path: '/events',
    name: RouteName.EVENTS,
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.EVENTS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.ADMIN],
  },
  */
  {
    path: '/alerts',
    name: RouteName.ALERTS,
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.ALERTS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/notifications-status',
    name: RouteName.NOTIFICATIONS_STATUS,
    title: 'Notifications Status',
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.NOTIFICATIONS_STATUS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/assets',
    name: RouteName.ASSETS,
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.ASSETS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/monitoring-systems',
    name: RouteName.MONITORING_SYSTEMS,
    title: 'Monitoring Systems',
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.MONITORING_SYSTEMS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/dd',
    name: RouteName.DATADOG_DASHBOARD,
    title: 'MEP DD Metrics',
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.DATADOG_DASHBOARD.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.ADMIN],
  },
  /*
  {
    path: '/profile',
    name: RouteName.PROFILE,
    icon: 'nc-icon nc-single-02',
    componentName: RouteName.PROFILE.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  */
];
