/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-empty-function */
import { MonitoringSystem, MonitoringSystemType, WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION } from '@serverfarm/nocd-commons';
// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import { Maybe, Some, None } from 'monet';
import * as React from 'react';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
// @ts-ignore
import { ClearButton, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import { CSVLink } from 'react-csv';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
// @ts-ignore: No declarations available
import RSA from 'react-simple-auth';
import { Badge, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import io, { Socket } from 'socket.io-client';

import { incommand as incommandProvider } from '../../auth/providers';
import config from '../../config';
import { State } from '../../reducers';
import { messageForUserReloadThePage, openAssetMaintenanceDialog } from '../assets';
import { showErrorNotification, showInfoNotification } from '../notification';
import { Site, SitesListingState } from '../redux/entities';
import { fetchSites, fetchSitesGroups } from '../sites';
import { UserState, logoutUser } from '../user';
import { DateTimeRender, withClickConfirmation } from '../utils';
import { getBadgeStatusColor } from '../utils/colors';
import { ServerfarmTable } from '../utils/serverfarmTable';

import {
  deleteMonitoringSystem,
  editMonitoringSystem,
  saveMonitoringSystem,
  fetchExportedMonitoringSystems,
  fetchMonitoringSystems,
  resetExportedMonitoringSystems,
  resetMonitoringSystems,
  updateMonitoringSystemListingFilter,
  updateMonitoringSystemListingHiddenColumns,
  updateMonitoringSystemListingSort,
  openMonitoringSystemIncidentInformation,
} from './data/monitoring-systems/actions';
import { ListSort } from './entities';
import { MonitoringSystemDeletingState, MonitoringSystemSavingState, MonitoringSystemsListingState } from './redux/entities';

const logger = logdown('component:MonitoringSystemsList');

const ButtonWithConfirmation = withClickConfirmation(Button);

class ActionsFormatter extends React.Component<Props & MonitoringSystemListsProps & { monitoringSystem: any }> {
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
        {' '}
        <Button
          data-testid="edit-button"
          className="edit-button"
          id={`edit-button-${this.props.monitoringSystem.id}`}
          color="success"
          size="sm"
          onClick={() => this.props.editMonitoringSystem(Maybe.Some(this.props.monitoringSystem))}
        >
          <Icon.PencilSquare className="sm" />
        </Button>{' '}
        <ButtonWithConfirmation
          data-testid="enable-disable-button"
          className="enale-disable-button"
          style={{ backgroundColor: !!this.props.monitoringSystem.active ? '#6bd098' : '#ef8157' }}
          id={`enable-disable-button-${this.props.monitoringSystem.id}`}
          size="sm"
          onClick={() => {
            this.props.saveMonitoringSystem(
              { ...this.props.monitoringSystem, active: !!!this.props.monitoringSystem.active },
              !this.props.monitoringSystem.id,
            );
          }}
          popoverCaption={this.props.monitoringSystem.active ? 'Put in Maintenance?' : 'Put out of Maintenance?'}
          popoverOkButton="Yes"
          popoverCancelButton="No"
        >
          {this.props.monitoringSystem.active ? <Icon.CheckCircle className="sm" /> : <Icon.Tools className="sm" />}
        </ButtonWithConfirmation>
        <ButtonWithConfirmation
          data-testid="delete-button"
          className="delete-button"
          id={`delete-button-${this.props.monitoringSystem.id}`}
          style={{ backgroundColor: '#dc0000' }}
          size="sm"
          disabled={
            this.props.monitoringSystemDeleting.isInProgress &&
            this.props.monitoringSystemDeleting.monitoringSystemId.contains(this.props.monitoringSystem.id)
          }
          onClick={() => this.props.deleteMonitoringSystem(this.props.monitoringSystem.id)}
          popoverCaption="Delete Monitoring System?"
          popoverOkButton="Yes"
          popoverCancelButton="No"
        >
          {this.props.monitoringSystemDeleting.isInProgress &&
          this.props.monitoringSystemDeleting.monitoringSystemId.contains(this.props.monitoringSystem.id) ? (
            <Spinner size="sm" />
          ) : (
            <Icon.TrashFill className="sm" />
          )}
        </ButtonWithConfirmation>
        {/* {doesTargetExist(`edit-button-${this.props.monitoringSystem.id}`) && (
          <UncontrolledTooltip target={`edit-button-${this.props.monitoringSystem.id}`}>Edit Monitoring System</UncontrolledTooltip>
        )}
        {doesTargetExist(`enable-disable-button-${this.props.monitoringSystem.id}`) && (
          <UncontrolledTooltip target={`enable-disable-button-${this.props.monitoringSystem.id}`}>Change Active Status</UncontrolledTooltip>
        )}
        {doesTargetExist(`delete-button-${this.props.monitoringSystem.id}`) && (
          <UncontrolledTooltip target={`delete-button-${this.props.monitoringSystem.id}`}>Delete Monitoring System</UncontrolledTooltip>
        )} */}
      </div>
    );
  }
}

const SiteColumnFilter = (props: Props & MonitoringSystemListsProps) => (/*params: any*/) => {
  const selectedOption = props.monitoringSystemsListing.filter
    .map((dsf): any[] => {
      let fullSites: any[] = [];
      if (dsf.sitesGroupId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesGroupsListing.sitesGroups
            .flatMap((sitesGroups) => Maybe.fromUndefined(sitesGroups.filter((s) => dsf.sitesGroupId?.find((a) => a === s.id))))
            .getOrElse([]),
        ];
      }
      if (dsf.siteId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesListing.sites
            .flatMap((sites: Site[]) => {
              return Maybe.fromUndefined(sites.filter((s) => dsf.siteId?.find((a) => a === s.id)));
            })
            .getOrElse([]),
        ];
      }
      return fullSites;
    })
    .orJust([]);
  // const selectedOption: any[] = [];
  const options = [...props.sitesListing.sites.getOrElse([]), ...props.sitesGroupsListing.sitesGroups.getOrElse([])];
  return (
    <div style={{ width: '180px' }}>
      <Typeahead
        className="unset-heigth"
        multiple={true}
        data-testid="data-source-filter-sites-groups"
        id="data-source-filter-sites-groups"
        onChange={(selected: any) => {
          const siteId: number[] = [];
          const sitesGroupId: number[] = [];
          let sites: string[] = [];
          if (selected && selected.length) {
            for (let i = 0; i < selected.length; i++) {
              if (selected[i].sites) {
                sitesGroupId.push(selected[i].id);
                //providers.push(selected[i].sites.map((s: Site) => s.monitorSiteId));
                sites = [...sites, ...selected[i].sites.map((s: Site) => s.monitorSiteId)];
              } else {
                siteId.push(selected[i].id);
                sites.push(selected[i].monitorSiteId);
              }
            }
          }
          const filter = props.monitoringSystemsListing.filter.map((filter: any) => ({
            ...filter,
            sitesGroupId,
            siteId,
            sites,
          }));
          props.updateMonitoringSystemListingFilter(
            filter,
            // props.monitoringSystemsListing.page,
            0,
            props.monitoringSystemsListing.sizePerPage,
          );
        }}
        options={options}
        emptyLabel="No Sites Groups found"
        defaultSelected={selectedOption}
        labelKey={(sg: any) => (sg && sg.sites ? `${sg.company.name} - ${sg.name}` : sg.name)}
        disabled={props.monitoringSystemsListing.isInProgress}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => {
              const key = `data-source-filter-sites-groups-item-${index}`;
              const value = result && result.sites ? `${result.company.name} - ${result.name}` : result.name;
              return (
                <MenuItem key={key} option={result} position={index}>
                  <span className="rolling-text">{value}</span>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      >
        {({
          selected,
        }: {
          selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
        }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={() => {
                  const siteId: number[] = [];
                  const sitesGroupId: number[] = [];
                  const sites: string[] = [];
                  const filter = props.monitoringSystemsListing.filter.map((filter: any) => ({
                    ...filter,
                    sitesGroupId,
                    siteId,
                    sites,
                  }));
                  props.updateMonitoringSystemListingFilter(filter, props.monitoringSystemsListing.page, props.monitoringSystemsListing.sizePerPage);
                }}
              />
            </div>
          )
        }
      </Typeahead>
    </div>
  );
};

const TypeColumnFilter = (props: Props & MonitoringSystemListsProps) => (/*params: any*/) => {
  const selectedOption = props.monitoringSystemsListing.filter
    .map((dsf): any[] => {
      let fullProviders: any[] = [];
      if (dsf.providers?.length) {
        fullProviders = [...dsf.providers];
      }
      return fullProviders;
    })
    .orJust([]);
  // const selectedOption: any[] = [];
  return (
    <div style={{ width: '180px' }}>
      <Typeahead
        className="unset-heigth"
        multiple={true}
        data-testid="data-source-filter-providers"
        id="data-source-filter-providers"
        onChange={(selected: any) => {
          const providers: string[] = [];
          if (selected && selected.length) {
            for (let i = 0; i < selected.length; i++) {
              providers.push(selected[i]);
            }
          }
          const filter = props.monitoringSystemsListing.filter.map((filter: any) => ({
            ...filter,
            providers,
          }));
          props.updateMonitoringSystemListingFilter(
            filter,
            // props.monitoringSystemsListing.page,
            0,
            props.monitoringSystemsListing.sizePerPage,
          );
        }}
        options={Object.values(MonitoringSystemType)}
        emptyLabel="No Provider found"
        defaultSelected={selectedOption}
        disabled={props.monitoringSystemsListing.isInProgress}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => {
              const key = `data-source-filter-providers-item-${index}`;
              const value = result;
              return (
                <MenuItem key={key} option={result} position={index}>
                  <span className="rolling-text">{value}</span>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      >
        {({
          selected,
        }: {
          selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
        }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={() => {
                  const providers: string[] = [];
                  const filter = props.monitoringSystemsListing.filter.map((filter: any) => ({
                    ...filter,
                    providers,
                  }));
                  props.updateMonitoringSystemListingFilter(filter, props.monitoringSystemsListing.page, props.monitoringSystemsListing.sizePerPage);
                }}
              />
            </div>
          )
        }
      </Typeahead>
    </div>
  );
};

const ExportDropdown = (props: { disabled: boolean; exportData: any; onDropdownToggle: (isOpen: boolean) => void }) => {
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);

  const toggleExportDropdown = () => {
    const isOpen = !exportDropdownOpen;
    props.onDropdownToggle && props.onDropdownToggle(isOpen);
    setExportDropdownOpen(isOpen);
  };

  let csvLink: any;
  return (
    <ButtonDropdown isOpen={exportDropdownOpen && !!props.exportData && !!props.exportData.length} toggle={toggleExportDropdown}>
      <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
        Export as {exportDropdownOpen && (!props.exportData || !props.exportData.length) ? <Spinner size="sm" /> : '...'}
      </DropdownToggle>
      <DropdownMenu>
        {/*<DropdownItem key="export-to-csv" onClick={() => csvLink.link.click()}>*/}
        <DropdownItem key="export-to-csv">
          <CSVLink data={props.exportData} filename="data.csv" className="hidden" ref={(r: any) => (csvLink = r)} target="_blank">
            CSV
          </CSVLink>
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const columns = (props: Props & MonitoringSystemListsProps) => [
  {
    id: 'id',
    Header: 'Id',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-alert-id';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.id}
        </span>
      );
    },
    disableFilters: true,
    disableSortBy: true,
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('id'),
  },
  {
    id: 'name',
    width: '180px',
    Header: 'Name',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-name';
      return <EllipsisText id={`${className}-${row.id}`} className={className} text={row.name} length={50} />;
    },
    disableFilters: true,
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('name'),
  },
  {
    id: 'site',
    width: '200px',
    Header: 'Site',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-site';
      return (
        <EllipsisText
          id={`${className}-${row.id}`}
          className={className}
          text={props.sitesListing.sites
            .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.site.indexOf(`${s.monitorSiteId}`) === 0)))
            .map((s: Site) => s.name)
            .getOrElse('N/A')}
          length={50}
        />
      );
    },
    Filter: SiteColumnFilter(props),
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('site'),
  },
  {
    id: 'type',
    width: '80px',
    Header: 'Provider',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-state';
      return (
        <Badge id={`${className}-${row.id}`} className={className}>
          {row.type}
        </Badge>
      );
    },
    Filter: TypeColumnFilter(props),
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('type'),
  },
  {
    id: 'code',
    width: '120px',
    Header: () => {
      return (
        <span data-testid="monitoringsystems-header-code" className="monitoringsystems-header-code">
          Code
        </span>
      );
    },
    accessor: (row: MonitoringSystem) => {
      const className = 'notifications-list-item-code';
      return (
        <span>
          <EllipsisText
            id={`${className}-${row.id}`}
            className={className}
            text={
              (row.type == 'SenseAnywhere' ? 'cgsense_poo3i' : row.site + '_' + row.type + '_' + row.code) +
              (config.envName == 'nocd-clean' ? '@clean.mep.api.sfrportal.com' : '@mep.api.sfrportal.com')
            }
            length={120}
          />{' '}
        </span>
      );
    },
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('code'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: 'status',
    width: '120px',
    Header: () => {
      return (
        <span data-testid="monitoringsystems-header-code" className="monitoringsystems-header-code">
          Alert Status
        </span>
      );
    },
    accessor: (row: MonitoringSystem) => {
      const getStatus = (): string => {
        if (row.lastIncident) {
          return row.lastIncident.status;
        }
        return 'N/A';
      };
      const className = 'notifications-list-item-status';
      const status = getStatus().toUpperCase();
      return (
        <Badge
          style={{
            backgroundColor: getBadgeStatusColor(status),
            cursor: row.lastIncident ? 'pointer' : '',
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          <span
            onClick={() => {
              if (row.lastIncident) {
                props.openMonitoringSystemIncidentInformation(row);
              }
            }}
          >
            <EllipsisText id={`${className}-${row.id}`} className={className} text={status} length={120} />{' '}
          </span>
        </Badge>
      );
    },
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('status'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: 'receivedSiteLocal',
    width: '150px',
    Header: 'Last Event Received (Site Local)',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-received-site-local';
      return row.lastEventReceivedAt ? (
        <Badge
          style={{
            backgroundColor: row.failing ? '#dc0000' : '#00ac46',
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          <DateTimeRender
            id={`${className}-${row.id}`}
            className={className}
            date={row.lastEventReceivedAt}
            timeZoneRef={props.sitesListing.sites
              .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.site.indexOf(`${s.monitorSiteId}`) === 0)))
              .map((s: Site) => s.timeZoneRef)
              .orUndefined()}
          />{' '}
        </Badge>
      ) : (
        <Badge
          style={{
            backgroundColor: '#dc0000',
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          Never Received
        </Badge>
      );
    },
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('receivedSiteLocal'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'receivedUtc',
    width: '130px',
    Header: 'Last Event Received (UTC)',
    accessor: (row: MonitoringSystem) => {
      const className = 'monitoringsystems-list-item-received-utc';
      return row.lastEventReceivedAt ? (
        <Badge
          style={{
            backgroundColor: row.failing ? '#dc0000' : '#00ac46',
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.lastEventReceivedAt} isUtc={true} />
        </Badge>
      ) : (
        <Badge
          style={{
            backgroundColor: '#dc0000',
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          Never Received
        </Badge>
      );
    },
    isVisible: !props.monitoringSystemsListing.hiddenColumns.includes('receivedUtc'),
    disableFilters: true,
  },
  {
    width: 155,
    Header: 'Actions',
    accessor: (row: MonitoringSystem) => <ActionsFormatter {...props} monitoringSystem={row} />,
    disableFilters: true,
    disableSortBy: true,
  },
];

interface MonitoringSystemListsProps {
  user: UserState;
  monitoringSystemsListing: MonitoringSystemsListingState;
  monitoringSystemDeleting: MonitoringSystemDeletingState;
  monitoringSystemSaving: MonitoringSystemSavingState;
  sitesListing: SitesListingState;
}

const crawfishUrl = new URL(config.services.crawfish.api.url);

class Component extends React.Component<Props & MonitoringSystemListsProps, unknown> {
  protected socket?: Socket;
  protected socketReaction?: NodeJS.Timeout;
  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
  }

  refresh() {
    const filter = this.props.monitoringSystemsListing.filter.map((filter: any) => ({
      ...filter,
    }));

    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchMonitoringSystems(
      filter,
      this.props.monitoringSystemsListing.page,
      this.props.monitoringSystemsListing.sizePerPage,
      this.props.monitoringSystemsListing.sort,
    );
  }

  componentDidMount() {
    this.refresh();
    //We use same logic as in Alerts List & Raw Notifications
    try {
      this.socket = io(crawfishUrl.host, {
        path: `${crawfishUrl.pathname.replace(/\/$/, '')}/socket.io`,
        transports: ['websocket', 'polling'],
        // upgrade: true,
        rememberUpgrade: true,
        query: {
          token: RSA.getAccessToken(incommandProvider, config.oauth.client.url),
        },
      });
      this.socket?.on(WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION, (serializedAlertNotification: string) => {
        logger.log(`Received serialized alert notification ${serializedAlertNotification}`);

        if (this.socketReaction) {
          clearTimeout(this.socketReaction);
        }

        this.socketReaction = setTimeout(() => {
          this.refresh();
        }, 100);
      });
    } catch (error) {
      this.props.messageForUserReloadThePage();
      this.props.logoutUser();
    }
  }

  componentDidUpdate(prevProps: Props & MonitoringSystemListsProps) {
    const isFilterUpdated = !prevProps.monitoringSystemsListing.filter.equals(this.props.monitoringSystemsListing.filter);
    const isSortingUpdated = !prevProps.monitoringSystemsListing.sort.equals(this.props.monitoringSystemsListing.sort);
    const isPageSizeUpdated = !(prevProps.monitoringSystemsListing.sizePerPage === this.props.monitoringSystemsListing.sizePerPage);
    const isPageChanged = !(prevProps.monitoringSystemsListing.page === this.props.monitoringSystemsListing.page);
    const isMonitoringSystemEditorClosed = prevProps.monitoringSystemEditing.isInProgress && !this.props.monitoringSystemEditing.isInProgress;
    const isMonitoringSystemDeleteFinished = prevProps.monitoringSystemDeleting.isInProgress && !this.props.monitoringSystemDeleting.isInProgress;
    const isMonitoringSystemSavingFinished = prevProps.monitoringSystemSaving.isInProgress && !this.props.monitoringSystemSaving.isInProgress;
    const isMonitoringSystemIncidentInformationModalClosed =
      prevProps.monitoringSystemIncidentInformationDialog.isOpen && !this.props.monitoringSystemIncidentInformationDialog.isOpen;
    if (
      isSortingUpdated ||
      isFilterUpdated ||
      isPageSizeUpdated ||
      isPageChanged ||
      isMonitoringSystemEditorClosed ||
      isMonitoringSystemDeleteFinished ||
      isMonitoringSystemSavingFinished ||
      isMonitoringSystemIncidentInformationModalClosed
    ) {
      this.refresh();
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.monitoringSystemsListing.total / this.props.monitoringSystemsListing.sizePerPage) + 1;

    return (
      <div className="content">
        <ExportDropdown
          disabled={false}
          exportData={this.props.monitoringSystemsListing.exportedMonitoringSystems
            .map((monitoringSystems) => {
              return monitoringSystems.map((monitoringSystem) => {
                return {
                  Name: monitoringSystem.name,
                  Site: monitoringSystem.site,
                  Type: monitoringSystem.type,
                  Code: monitoringSystem.code,
                  Frequency: monitoringSystem.frequency,
                  Quantity: monitoringSystem.quantity,
                  'Last Event Received At': monitoringSystem.lastEventReceivedAt,
                };
              });
            })
            .orJust([])}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedMonitoringSystems(this.props.monitoringSystemsListing.filter, this.props.monitoringSystemsListing.sort);
            } else {
              this.props.resetExportedMonitoringSystems();
            }
          }}
        />
        <Button
          className="btn-sm reset-filter"
          onClick={() => this.props.resetMonitoringSystems()}
          disabled={this.props.monitoringSystemsListing.isInProgress}
        >
          Reset Filters
        </Button>{' '}
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.monitoringSystemsListing.monitoringSystems.orJust([])}
          exportData={this.props.monitoringSystemsListing.exportedMonitoringSystems
            .map((monitoringSystems) => {
              return monitoringSystems.map((monitoringSystem) => {
                return {
                  Name: monitoringSystem.name,
                  Site: monitoringSystem.site,
                  Type: monitoringSystem.type,
                  Code: monitoringSystem.code,
                  Frequency: monitoringSystem.frequency,
                  Quantity: monitoringSystem.quantity,
                  'Last Event Received At': monitoringSystem.lastEventReceivedAt,
                };
              });
            })
            .orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.monitoringSystemsListing.page !== pageIndex || this.props.monitoringSystemsListing.sizePerPage !== pageSize) {
              this.props.updateMonitoringSystemListingFilter(this.props.monitoringSystemsListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<ListSort> = sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            // this.props.monitoringSystemsListing.sort.equals(newSort);
            if (!this.props.monitoringSystemsListing.sort.equals(newSort)) {
              this.props.updateMonitoringSystemListingSort(newSort);
            }
          }}
          onColumnVisibilityChange={(hiddenColumns) => {
            this.props.updateMonitoringSystemListingHiddenColumns(hiddenColumns);
          }}
          onReset={() => {
            this.props.resetMonitoringSystems();
          }}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedMonitoringSystems(this.props.monitoringSystemsListing.filter, this.props.monitoringSystemsListing.sort);
            } else {
              this.props.resetExportedMonitoringSystems();
            }
          }}
          total={this.props.monitoringSystemsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.monitoringSystemsListing.page,
            pageSize: this.props.monitoringSystemsListing.sizePerPage,
            sortBy: this.props.monitoringSystemsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
            hiddenColumns: this.props.monitoringSystemsListing.hiddenColumns,
          }}
          isLoading={this.props.monitoringSystemsListing.isInProgress}
          renderRow={(row: any) => {
            const classNames = [];
            if (row.original.isNew) {
              classNames.push('glow-row');
            } else if (row.original.isTracked) {
              classNames.push('table-warning');
            }
            return {
              className: classNames.join(' '),
            };
          }}
          showTopManageHiddenCols={this.props.showTopManageHiddenCols}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchMonitoringSystems,
      fetchExportedMonitoringSystems,
      showInfoNotification,
      showErrorNotification,
      updateMonitoringSystemListingFilter,
      updateMonitoringSystemListingHiddenColumns,
      updateMonitoringSystemListingSort,
      fetchSitesGroups,
      fetchSites,
      resetExportedMonitoringSystems,
      openAssetMaintenanceDialog,
      openMonitoringSystemIncidentInformation,
      resetMonitoringSystems,
      logoutUser,
      messageForUserReloadThePage,
      editMonitoringSystem,
      deleteMonitoringSystem,
      saveMonitoringSystem,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  monitoringSystemsListing: state.monitoringSystemsListing,
  monitoringSystemEditing: state.monitoringSystemEditing,
  monitoringSystemDeleting: state.monitoringSystemDeleting,
  monitoringSystemIncidentInformationDialog: state.monitoringSystemIncidentInformationDialog,
  sitesGroupsListing: state.sitesGroupsListing,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps &
  typeof dispatchProps & {
    showTopManageHiddenCols: boolean;
  };

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
