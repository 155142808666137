import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { ALERT_INITIAL_STATE } from '../constants';
import { AlertWorkLogAddingState, AlertWorkLogListingState } from '../entities';

import { ActionType, AlertWorkLogAction } from './actions';

export const alertWorkLogListingReducer: Reducer<AlertWorkLogListingState, AlertWorkLogAction> = (
  state = ALERT_INITIAL_STATE.alertWorkLogListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_ALERT_WORK_LOG:
      return {
        ...state,
        alert: action.alert,
        workLogs: Maybe.None(),
        isInProgress: false,
        total: 0,
        error: Maybe.None(),
      };
    case ActionType.CLOSE_ALERT_WORK_LOG:
      return {
        ...state,
        alert: Maybe.None(),
        workLogs: Maybe.None(),
        isInProgress: false,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.REQUEST_WORK_LOG:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_WORK_LOG:
      return {
        ...state,
        isInProgress: false,
        workLogs: Maybe.some(action.workLogs),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.RECEIVE_WORK_LOG_ERROR:
      return {
        ...state,
        isInProgress: false,
        workLogs: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.UPDATE_ALERT_WORK_LOG_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_ALERT_WORK_LOG_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_WORK_LOG:
      return ALERT_INITIAL_STATE.alertWorkLogListingState;
    default:
      return state;
  }
};

export const alertWorkLogAddingReducer: Reducer<AlertWorkLogAddingState, AlertWorkLogAction> = (
  state = ALERT_INITIAL_STATE.alertWorkLogAddingState,
  action,
) => {
  switch (action.type) {
    case ActionType.CLOSE_ALERT_WORK_LOG:
      return {
        ...state,
        alert: Maybe.None(),
        workLog: Maybe.None(),
      };
    case ActionType.EDIT_WORK_LOG:
      return {
        ...state,
        alert: action.alert,
        workLog: action.workLog,
      };
    case ActionType.EDIT_WORK_LOG_IS_SITE_AWARE:
      return {
        ...state,
        alert: action.alert,
        isSiteAware: action.isSiteAware,
      };
    case ActionType.REQUEST_WORK_LOG_ADD:
      return {
        ...state,
        isInProgress: true,
        workLog: Maybe.Some(action.workLog),
      };
    case ActionType.RECEIVE_WORK_LOG_ADD:
      return {
        ...state,
        isInProgress: false,
        workLog: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_WORK_LOG_ADD_ERROR:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    default:
      return state;
  }
};
