import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import { fetchProfile, logoutUser } from './data/actions';

import './Profile.css';

const component = ({ user /*, fetchProfile*/ }: Props) => (
  <div className="content">
    <p>
      Hey, {user.profile.firstName}! This is your personal space. You are logged in as @{user.profile.userName}.
    </p>
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      logoutUser,
      fetchProfile,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, unknown>(mapStateToProps, mapDispatchToProps)(component);
