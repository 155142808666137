// @ts-ignore: No declarations available
import { AlertState, SeverityLevel } from '@serverfarm/nocd-commons';
// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import moment from 'moment-timezone';
import { Some, Maybe, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
// @ts-ignore
import * as Icon from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { Button, UncontrolledTooltip, Badge, Label, Input, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { showErrorNotification } from '../notification';
import { DATE_TIME_FORMAT_DEFAULT, DateTimeRender } from '../utils';
import { getAcknowledgedColor, getBadgeColor, getInMaintenanceColor, getStateColor } from '../utils/colors';
import { DateRange, DateRangeFilter, DateRangeFn } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import {
  fetchAlertNotifications,
  updateAlertNotificationListingFilter,
  updateAlertNotificationListingPages,
  updateAlertNotificationListingSort,
  closeAlertNotificationsList,
  Notification,
  AlertNotificationListSort,
  openAlertNotificationComments,
  closeAlertNotificationComments,
  ackNotification,
  toggleNotificationSelect,
  toggleAllNotificationSelect,
  bulkAckNotifications,
  resetAlertNotification,
} from './data/alert-notifications';
import { AlertNotificationsListingState } from './redux/entities';

const logger = logdown('component:RenderedReportHistoryList');

const ActionsFormatter = (props: Props) => (row: Notification & any) => {
  const notification = props.alertNotificationsListing.notification.orUndefined();
  return (
    <span>
      <Button
        className="acknowledge-btn"
        id={`ack-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => {
          props.ackNotification(row, false);
        }}
        disabled={
          (props.alertNotificationsListing.isInProgress && notification && notification.id === row.id) ||
          (row.acknowledged && row.acknowledged.length > 0)
        }
      >
        <Spinner size="sm" hidden={!(props.alertNotificationsListing.isInProgress && notification && notification.id === row.id)} />
        {row.acknowledged && row.acknowledged.length > 0 ? <Icon.Check2Circle className="sm" /> : <Icon.Check2 className="sm" />}{' '}
      </Button>{' '}
      <Button
        className="comments-btn"
        id={`comments-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => {
          props.openAlertNotificationComments(row);
        }}
      >
        <Icon.ChatQuote className="sm" /> {row.comments ? row.comments.length : 0}
      </Button>{' '}
      {doesTargetExist(`ack-btn-${row.id}`) && <UncontrolledTooltip target={`ack-btn-${row.id}`}>Acknowledge</UncontrolledTooltip>}
      {doesTargetExist(`comments-btn-${row.id}`) && <UncontrolledTooltip target={`comments-btn-${row.id}`}>Comments</UncontrolledTooltip>}
    </span>
  );
};

const CreatedDateRangeFilter = (props: Props & { alertNotificationsListing: AlertNotificationsListingState }) => (
  <DateRangeFilter
    id="date-range-created"
    showErrorNotification={props.showErrorNotification}
    value={props.alertNotificationsListing.filter.flatMap((filter: any) => filter.created)}
    onReset={() => {
      props.updateAlertNotificationListingFilter(
        props.alertNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          createdAt: None(),
        })),
        // props.alertNotificationsListing.page,
        0,
        props.alertNotificationsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange | DateRangeFn) => {
      props.updateAlertNotificationListingFilter(
        props.alertNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          createdAt: Some(range),
        })),
        props.alertNotificationsListing.page,
        props.alertNotificationsListing.sizePerPage,
      );
    }}
  />
);

const StateColumnFilter = (props: Props) => (/*params: any*/) => {
  const selectedCompanyIds = props.alertNotificationsListing.filter
    .map((dsf: any) => {
      return dsf.state ? [dsf.state] : [];
    })
    .orJust([]);
  return (
    <Typeahead<string>
      multiple={true}
      data-testid="alerts-filter-state"
      id="alerts-filter-state"
      onChange={(selected) => {
        const filter = props.alertNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          state: selected.length > 1 ? selected[1] : selected[0],
        }));
        props.updateAlertNotificationListingFilter(
          filter,
          //  props.alertNotificationsListing.page,
          0,
          props.alertNotificationsListing.sizePerPage,
        );
      }}
      filterBy={() => {
        return true;
      }}
      options={Object.values(AlertState)}
      placeholder="Select a state"
      selected={selectedCompanyIds}
    />
  );
};

const SeverityLevelColumnFilter = (props: Props) => (/*params: any*/) => {
  const selectedSeverityLevels = props.alertNotificationsListing.filter
    .map((dsf: any) => {
      return dsf.severityLevel !== undefined ? [dsf.severityLevel] : [];
    })
    .orJust([]);

  const map: { id: number; name: string }[] = [];

  for (const n in SeverityLevel) {
    if (typeof SeverityLevel[n] === 'number') {
      map.push({ id: SeverityLevel[n] as unknown as number, name: n });
    }
  }

  return (
    <Typeahead
      multiple={true}
      data-testid="alerts-filter-severity-level"
      id="alerts-filter-severity-level"
      onChange={(selected) => {
        const filter = props.alertNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          severityLevel: selected.length > 1 ? selected[1] : selected[0],
        }));
        props.updateAlertNotificationListingFilter(
          filter,
          // props.alertNotificationsListing.page,
          0,
          props.alertNotificationsListing.sizePerPage,
        );
      }}
      filterBy={() => {
        return true;
      }}
      clearButton={false}
      options={map.map((v) => v.name)}
      placeholder="Select a Severity Level"
      selected={selectedSeverityLevels}
    />
  );
};

const IsInMaintenanceColumnFilter = (props: Props) => () => {
  const selectedIsInMaintenance = props.alertNotificationsListing.filter
    .map((dsf: any) => {
      return dsf.isInMaintenance ? dsf.isInMaintenance : 'Undefined';
    })
    .orJust('Undefined');
  return (
    <Label className="ml-4 mt-1" check>
      <span
        className="clickable"
        onClick={() => {
          const filter = props.alertNotificationsListing.filter.map((filter: any) => ({
            ...filter,
            isInMaintenance:
              'Yes' === selectedIsInMaintenance
                ? 'No'
                : 'No' === selectedIsInMaintenance
                ? 'Undefined'
                : 'Undefined' === selectedIsInMaintenance
                ? 'Yes'
                : undefined,
          }));
          props.updateAlertNotificationListingFilter(
            filter,
            // props.alertNotificationsListing.page,
            0,
            props.alertNotificationsListing.sizePerPage,
          );
        }}
      >
        {selectedIsInMaintenance === 'Undefined' ? 'Yes/No' : selectedIsInMaintenance}
      </span>
    </Label>
  );
};

const SelectNotificationAccessor = (props: Props, row: Notification & any) => {
  const checked = props.alertNotificationsListing.notifications.map((ns: any) => ns.filter((n: any) => n.id === row.id)).some();
  // console.log('SELECTED', checked);
  return row.acknowledged && row.acknowledged.length > 0 ? (
    ''
  ) : (
    <Input
      type="checkbox"
      style={{
        position: 'relative',
        marginTop: 0,
        marginLeft: 0,
      }}
      className="alert-notifications-select"
      checked={checked[0].selected}
      onChange={() => {
        props.toggleNotificationSelect(row.id as string);
      }}
    />
  );
};

const SelectAllNotificationFilter = (props: Props) => (/*params: any*/) => {
  const checked = props.alertNotificationsListing.notifications.orJust([]).reduce((r: any, n: any /*, idx: any*/) => {
    if (r) return (n.acknowledged && n.acknowledged.length > 0) || n.selected;
    return false;
  }, true);
  return (
    <Input
      type="checkbox"
      style={{
        position: 'relative',
        marginTop: 0,
        marginLeft: 0,
      }}
      id="alert-notifications-select-all"
      defaultChecked={checked}
      onChange={() => {
        props.toggleAllNotificationSelect(!checked);
      }}
    />
  );
};
const columns = (props: Props) => [
  {
    id: 'id',
    width: '20px',
    Header: (/*row: Notification*/) => {
      const checked = props.alertNotificationsListing.notifications.orJust([]).reduce((r: any, n: any /*, idx: any*/) => {
        if (!r) return n.selected;
        return true;
      }, false);
      return (
        <Button
          className="bulk-acknowledge-btn"
          id="bulk-ack-btn"
          color="info"
          size="sm"
          onClick={() => {
            const notificationIds = props.alertNotificationsListing.notifications.orJust([]).reduce((r: any, n: any /*, idx: any*/) => {
              if (n.selected) {
                r.push(n.id as string);
              }
              return r;
            }, [] as string[]);
            props.bulkAckNotifications(notificationIds);
          }}
          disabled={!checked}
        >
          <Icon.Check2 className="sm" />
        </Button>
      );
    },
    accessor: (row: Notification) => {
      logger.info(`ROW COMING ${JSON.stringify(row)}`);
      return SelectNotificationAccessor(props, row);
    },
    Filter: SelectAllNotificationFilter(props),
    disableSortBy: true,
  },
  // {
  //   maxWidth: 300,
  //   Header: 'Provider',
  //   accessor: (row: Notification & any) => {
  //     return <span className="alert-notifications-list-item-provider">{row.provider}</span>;
  //   },
  //   Filter: <ProviderFilter {...props} />,
  // },
  // {
  //   Header: 'Source',
  //   width: 'auto',
  //   accessor: (row: Notification & any) => {
  //     return <span className="alert-notifications-list-item-source">{row.source}</span>;
  //   },
  //   Filter: <SourceFilter {...props} />,
  // },
  {
    id: 'severityLevel',
    Header: 'Severity',
    accessor: (notification: Notification & any) => {
      const className = 'alert-notifications-list-item-severityLevel';
      return (
        <Badge
          style={{
            backgroundColor: getBadgeColor(notification.severityLevel as number),
          }}
          id={`${className}-${notification.id}`}
          className={className}
        >
          {SeverityLevel[notification.severityLevel as number]}
        </Badge>
      );
    },
    Filter: SeverityLevelColumnFilter(props),
  },
  {
    id: 'state',
    Header: 'State',
    accessor: (row: Notification & any) => {
      const className = 'alert-notifications-list-item-state';
      return (
        <Badge
          style={{
            backgroundColor: getStateColor(row.state as AlertState),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.state}
        </Badge>
      );
    },
    Filter: StateColumnFilter(props),
  },
  {
    id: 'isInMaintenance',
    Header: 'In Maintenance',
    accessor: (row: Notification & any) => {
      const className = 'alert-notifications-list-item-is-in-maintenance';
      return (
        <Badge
          style={{
            backgroundColor: getInMaintenanceColor(row.isInMaintenance),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.isInMaintenance ? 'Yes' : 'No'}
        </Badge>
      );
    },
    Filter: IsInMaintenanceColumnFilter(props),
    // disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'acknowledged',
    Header: () => {
      return <span data-testid="alert-notifications-acknowledged">Acknowledged (UTC)</span>;
    },
    accessor: (row: Notification) => {
      const className = 'alert-notifications-list-item-acknowledged';
      return row.acknowledged ? (
        <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.acknowledged.createdAt} isUtc={true} />
      ) : (
        <Badge
          style={{
            backgroundColor: getAcknowledgedColor(false),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          No
        </Badge>
      );
    },
    // Filter: IsInMaintenanceColumnFilter(props),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'acknowledgedBy',
    Header: () => {
      return <span data-testid="alert-notifications-header-acknowledged-by">Acknowledged By</span>;
    },
    accessor: (row: Notification) => {
      const className = 'alert-notifications-list-item-acknowledged-by';
      return <span className={className}>{row.acknowledged ? row.acknowledged.userName : 'N/A'}</span>;
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Received (UTC)',
    accessor: (row: Notification & any) => {
      const className = 'alert-notifications-list-item-date';
      logger.info(`ROW COMING ${JSON.stringify(row)}`);
      return <DateTimeRender id={`${className}-${row.id}`} date={row.createdAt} className={className} isUtc={true} />;
    },
    Filter: CreatedDateRangeFilter(props),
  },
  {
    Header: 'Actions',
    width: '120px',
    accessor: (row: Notification) => ActionsFormatter(props)(row),
    disableFilters: true,
    disableSortBy: true,
  },
];

class Component extends React.Component<Props, unknown> {
  componentDidMount() {
    this.props.updateAlertNotificationListingFilter(
      this.props.alertNotificationsListing.alert.map((a: any) => ({
        alertId: a.id,
        createdAt: None(),
      })),
      this.props.alertNotificationsListing.page,
      this.props.alertNotificationsListing.sizePerPage,
    );
  }

  componentDidUpdate(prevProps: Props) {
    const alertIsSet = this.props.alertNotificationsListing.alert.isSome();
    const isFilterUpdated = !prevProps.alertNotificationsListing.filter.equals(this.props.alertNotificationsListing.filter);
    const isSortUpdated = !prevProps.alertNotificationsListing.sort.equals(this.props.alertNotificationsListing.sort);
    const isPageSizeUpdated = !(prevProps.alertNotificationsListing.sizePerPage === this.props.alertNotificationsListing.sizePerPage);
    const isPageChanged = !(prevProps.alertNotificationsListing.page === this.props.alertNotificationsListing.page);
    logger.info(`LOAD INFO ${alertIsSet && (isFilterUpdated || isSortUpdated || isPageSizeUpdated || isPageChanged)}`);
    if (alertIsSet && (isFilterUpdated || isSortUpdated || isPageSizeUpdated || isPageChanged)) {
      this.props.fetchAlertNotifications(
        this.props.alertNotificationsListing.alert.some(),
        this.props.alertNotificationsListing.filter,
        this.props.alertNotificationsListing.sort,
        this.props.alertNotificationsListing.page,
        this.props.alertNotificationsListing.sizePerPage,
      );
      return;
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.alertNotificationsListing.total / this.props.alertNotificationsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.alertNotificationsListing.notifications.orJust([])}
          exportData={this.props.alertNotificationsListing.notifications
            .map((m: Notification[]) => {
              return m.map((a: Notification) => ({
                id: a.id,
                created: moment(a.createdAt).utc().format(DATE_TIME_FORMAT_DEFAULT),
                severityLevel: a.severityLevel,
                source: a.source,
                state: a.state,
                provider: a.provider,
                comments: a?.comments?.length,
                isInMaintenance: a.isInMaintenance ? 'Yes' : 'No',
                acknowledged: a.acknowledged?.userName || '',
                acknowledgedUTCTime: moment(a.acknowledged?.createdAt || new Date())
                  .utc()
                  .format(DATE_TIME_FORMAT_DEFAULT),
              }));
            })
            .orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.alertNotificationsListing.page !== pageIndex || this.props.alertNotificationsListing.sizePerPage !== pageSize) {
              this.props.updateAlertNotificationListingPages(pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<AlertNotificationListSort> =
              sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            this.props.alertNotificationsListing.sort.equals(newSort);
            if (!this.props.alertNotificationsListing.sort.equals(newSort)) {
              this.props.updateAlertNotificationListingSort(newSort);
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onColumnVisibilityChange={() => {}}
          onReset={() => {
            this.props.resetAlertNotification();
            // this.props.updateAlertNotificationListingFilter(
            //   this.props.alertNotificationsListing.filter.map((filter: any) => ({ ...filter })),
            //   this.props.alertNotificationsListing.page,
            //   this.props.alertNotificationsListing.sizePerPage,
            // );
          }}
          total={this.props.alertNotificationsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.alertNotificationsListing.page,
            pageSize: this.props.alertNotificationsListing.sizePerPage,
            sortBy: [],
          }}
          isLoading={this.props.alertNotificationsListing.isInProgress}
          renderRow={(row: any) => {
            // console.log('Rendering row', row);
            return {
              className: row.original.isNew ? 'glow-row' : '',
            };
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      // fetchalertNotificationsListing,
      // updatealertNotificationsListingFilter,
      // updatealertNotificationsListingPages,
      // updatealertNotificationsListingSort,
      // openRenderReportViewer,
      // copyToClipboard,
      // closealertNotificationsListing,
      fetchAlertNotifications,
      updateAlertNotificationListingFilter,
      updateAlertNotificationListingPages,
      updateAlertNotificationListingSort,
      closeAlertNotificationsList,
      openAlertNotificationComments,
      closeAlertNotificationComments,
      ackNotification,
      toggleNotificationSelect,
      toggleAllNotificationSelect,
      bulkAckNotifications,
      resetAlertNotification,
      showErrorNotification,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  alertsListing: state.alertsListing,
  companiesListing: state.companiesListing,
  alertNotificationsListing: state.alertNotificationsListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
