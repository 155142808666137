import { Maybe } from 'monet';

import { Action, ErrorAction } from '../../redux/actions';
import { Event, EventsListSort, EventsListFilter } from '../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_EVENTS = 'REQUEST_EVENTS',
  RECEIVE_EVENTS = 'RECEIVE_EVENTS',
  RECEIVE_EVENTS_ERROR = 'RECEIVE_EVENTS_ERROR',

  UPDATE_EVENTS_LISTING_FILTER = 'UPDATE_EVENTS_LISTING_FILTER',
  UPDATE_EVENTS_SORT_LIST = 'UPDATE_EVENTS_SORT_LIST',

  RESET_EVENTS = 'RESET_EVENTS',
}

interface EventsRequestAction extends Action<ActionType> {
  filter: Maybe<EventsListFilter>;
  sizePerPage: number;
  sort: Maybe<EventsListSort>;
}

interface EventsReceiveAction extends Action<ActionType> {
  events: Event[];
  page: number;
  total: number;
}

type EventsReceiveErrorAction = ErrorAction<ActionType>;

interface EventListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<EventsListFilter>;
  page: number;
  sizePerPage: number;
}

interface EventListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<EventsListSort>;
}

type EventResetAction = Action<ActionType>;

type DataSourceResetAction = Action<ActionType>;

export type EventsFetchAction = EventsRequestAction &
  EventsReceiveAction &
  EventsReceiveErrorAction &
  EventListingFilterUpdateAction &
  EventResetAction &
  DataSourceResetAction;

export const eventsRequest = (filter: Maybe<EventsListFilter>, sizePerPage: number, sort: Maybe<EventsListSort>): EventsRequestAction => ({
  type: ActionType.REQUEST_EVENTS,
  filter,
  sizePerPage,
  sort,
});

export const eventsReceive = (events: Event[], page: number, total: number): EventsReceiveAction => ({
  type: ActionType.RECEIVE_EVENTS,
  events,
  page,
  total,
});

export const eventsReceiveError = (error: Error): EventsReceiveErrorAction => ({
  type: ActionType.RECEIVE_EVENTS_ERROR,
  error,
});

export const eventListingFilterUpdate = (filter: Maybe<EventsListFilter>, page: number, sizePerPage: number): EventListingFilterUpdateAction => ({
  type: ActionType.UPDATE_EVENTS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const eventListingSortUpdate = (sort: Maybe<EventsListSort>): EventListingSortUpdateAction => ({
  type: ActionType.UPDATE_EVENTS_SORT_LIST,
  sort,
});

export const eventsReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_EVENTS,
});
