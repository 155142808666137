import { Maybe } from 'monet';

import { IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';

import { EventsListingState } from './entities';

const eventsListingState: EventsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  events: Maybe.None(),
  filter: Maybe.None(),
  sort: Maybe.None(),
};

export const EVENT_INITIAL_STATE = {
  eventsListingState,
};
