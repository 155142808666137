// @ts-ignore: No declarations available
import logdown from 'logdown';
import { Maybe } from 'monet';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, Input, Label, ModalFooter, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { IconType } from '../../components/notification/Notification';
import { State } from '../../reducers';
import { showNotification } from '../notification';

import { closeAlertSourceInformation } from './data/alerts';

const logger = logdown('component:AlertSourceInformationModal');

class Component extends React.Component<Props, unknown> {
  inputRef: HTMLTextAreaElement | HTMLInputElement | null = null;

  componentDidMount() {
    this.updateInputHeight();
  }

  componentDidUpdate() {
    this.updateInputHeight();
  }

  updateInputHeight() {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.style.height = 'auto';
        this.inputRef.style.height = `${this.inputRef.scrollHeight}px`;
        this.inputRef.style.overflow = 'hidden';
      }
    }, 250);
  }

  copyToClipBoard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.props.showNotification(`Copied to clipboard`, IconType.SUCCESS);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  public render() {
    const siteid = this.props.sitesListing.sites
      .flatMap((sites) =>
        Maybe.fromUndefined(
          sites.find(
            (s) =>
              this.props.alertSourceInformationDialog.alert
                .map((a: any) => a.provider)
                .getOrElse('N/A')
                .indexOf(`/${s.monitorSiteId}`) === 0,
          ),
        ),
      )
      .map((s) => s.name)
      .getOrElse('N/A');

    logger.log(`Alert ${this.props.alertSourceInformationDialog.alert.isSome()}`);
    return (
      <Modal
        id="alert-source-information-modal"
        data-testid="alert-source-information-modal"
        size="lg"
        isOpen={this.props.alertSourceInformationDialog.isOpen}
        // style={{ maxWidth: '1600px', width: '80%' }}
        toggle={this.close.bind(this)}
      >
        <ModalHeader data-testid="alert-source-information-modal-header">
          <EllipsisText className="stakeholder-notifications-header" text={`Alert Source Information`} length={75} />
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Site</Label>
          <InputGroup>
            <Input type="text" name="site" id="site" value={siteid} disabled={true} />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ background: '#e3e3e3', cursor: 'pointer' }}>
                <Icon.Clipboard
                  onClick={() => {
                    this.copyToClipBoard(siteid);
                  }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Label className="mr-sm-2">Source</Label>
          <InputGroup>
            <Input
              type="textarea"
              name="site"
              id="site"
              value={this.props.alertSourceInformationDialog.alert.map((a: any) => a.source).getOrElse('N/A')}
              disabled={true}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ background: '#e3e3e3', cursor: 'pointer' }}>
                <Icon.Clipboard
                  onClick={() => {
                    this.copyToClipBoard(this.props.alertSourceInformationDialog.alert.map((a: any) => a.source).getOrElse('N/A'));
                  }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Label className="mr-sm-2">Alarm Text</Label>
          <InputGroup>
            <Input
              type="textarea"
              name="alert"
              id="alert"
              value={this.props.alertSourceInformationDialog.alert.map((a: any) => a.lastNotification?.raw?.Message).getOrElse('N/A')}
              disabled={true}
              style={{ maxHeight: 'unset' }}
              innerRef={(ref) => (this.inputRef = ref)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ background: '#e3e3e3', cursor: 'pointer' }}>
                <Icon.Clipboard
                  onClick={() => {
                    this.copyToClipBoard(
                      this.props.alertSourceInformationDialog.alert.map((a: any) => a.lastNotification?.raw?.Message).getOrElse('N/A'),
                    );
                  }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="stakeholder-notifications-cancel-button"
            data-testid="alert-source-information-modal-cancel-button"
            color="secondary"
            onClick={this.close.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected close(e: any) {
    e.preventDefault();
    // if (this.props.stakeHolderNotificationSending.workLogs.isSome()) {
    //   return this.props.closeAlertStakeholderNotification();
    // }
    return this.props.closeAlertSourceInformation();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      closeAlertSourceInformation,
      showNotification,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  alertSourceInformationDialog: state.alertSourceInformationDialog,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
