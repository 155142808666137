import moment from 'moment';
import { Maybe, Some } from 'monet';
import * as React from 'react';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { Button, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchSites, fetchSitesGroups } from '../sites';

import {
  fetchRawNotifications,
  resetRawNotifications,
  updateRawNotificationListingFilter,
  updateRawNotificationListingSort,
} from './data/raw-notifications';
import MessageInformationModal from './MessageInformationModal';
import RawNotificationsList from './RawNotificationsList';

class Component extends React.Component<Props, unknown> {
  refresh() {
    const updatedAt = this.props.rawNotificationsListing.filter
      .flatMap((filter: any) => Maybe.fromUndefined(filter.updatedAt))
      .getOrElse({ from: '', to: '' });
    const from = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.from).getOrElse(''));
    const to = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.to).getOrElse(''));

    const today = moment().startOf('day');
    const toDate = moment(to || new Date())
      .startOf('day')
      .isSame(today)
      ? moment().toDate()
      : to;

    const filter = this.props.rawNotificationsListing.filter.map((filter: any) => ({
      ...filter,
      updatedAt: Some({ from, to: toDate }),
    }));
    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchRawNotifications(
      filter,
      this.props.rawNotificationsListing.page,
      this.props.rawNotificationsListing.sizePerPage,
      this.props.rawNotificationsListing.sort,
    );
  }

  public render() {
    return (
      <div className="content">
        <Button
          data-testid="rawnotifications-list-reload-button"
          id="rawnotifications-list-reload-button"
          color="secondary"
          size="sm"
          onClick={this.refresh.bind(this)}
        >
          <Spinner size="sm" hidden={!this.props.rawNotificationsListing.isInProgress} /> Refresh
        </Button>
        <RawNotificationsList {...this.props} />
        <MessageInformationModal {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchRawNotifications,
      fetchSitesGroups,
      fetchSites,
      updateRawNotificationListingFilter,
      updateRawNotificationListingSort,
      resetRawNotificationsState: resetRawNotifications,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  sitesGroupsListing: state.sitesGroupsListing,
  rawNotificationsListing: state.rawNotificationsListing,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
