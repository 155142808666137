import { AlertNotification } from '@serverfarm/nocd-commons';
import { Maybe, None, Some } from 'monet';

import { Action, ErrorAction } from '../../../redux/actions';
import { AlertNotificationListFilter, AlertNotificationListSort, Notification } from '../../data/alert-notifications';
import { Alert } from '../../entities';

/**
 * Action types
 */

export enum AlertNotificationListActionType {
  OPEN_ALERT_NOTIFICATIONS_LIST = 'OPEN_ALERT_NOTIFICATIONS_LIST',
  CLOSE_ALERT_NOTIFICATIONS_LIST = 'CLOSE_ALERT_NOTIFICATIONS_LIST',

  OPEN_ALERT_NOTIFICATION_LIST = 'OPEN_ALERT_NOTIFICATION_LIST',
  CLOSE_ALERT_NOTIFICATION_LIST = 'CLOSE_ALERT_NOTIFICATION_LIST',

  REQUEST_ALERT_NOTIFICATIONS = 'REQUEST_ALERT_NOTIFICATIONS',
  RECEIVE_ALERT_NOTIFICATIONS = 'RECEIVE_ALERT_NOTIFICATIONS',
  RECEIVE_ALERT_NOTIFICATIONS_ERROR = 'RECEIVE_ALERT_NOTIFICATIONS_ERROR',

  UPDATE_ALERT_NOTIFICATIONS_LISTING_FILTER = 'UPDATE_ALERT_NOTIFICATIONS_LISTING_FILTER',
  UPDATE_ALERT_NOTIFICATIONS_SORT_LIST = 'UPDATE_ALERT_NOTIFICATIONS_SORT_LIST',

  RESET_ALERT_NOTIFICATIONS = 'RESET_ALERT_NOTIFICATIONS',

  OPEN_ALERT_NOTIFICATION_COMMENTS_LIST = 'OPEN_ALERT_NOTIFICATION_COMMENTS_LIST',
  CLOSE_ALERT_NOTIFICATION_COMMENTS_LIST = 'CLOSE_ALERT_NOTIFICATION_COMMENTS_LIST',
  EDIT_NOTIFICATION_COMMENT = 'EDIT_NOTIFICATION_COMMENT',

  REQUEST_NOTIFICATION_COMMENT = 'REQUEST_NOTIFICATION_COMMENT',
  RECEIVE_NOTIFICATION_COMMENT = 'RECEIVE_NOTIFICATION_COMMENT',
  RECEIVE_NOTIFICATION_COMMENT_ERROR = 'RECEIVE_NOTIFICATION_COMMENT_ERROR',

  REQUEST_NOTIFICATION_ACK = 'REQUEST_NOTIFICATION_ACK',
  RECEIVE_NOTIFICATION_ACK = 'RECEIVE_NOTIFICATION_ACK',
  RECEIVE_NOTIFICATION_ACK_ERROR = 'RECEIVE_NOTIFICATION_ACK_ERROR',

  TOGGLE_NOTIFICATION_SELECTED = 'TOGGLE_NOTIFICATION_SELECTED',
  TOGGLE_NOTIFICATION_SELECTED_ALL = 'TOGGLE_NOTIFICATION_SELECTED_ALL',

  REQUEST_NOTIFICATION_BULK_ACK = 'REQUEST_NOTIFICATION_BULK_ACK',
  RECEIVE_NOTIFICATION_BULK_ACK = 'RECEIVE_NOTIFICATION_BULK_ACK',
  RECEIVE_NOTIFICATION_BULK_ACK_ERROR = 'RECEIVE_NOTIFICATION_BULK_ACK_ERROR',
  ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION = 'ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION',
  RESET_NOTIFICATION_GLOW = 'RESET_NOTIFICATION_GLOW',
  RESET_ALERT_FILTER_NOTIFICATIONS = 'RESET_ALERT_FILTER_NOTIFICATIONS',
}

/**
 * Actions
 */

interface AlertNotificationListToggleAction extends Action<AlertNotificationListActionType> {
  alert: Maybe<Alert>;
}

interface AlertNotificationsRequestAction extends Action<AlertNotificationListActionType> {
  filter: Maybe<AlertNotificationListFilter>;
  sizePerPage: number;
  sort: Maybe<AlertNotificationListSort>;
}

interface AlertNotificationsReceiveAction extends Action<AlertNotificationListActionType> {
  notifications: Notification[];
  page: number;
  total: number;
}

type AlertNotificationsReceiveErrorAction = ErrorAction<AlertNotificationListActionType>;

interface AlertNotificationsListingFilterUpdateAction extends Action<AlertNotificationListActionType> {
  filter: Maybe<AlertNotificationListFilter>;
  page: number;
  sizePerPage: number;
}

interface AlertNotificationsListingSortUpdateAction extends Action<AlertNotificationListActionType> {
  sort: Maybe<AlertNotificationListSort>;
}

type AlertNotificationsResetAction = Action<AlertNotificationListActionType>;

interface AlertNotificationCommentsToggleAction extends Action<AlertNotificationListActionType> {
  notificationComment: Maybe<Notification>;
}

interface NotificationEditCommentAction extends Action<AlertNotificationListActionType> {
  commentEditing: string;
}
interface AlertNotificationsCommentRequestAction extends Action<AlertNotificationListActionType> {
  notificationComment: Maybe<Notification>;
}
interface AlertNotificationsAckRequestAction extends Action<AlertNotificationListActionType> {
  notification: Maybe<Notification>;
}

interface AlertNotificationsCommentReceiveAction extends Action<AlertNotificationListActionType> {
  userName?: string;
}
type AlertNotificationsCommentReceiveErrorAction = ErrorAction<AlertNotificationListActionType>;

interface AlertNotificationsToggleSelectedAction extends Action<AlertNotificationListActionType> {
  notificationId?: string;
}
interface AlertNotificationsToggleAllSelectedAction extends Action<AlertNotificationListActionType> {
  selected: boolean;
}

type AlertNotificationsBulkAckRequestAction = Action<AlertNotificationListActionType>;
interface AlertNotificationsBulkAckReceiveAction extends Action<AlertNotificationListActionType> {
  notificationIds: string[];
}
type AlertNotificationsBulkAckReceiveErrorAction = ErrorAction<AlertNotificationListActionType>;
interface AlertNotificationsUpdateAction extends Action<AlertNotificationListActionType> {
  notification: AlertNotification;
}

type AlertSourceResetAction = Action<AlertNotificationListActionType>;

export type AlertNotificationsFetchAction = AlertNotificationListToggleAction &
  AlertNotificationsRequestAction &
  AlertNotificationsReceiveAction &
  AlertNotificationsReceiveErrorAction &
  AlertNotificationsListingSortUpdateAction &
  AlertNotificationsResetAction &
  AlertNotificationCommentsToggleAction &
  NotificationEditCommentAction &
  AlertNotificationsCommentRequestAction &
  AlertNotificationsCommentReceiveAction &
  AlertNotificationsCommentReceiveErrorAction &
  AlertNotificationsToggleSelectedAction &
  AlertNotificationsToggleAllSelectedAction &
  AlertNotificationsBulkAckRequestAction &
  AlertNotificationsBulkAckReceiveAction &
  AlertNotificationsBulkAckReceiveErrorAction &
  AlertNotificationsUpdateAction &
  AlertNotificationsAckRequestAction &
  AlertSourceResetAction;

export const alertNotificationListOpen = (alert: Alert): AlertNotificationListToggleAction => ({
  type: AlertNotificationListActionType.OPEN_ALERT_NOTIFICATIONS_LIST,
  alert: Some(alert),
});

export const alertNotificationListClose = (): AlertNotificationListToggleAction => ({
  type: AlertNotificationListActionType.CLOSE_ALERT_NOTIFICATIONS_LIST,
  alert: None(),
});

export const alertNotificationsRequest = (
  filter: Maybe<AlertNotificationListFilter>,
  sizePerPage: number,
  sort: Maybe<AlertNotificationListSort>,
): AlertNotificationsRequestAction => ({
  type: AlertNotificationListActionType.REQUEST_ALERT_NOTIFICATIONS,
  filter,
  sizePerPage,
  sort,
});

export const alertNotificationsReceive = (notifications: Notification[], page: number, total: number): AlertNotificationsReceiveAction => ({
  type: AlertNotificationListActionType.RECEIVE_ALERT_NOTIFICATIONS,
  notifications,
  page,
  total,
});

export const alertNotificationsReceiveError = (error: Error): AlertNotificationsReceiveErrorAction => ({
  type: AlertNotificationListActionType.RECEIVE_ALERT_NOTIFICATIONS_ERROR,
  error,
});

export const alertNotificationsListingFilterUpdate = (
  filter: Maybe<AlertNotificationListFilter>,
  page: number,
  sizePerPage: number,
): AlertNotificationsListingFilterUpdateAction => ({
  type: AlertNotificationListActionType.UPDATE_ALERT_NOTIFICATIONS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const alertNotificationsListingSortUpdate = (sort: Maybe<AlertNotificationListSort>): AlertNotificationsListingSortUpdateAction => ({
  type: AlertNotificationListActionType.UPDATE_ALERT_NOTIFICATIONS_SORT_LIST,
  sort,
});

export const alertNotificationsReset = (): AlertNotificationsResetAction => ({
  type: AlertNotificationListActionType.RESET_ALERT_NOTIFICATIONS,
});

export const alertNotificationCommentsOpen = (notification: Notification): AlertNotificationCommentsToggleAction => ({
  type: AlertNotificationListActionType.OPEN_ALERT_NOTIFICATION_COMMENTS_LIST,
  notificationComment: Some(notification),
});

export const alertNotificationCommentsClose = (): AlertNotificationCommentsToggleAction => ({
  type: AlertNotificationListActionType.CLOSE_ALERT_NOTIFICATION_COMMENTS_LIST,
  notificationComment: None(),
});

export const notificationEditComment = (commentEditing: string): NotificationEditCommentAction => ({
  type: AlertNotificationListActionType.EDIT_NOTIFICATION_COMMENT,
  commentEditing,
});

export const commentNotificationRequest = (notification: Notification): AlertNotificationsCommentRequestAction => ({
  type: AlertNotificationListActionType.REQUEST_NOTIFICATION_COMMENT,
  notificationComment: Maybe.some(notification),
});

export const commentNotificationReceive = (userName?: string): AlertNotificationsCommentReceiveAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_COMMENT,
  userName,
});

export const commentNotificationReceiveError = (error: Error): AlertNotificationsCommentReceiveErrorAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_COMMENT_ERROR,
  error,
});

export const ackNotificationRequest = (notification: Notification): AlertNotificationsAckRequestAction => ({
  type: AlertNotificationListActionType.REQUEST_NOTIFICATION_ACK,
  notification: Maybe.some(notification),
});

export const ackNotificationReceive = (userName?: string): AlertNotificationsCommentReceiveAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_ACK,
  userName,
});

export const ackNotificationReceiveError = (error: Error): AlertNotificationsCommentReceiveErrorAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_ACK_ERROR,
  error,
});

export const toggleNotificationSelected = (notificationId: string): AlertNotificationsToggleSelectedAction => ({
  type: AlertNotificationListActionType.TOGGLE_NOTIFICATION_SELECTED,
  notificationId,
});

export const toggleAllNotificationSelected = (selected: boolean): AlertNotificationsToggleAllSelectedAction => ({
  type: AlertNotificationListActionType.TOGGLE_NOTIFICATION_SELECTED_ALL,
  selected,
});

export const bulkAckNotificationRequest = (): AlertNotificationsBulkAckRequestAction => ({
  type: AlertNotificationListActionType.REQUEST_NOTIFICATION_BULK_ACK,
});

export const bulkAckNotificationReceive = (notificationIds: string[]): AlertNotificationsBulkAckReceiveAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_BULK_ACK,
  notificationIds,
});

export const bulkAckNotificationReceiveError = (error: Error): AlertNotificationsBulkAckReceiveErrorAction => ({
  type: AlertNotificationListActionType.RECEIVE_NOTIFICATION_BULK_ACK_ERROR,
  error,
});

export const alertNotificationReceiveNotification = (notification: AlertNotification): AlertNotificationsUpdateAction => ({
  type: AlertNotificationListActionType.ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION,
  notification,
});

export const alertNotificationResetGlow = (notification: AlertNotification): AlertNotificationsUpdateAction => ({
  type: AlertNotificationListActionType.RESET_NOTIFICATION_GLOW,
  notification,
});

export const alertNotificationReset = (): AlertSourceResetAction => ({
  type: AlertNotificationListActionType.RESET_ALERT_FILTER_NOTIFICATIONS,
});
