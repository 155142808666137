/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-empty-function */
import { RawNotification, RawNotificationStatus, WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION } from '@serverfarm/nocd-commons';
// @ts-ignore: No declarations available
import logdown from 'logdown';
import moment from 'moment';
// @ts-ignore: No declarations available
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
// @ts-ignore
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { ClearButton, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import { CSVLink } from 'react-csv';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
// @ts-ignore: No declarations available
import RSA from 'react-simple-auth';
import { Badge, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import io, { Socket } from 'socket.io-client';

import { incommand as incommandProvider } from '../../auth/providers';
import config from '../../config';
import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { messageForUserReloadThePage, openAssetMaintenanceDialog } from '../assets';
import { showErrorNotification, showInfoNotification } from '../notification';
import { Site, SitesListingState } from '../redux/entities';
import { fetchSites, fetchSitesGroups } from '../sites';
import { UserState, CompaniesListingState, logoutUser } from '../user';
import { DateTimeRender } from '../utils';
import { getStatusColor } from '../utils/colors';
import { DateRangeFilter, DateRange, DateRangeFn } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import {
  fetchExportedRawNotifications,
  fetchRawNotifications,
  openMessageInformation,
  resetExportedRawNotifications,
  resetRawNotifications,
  updateRawNotificationListingFilter,
  updateRawNotificationListingHiddenColumns,
  updateRawNotificationListingSort,
} from './data/raw-notifications/actions';
import { ListSort } from './entities';
import { RawNotificationsListingState } from './redux/entities';

const logger = logdown('component:RawNotificationsList');
const SiteColumnFilter = (props: Props & RawNotificationListsProps) => (/*params: any*/) => {
  const selectedOption = props.rawNotificationsListing.filter
    .map((dsf): any[] => {
      let fullSites: any[] = [];
      if (dsf.sitesGroupId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesGroupsListing.sitesGroups
            .flatMap((sitesGroups) => Maybe.fromUndefined(sitesGroups.filter((s) => dsf.sitesGroupId?.find((a) => a === s.id))))
            .getOrElse([]),
        ];
      }
      if (dsf.siteId?.length) {
        fullSites = [
          ...fullSites,
          ...props.sitesListing.sites
            .flatMap((sites: Site[]) => {
              return Maybe.fromUndefined(sites.filter((s) => dsf.siteId?.find((a) => a === s.id)));
            })
            .getOrElse([]),
        ];
      }
      return fullSites;
    })
    .orJust([]);
  // const selectedOption: any[] = [];
  const options = [...props.sitesListing.sites.getOrElse([]), ...props.sitesGroupsListing.sitesGroups.getOrElse([])];
  return (
    <div style={{ width: '180px' }}>
      <Typeahead
        className="unset-heigth"
        multiple={true}
        data-testid="data-source-filter-sites-groups"
        id="data-source-filter-sites-groups"
        onChange={(selected: any) => {
          const siteId: number[] = [];
          const sitesGroupId: number[] = [];
          let providers: string[] = [];
          if (selected && selected.length) {
            for (let i = 0; i < selected.length; i++) {
              if (selected[i].sites) {
                sitesGroupId.push(selected[i].id);
                //providers.push(selected[i].sites.map((s: Site) => s.monitorSiteId));
                providers = [...providers, ...selected[i].sites.map((s: Site) => s.monitorSiteId)];
              } else {
                siteId.push(selected[i].id);
                providers.push(selected[i].monitorSiteId);
              }
            }
          }
          const filter = props.rawNotificationsListing.filter.map((filter: any) => ({
            ...filter,
            sitesGroupId,
            siteId,
            providers,
          }));
          props.updateRawNotificationListingFilter(
            filter,
            // props.rawNotificationsListing.page,
            0,
            props.rawNotificationsListing.sizePerPage,
          );
        }}
        options={options}
        emptyLabel="No Sites Groups found"
        defaultSelected={selectedOption}
        labelKey={(sg: any) => (sg && sg.sites ? `${sg.company.name} - ${sg.name}` : sg.name)}
        disabled={props.rawNotificationsListing.isInProgress}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => {
              const key = `data-source-filter-sites-groups-item-${index}`;
              const value = result && result.sites ? `${result.company.name} - ${result.name}` : result.name;
              return (
                <MenuItem key={key} option={result} position={index}>
                  <span className="rolling-text">{value}</span>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      >
        {({
          selected,
        }: {
          selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
        }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={() => {
                  const siteId: number[] = [];
                  const sitesGroupId: number[] = [];
                  const providers: string[] = [];
                  const filter = props.rawNotificationsListing.filter.map((filter: any) => ({
                    ...filter,
                    sitesGroupId,
                    siteId,
                    providers,
                  }));
                  props.updateRawNotificationListingFilter(filter, props.rawNotificationsListing.page, props.rawNotificationsListing.sizePerPage);
                }}
              />
            </div>
          )
        }
      </Typeahead>
    </div>
  );
};

class MessageFilter extends React.Component<Props & RawNotificationListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & RawNotificationListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.rawNotificationsListing.isInProgress !== this.props.rawNotificationsListing.isInProgress;
    const isTotalChanged = nextProps.rawNotificationsListing.total !== this.props.rawNotificationsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    const initialValue = this.props.rawNotificationsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.source)).getOrElse('');
    console.log('initialValue =>', initialValue);
    return (
      <TextFilter
        id={this.props.id}
        initialState={initialValue}
        placeholder={`Search ${this.props.rawNotificationsListing.total} ${pluralize('records', this.props.rawNotificationsListing.total)}...`}
        isSearching={this.props.rawNotificationsListing.isInProgress}
        onChange={(val) => {
          console.log('changed => ', val);
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.rawNotificationsListing.filter.exists((filter: any) => filter.message !== valueToSet)) {
            this.props.updateRawNotificationListingFilter(
              this.props.rawNotificationsListing.filter.map((filter: any) => ({ ...filter, message: valueToSet })),
              // this.props.rawNotificationsListing.page,
              0,
              this.props.rawNotificationsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const UpdatedUtcDateRangeFilter = (props: Props & { rawNotificationsListing: RawNotificationsListingState }) => (
  <DateRangeFilter
    id="date-range-updated"
    showErrorNotification={props.showErrorNotification}
    value={props.rawNotificationsListing.filter.flatMap((filter) => filter.updatedAt)}
    onReset={() => {
      props.updateRawNotificationListingFilter(
        props.rawNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          updatedAt: None(),
        })),
        // props.rawNotificationsListing.page,
        0,
        props.rawNotificationsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange | DateRangeFn) => {
      props.updateRawNotificationListingFilter(
        props.rawNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          updatedAt: Some(range),
        })),
        // props.rawNotificationsListing.page,
        0,
        props.rawNotificationsListing.sizePerPage,
      );
    }}
    disabled={props.rawNotificationsListing.isInProgress}
  />
);

const StatusColumnFilter = (props: Props & RawNotificationListsProps) => (/*params: any*/) => {
  const selectedState = props.rawNotificationsListing.filter
    .map((dsf) => {
      return dsf.status ? [dsf.status] : [];
    })
    .orJust([]);
  return (
    <Typeahead
      multiple={true}
      data-testid="rawnotification-filter-status"
      id="rawnotifications-filter-status"
      onChange={(selected) => {
        const filter = props.rawNotificationsListing.filter.map((filter: any) => ({
          ...filter,
          status: selected.length > 1 ? selected[1] : selected[0],
        }));
        props.updateRawNotificationListingFilter(
          filter,
          // props.rawNotificationsListing.page,
          0,
          props.rawNotificationsListing.sizePerPage,
        );
      }}
      filterBy={() => {
        return true;
      }}
      options={Object.values(RawNotificationStatus)}
      // placeholder="Select a state"
      selected={selectedState}
      disabled={props.rawNotificationsListing.isInProgress}
      clearButton={false}
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => {
            const key = `alert-filter-state-item-${index}`;
            return (
              <MenuItem key={key} option={result} position={index}>
                <span className="rolling-text">{result}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    >
      {({
        selected,
      }: {
        selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
      }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={() => {
                const status = '';
                const filter = props.rawNotificationsListing.filter.map((filter: any) => ({
                  ...filter,
                  status,
                }));
                props.updateRawNotificationListingFilter(
                  filter,
                  // props.rawNotificationsListing.page,
                  0,
                  props.rawNotificationsListing.sizePerPage,
                );
              }}
            />
          </div>
        )
      }
    </Typeahead>
  );
};

const ExportDropdown = (props: { disabled: boolean; exportData: any; onDropdownToggle: (isOpen: boolean) => void }) => {
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);

  const toggleExportDropdown = () => {
    const isOpen = !exportDropdownOpen;
    props.onDropdownToggle && props.onDropdownToggle(isOpen);
    setExportDropdownOpen(isOpen);
  };

  let csvLink: any;
  return (
    <ButtonDropdown isOpen={exportDropdownOpen && !!props.exportData && !!props.exportData.length} toggle={toggleExportDropdown}>
      <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
        Export as {exportDropdownOpen && (!props.exportData || !props.exportData.length) ? <Spinner size="sm" /> : '...'}
      </DropdownToggle>
      <DropdownMenu>
        {/*<DropdownItem key="export-to-csv" onClick={() => csvLink.link.click()}>*/}
        <DropdownItem key="export-to-csv">
          <CSVLink data={props.exportData} filename="data.csv" className="hidden" ref={(r: any) => (csvLink = r)} target="_blank">
            CSV
          </CSVLink>
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const columns = (props: Props & RawNotificationListsProps) => [
  {
    id: 'id',
    Header: 'Id',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-alert-id';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.id}
        </span>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'provider',
    width: '200px',
    Header: 'Site',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-site';
      return (
        <EllipsisText
          id={`${className}-${row.id}`}
          className={className}
          text={props.sitesListing.sites
            .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
            .map((s: Site) => s.name)
            .getOrElse('N/A')}
          length={50}
        />
      );
    },
    Filter: SiteColumnFilter(props),
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('provider'),
  },
  {
    id: 'message',
    width: 'auto',
    Header: 'Message',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-source';
      return (
        <span>
          <EllipsisText id={`${className}-${row.id}`} className={className} text={row.message} length={120} />{' '}
          <Icon.QuestionCircle
            onClick={() => {
              props.openMessageInformation(row);
            }}
            id={`${className}-${row.id}-question`}
            className="text-info"
            style={{ cursor: 'pointer' }}
          />
          {doesTargetExist(`${className}-${row.id}-question`) && (
            <UncontrolledTooltip target={`${className}-${row.id}-question`}>{row.message}</UncontrolledTooltip>
          )}
        </span>
      );
    },
    Filter: <MessageFilter {...props} id="rawnotifications-header-message-filter" />,
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('message'),
  },
  {
    id: 'status',
    width: '80px',
    Header: 'Status',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-state';
      return (
        <Badge
          style={{
            backgroundColor: getStatusColor(row.status as RawNotificationStatus),
          }}
          id={`${className}-${row.id}`}
          className={className}
        >
          {row.status}
        </Badge>
      );
    },
    Filter: StatusColumnFilter(props),
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('status'),
    disableSortBy: true,
  },
  {
    id: 'sender',
    width: '120px',
    Header: () => {
      return (
        <span data-testid="rawnotifications-header-sender" className="rawnotifications-header-sender">
          Sender
        </span>
      );
    },
    accessor: (row: RawNotification) => {
      const className = 'notifications-list-item-sender';
      return (
        <span>
          <EllipsisText id={`${className}-${row.id}`} className={className} text={row.sender} length={120} />{' '}
        </span>
      );
    },
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('sender'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'recipient',
    width: '120px',
    Header: () => {
      return (
        <span data-testid="rawnotifications-header-recipient" className="rawnotifications-header-recipient">
          Recipient
        </span>
      );
    },
    accessor: (row: RawNotification) => {
      const className = 'notifications-list-item-recipient';
      return (
        <span>
          <EllipsisText id={`${className}-${row.id}`} className={className} text={row.recipient} length={120} />{' '}
        </span>
      );
    },
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('recipient'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: 'updatedSiteLocal',
    width: '150px',
    Header: 'Updated (Site Local)',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-updated-site-local';
      return (
        <DateTimeRender
          id={`${className}-${row.id}`}
          className={className}
          date={row.updatedAt}
          timeZoneRef={props.sitesListing.sites
            .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => row.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
            .map((s: Site) => s.timeZoneRef)
            .orUndefined()}
        />
      );
    },
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('updatedSiteLocal'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'updatedUtc',
    width: '130px',
    Header: 'Updated (UTC)',
    accessor: (row: RawNotification) => {
      const className = 'rawnotifications-list-item-updated-utc';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.updatedAt} isUtc={true} />;
    },
    Filter: UpdatedUtcDateRangeFilter(props),
    isVisible: !props.rawNotificationsListing.hiddenColumns.includes('updatedUtc'),
  },
];

interface RawNotificationListsProps {
  user: UserState;
  rawNotificationsListing: RawNotificationsListingState;
  companiesListing: CompaniesListingState;
  sitesListing: SitesListingState;
}

const crawfishUrl = new URL(config.services.crawfish.api.url);

class Component extends React.Component<Props & RawNotificationListsProps, unknown> {
  protected socket?: Socket;
  protected socketReaction?: NodeJS.Timeout;
  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
  }

  refresh() {
    const updatedAt = this.props.rawNotificationsListing.filter
      .flatMap((filter: any) => Maybe.fromUndefined(filter.updatedAt))
      .getOrElse({ from: '', to: '' });
    const from = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.from).getOrElse(''));
    const to = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.to).getOrElse(''));

    const today = moment().startOf('day');
    const toDate = moment(to || new Date())
      .startOf('day')
      .isSame(today)
      ? moment().toDate()
      : to;

    const filter = this.props.rawNotificationsListing.filter.map((filter: any) => ({
      ...filter,
      updatedAt: Some({ from, to: toDate }),
    }));

    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchRawNotifications(
      filter,
      this.props.rawNotificationsListing.page,
      this.props.rawNotificationsListing.sizePerPage,
      this.props.rawNotificationsListing.sort,
    );

    console.log('FetchingInformation');
  }

  componentDidMount() {
    this.refresh();
    // this.props.updateAlertListingFilter(filter, this.props.rawNotificationsListing.page, this.props.rawNotificationsListing.sizePerPage);
    try {
      this.socket = io(crawfishUrl.host, {
        path: `${crawfishUrl.pathname.replace(/\/$/, '')}/socket.io`,
        transports: ['websocket', 'polling'],
        // upgrade: true,
        rememberUpgrade: true,
        query: {
          token: RSA.getAccessToken(incommandProvider, config.oauth.client.url),
        },
      });
      this.socket?.on(WS_CHANNEL_RECEIVED_ALERT_NOTIFICATION, (serializedAlertNotification: string) => {
        logger.log(`Received serialized alert notification ${serializedAlertNotification}`);

        if (this.socketReaction) {
          clearTimeout(this.socketReaction);
        }

        this.socketReaction = setTimeout(() => {
          this.refresh();
        }, 100);
      });
    } catch (error) {
      this.props.messageForUserReloadThePage();
      this.props.logoutUser();
    }
  }

  componentDidUpdate(prevProps: Props & RawNotificationListsProps) {
    const isFilterUpdated = !prevProps.rawNotificationsListing.filter.equals(this.props.rawNotificationsListing.filter);
    const isSortingUpdated = !prevProps.rawNotificationsListing.sort.equals(this.props.rawNotificationsListing.sort);
    const isPageSizeUpdated = !(prevProps.rawNotificationsListing.sizePerPage === this.props.rawNotificationsListing.sizePerPage);
    const isPageChanged = !(prevProps.rawNotificationsListing.page === this.props.rawNotificationsListing.page);
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged) {
      this.refresh();
    }
  }

  public render() {
    console.log(this.props.rawNotificationsListing.rawNotifications.orJust([]));
    const pageCount = Math.floor(this.props.rawNotificationsListing.total / this.props.rawNotificationsListing.sizePerPage) + 1;
    // const {
    //   allColumns,
    //   state: { pageIndex, pageSize, sortBy, hiddenColumns },
    // } = useTable({ columns: props.columns, data: props.data }) as any;
    //
    // React.useEffect(() => {
    //   this.props.onColumnVisibilityChange(hiddenColumns);
    // }, [hiddenColumns]);
    return (
      <div className="content">
        <ExportDropdown
          disabled={false}
          exportData={this.props.rawNotificationsListing.exportedRawNotifications
            .map((rawNotifications) => {
              return rawNotifications.map((rawNotification) => {
                return {
                  'Raw Id': rawNotification.id,
                  Site: this.props.sitesListing.sites
                    .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => rawNotification.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
                    .map((s: Site) => s.name)
                    .getOrElse('N/A'),
                  Message: rawNotification.message,
                  Sender: rawNotification.sender,
                  Recipient: rawNotification.recipient,
                  Status: rawNotification.status,
                };
              });
            })
            .orJust([])}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedRawNotifications(this.props.rawNotificationsListing.filter, this.props.rawNotificationsListing.sort);
            } else {
              this.props.resetExportedRawNotifications();
            }
          }}
        />
        <Button
          className="btn-sm reset-filter"
          onClick={() => this.props.resetRawNotifications()}
          disabled={this.props.rawNotificationsListing.isInProgress}
        >
          Reset Filters
        </Button>{' '}
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.rawNotificationsListing.rawNotifications.orJust([])}
          exportData={this.props.rawNotificationsListing.exportedRawNotifications
            .map((rawNotifications) => {
              return rawNotifications.map((rawNotification) => {
                return {
                  Site: this.props.sitesListing.sites
                    .flatMap((sites: Site[]) => Maybe.fromUndefined(sites.find((s) => rawNotification.provider.indexOf(`/${s.monitorSiteId}`) === 0)))
                    .map((s: Site) => s.name)
                    .getOrElse('N/A'),
                  Provider: rawNotification.provider,
                  Message: rawNotification.message,
                  Sender: rawNotification.sender,
                  Recipient: rawNotification.recipient,
                  Status: rawNotification.status,
                  Updated: rawNotification.updatedAt,
                };
              });
            })
            .orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.rawNotificationsListing.page !== pageIndex || this.props.rawNotificationsListing.sizePerPage !== pageSize) {
              this.props.updateRawNotificationListingFilter(this.props.rawNotificationsListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<ListSort> = sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            // this.props.rawNotificationsListing.sort.equals(newSort);
            if (!this.props.rawNotificationsListing.sort.equals(newSort)) {
              this.props.updateRawNotificationListingSort(newSort);
            }
          }}
          onColumnVisibilityChange={(hiddenColumns) => {
            console.log('hiddenColumns =>', hiddenColumns);
            this.props.updateRawNotificationListingHiddenColumns(hiddenColumns);
          }}
          onReset={() => {
            this.props.resetRawNotifications();
          }}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedRawNotifications(this.props.rawNotificationsListing.filter, this.props.rawNotificationsListing.sort);
            } else {
              this.props.resetExportedRawNotifications();
            }
          }}
          total={this.props.rawNotificationsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.rawNotificationsListing.page,
            pageSize: this.props.rawNotificationsListing.sizePerPage,
            sortBy: this.props.rawNotificationsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
            hiddenColumns: this.props.rawNotificationsListing.hiddenColumns,
          }}
          isLoading={this.props.rawNotificationsListing.isInProgress}
          renderRow={(row: any) => {
            const classNames = [];
            if (row.original.isNew) {
              classNames.push('glow-row');
            } else if (row.original.isTracked) {
              classNames.push('table-warning');
            }
            return {
              className: classNames.join(' '),
            };
          }}
          showTopManageHiddenCols={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchRawNotifications,
      fetchExportedRawNotifications,
      showInfoNotification,
      showErrorNotification,
      updateRawNotificationListingFilter,
      updateRawNotificationListingHiddenColumns,
      updateRawNotificationListingSort,
      fetchSitesGroups,
      fetchSites,
      resetExportedRawNotifications,
      openMessageInformation,
      openAssetMaintenanceDialog,
      resetRawNotifications,
      logoutUser,
      messageForUserReloadThePage,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  rawNotificationsListing: state.rawNotificationsListing,
  companiesListing: state.companiesListing,
  sitesGroupsListing: state.sitesGroupsListing,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
