import { Maybe, None, Some } from 'monet';

import { Alert } from '../../../alerts';
import { Action } from '../../../redux/actions';
import { Asset } from '../../entities';

/**
 * Action types
 */

export enum AssetMappingDialogActionType {
  OPEN_ASSET_MAPPING_DIALOG = 'OPEN_ASSET_MAPPING_DIALOG',
  CLOSE_ASSET_MAPPING_DIALOG = 'CLOSE_ASSET_MAPPING_DIALOG',

  UPDATE_ASSET_MAPPING_DIALOG_ASSET = 'UPDATE_ASSET_MAPPING_DIALOG_ASSET',
  UPDATE_ASSET_MAPPING_DIALOG_MAPPING_STRING = 'UPDATE_ASSET_MAPPING_DIALOG_MAPPING_STRING',
  UPDATE_ASSET_MAPPING_DIALOG_PROGRESS_STATE = 'UPDATE_ASSET_MAPPING_DIALOG_PROGRESS_STATE',
}

interface AssetMappingDialogToggleAction extends Action<AssetMappingDialogActionType> {
  alert: Maybe<Alert>;
  asset: Maybe<Asset>;
}

interface AssetMappingDialogUpdateMappingAssetAction extends Action<AssetMappingDialogActionType> {
  asset: Maybe<Asset>;
}

interface AssetMappingDialogUpdateMappingStringAction extends Action<AssetMappingDialogActionType> {
  mappingString: Maybe<string>;
}

interface AssetMappingDialogUpdateProgressStateAction extends Action<AssetMappingDialogActionType> {
  progressState: Maybe<{
    isInProgress: boolean;
    error: Maybe<Error>;
  }>;
}

export type AssetMappingDialogAction = AssetMappingDialogToggleAction &
  AssetMappingDialogUpdateMappingAssetAction &
  AssetMappingDialogUpdateMappingStringAction &
  AssetMappingDialogUpdateProgressStateAction;

export const assetMappingDialogOpen = (alert: Maybe<Alert>, asset: Maybe<Asset>): AssetMappingDialogToggleAction => ({
  type: AssetMappingDialogActionType.OPEN_ASSET_MAPPING_DIALOG,
  alert,
  asset,
});

export const assetMappingDialogClose = (): AssetMappingDialogToggleAction => ({
  type: AssetMappingDialogActionType.CLOSE_ASSET_MAPPING_DIALOG,
  alert: None(),
  asset: None(),
});

export const assetMappingDialogUpdateMappingAsset = (asset: Maybe<Asset>): AssetMappingDialogUpdateMappingAssetAction => ({
  type: AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_ASSET,
  asset,
});

export const assetMappingDialogUpdateMappingString = (mappingString: Maybe<string>): AssetMappingDialogUpdateMappingStringAction => ({
  type: AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_MAPPING_STRING,
  mappingString,
});

export const assetMappingDialogUpdateProgressState = (
  progressState: Maybe<{ isInProgress: boolean; error: Maybe<Error> }>,
): AssetMappingDialogUpdateProgressStateAction => ({
  type: AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_PROGRESS_STATE,
  progressState,
});
