/* eslint-disable react/jsx-key */
// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import moment from 'moment-timezone';
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { ClearButton, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import { CSVLink } from 'react-csv';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import RSA from 'react-simple-auth';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { incommand as incommandProvider } from '../../auth/providers';
import config from '../../config';
import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { Site } from '../redux/entities';
import { fetchSites } from '../sites/actions';
import { logoutUser, UserState } from '../user';
import { DATE_TIME_FORMAT_DEFAULT, DateTimeRender, withClickConfirmation } from '../utils';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import {
  deleteAssetMapping,
  fetchAssets,
  messageForUserReloadThePage,
  openAssetMaintenanceDialog,
  openAssetMappingDialog,
  updateAssetListingFilter,
  updateAssetListingSort,
  resetAssets,
  fetchExportedAssets,
  updateAssetListingHiddenColumns,
} from './data';
import { Asset, AssetsListSort } from './entities';
import { exportedAssetsReset } from './redux';
import { AssetsListingState } from './redux/entities';

const logger = logdown('component:AssetsList');

const ButtonWithConfirmation = withClickConfirmation(Button);

// class SiteFilter extends React.Component<Props & AssetListsProps & { id?: string }> {
const SiteFilter = (props: Props & AssetListsProps) => (/*params: any*/) => {
  const selectedSites = props.assetsListing.filter
    .map((filter) => {
      const sites = props.sitesListing.sites
        .flatMap((sites) => Maybe.fromUndefined(sites.filter((s) => filter.site?.find((a) => a == s.monitorSiteId))))
        .getOrElse([]);
      return sites;
    })
    .orJust([]);

  // const options = props.sitesListing.sites.map((sites) => sites.map((s) => ({ id: s.monitorSiteId, label: s.name }))).getOrElse([]);
  const options = [...props.sitesListing.sites.getOrElse([])];
  return (
    <div style={{ width: '580px' }}>
      <Typeahead
        multiple={true}
        data-testid="data-source-filter-company-s"
        id="data-source-filter-company"
        isLoading={props.assetsListing.isInProgress}
        onChange={(selected: any) => {
          let site: string[] = [];
          const sitesGroupId: number[] = [];
          if (selected && selected.length) {
            for (let i = 0; i < selected.length; i++) {
              if (selected[i].sites) {
                sitesGroupId.push(selected[i].id);
                //site.push(selected[i].sites.map((s: Site) => s.monitorSiteId));
                site = [...site, ...selected[i].sites.map((s: Site) => s.monitorSiteId)];
              } else {
                // site.push(selected[i].id);
                site.push(selected[i].monitorSiteId);
              }
            }
          }
          const filter = props.assetsListing.filter.map((filter: any) => {
            return {
              ...filter,
              site,
            };
          });
          props.updateAssetListingFilter(
            filter,
            //  props.assetsListing.page,
            0,
            props.assetsListing.sizePerPage,
          );
        }}
        emptyLabel="No Sites found"
        placeholder="Select Site(s)"
        labelKey={(sg: any) => {
          return sg && sg.sites ? `${sg.company.name} - ${sg.name}` : sg.name;
        }}
        options={options}
        defaultSelected={selectedSites}
        disabled={props.assetsListing.isInProgress}
        clearButton={true}
      >
        {({
          selected,
        }: {
          selected: any[]; // Replace `any[]` with the appropriate type for the `selected` array
        }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={() => {
                  const site: string[] = [];
                  const filter = props.assetsListing.filter.map((filter: any) => ({
                    ...filter,
                    site,
                  }));
                  props.updateAssetListingFilter(filter, props.assetsListing.page, props.assetsListing.sizePerPage);
                }}
              />
            </div>
          )
        }
      </Typeahead>
    </div>
  );
};

const IsInMaintenanceColumnFilter = (props: Props & AssetListsProps) => (/*params: any*/) => {
  const selectedIsInMaintenance = props.assetsListing.filter
    .map((dsf) => {
      return dsf.isInMaintenance ? dsf.isInMaintenance : 'Undefined';
    })
    .orJust('Undefined');
  return (
    <Label className="mt-1">
      <span
        className="clickable"
        onClick={() => {
          const filter = props.assetsListing.filter.map((filter: any) => ({
            ...filter,
            isInMaintenance:
              'Yes' === selectedIsInMaintenance
                ? 'No'
                : 'No' === selectedIsInMaintenance
                ? 'Undefined'
                : 'Undefined' === selectedIsInMaintenance
                ? 'Yes'
                : undefined,
          }));
          props.updateAssetListingFilter(
            filter,
            // props.assetsListing.page,
            0,
            props.assetsListing.sizePerPage,
          );
        }}
      >
        {selectedIsInMaintenance === 'Undefined' ? 'Yes/No' : selectedIsInMaintenance}
      </span>
    </Label>
  );
};

class NameFilter extends React.Component<Props & AssetListsProps & { id?: string }> {
  shouldComponentUpdate(nextProps: Props & AssetListsProps & { id?: string }) {
    const isIsInProgressChanged = nextProps.assetsListing.isInProgress !== this.props.assetsListing.isInProgress;
    const isTotalChanged = nextProps.assetsListing.total !== this.props.assetsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    const initialValue = this.props.assetsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.name)).getOrElse('');

    return (
      <TextFilter
        id={this.props.id}
        placeholder={`Search ${this.props.assetsListing.total} ${pluralize('record', this.props.assetsListing.total)}...`}
        isSearching={this.props.assetsListing.isInProgress}
        initialState={initialValue}
        onChange={(val) => {
          const valueToSet = val === '' || val === undefined ? undefined : val;
          console.log(val);
          if (!this.props.assetsListing.filter.exists((filter: any) => filter.name === valueToSet)) {
            this.props.updateAssetListingFilter(
              this.props.assetsListing.filter.map((filter: any) => ({ ...filter, name: valueToSet })).orElse(Maybe.Some({ name: valueToSet })),
              this.props.assetsListing.page,
              this.props.assetsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const ActionsFormatter = (props: Props) => (row: Asset & any) => {
  // const notification = props.assetNotificationsListing.notification.orUndefined();
  return (
    <span>
      <Button
        className="maintenance-dialog-btn"
        id={`maintenance-dialog-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => props.openAssetMaintenanceDialog(row)}
        disabled={props.assetMappingDelete.isInProgress || props.assetsListing.isInProgress}
      >
        <Icon.ClockHistory className="sm" />
      </Button>{' '}
      <Button
        className="mapping-dialog-btn"
        id={`mapping-dialog-btn-${row.id}`}
        color="info"
        size="sm"
        onClick={() => props.openAssetMappingDialog(Maybe.None(), Maybe.Some(row))}
        disabled={props.assetMappingDelete.isInProgress || props.assetsListing.isInProgress}
      >
        <Icon.Plus className="sm" />
      </Button>{' '}
      {doesTargetExist(`maintenance-dialog-btn-${row.id}`) && (
        <UncontrolledTooltip target={`maintenance-dialog-btn-${row.id}`}>Set maintenance period</UncontrolledTooltip>
      )}
      {doesTargetExist(`mapping-dialog-btn-${row.id}`) && (
        <UncontrolledTooltip target={`mapping-dialog-btn-${row.id}`}>Add Asset mapping string</UncontrolledTooltip>
      )}
    </span>
  );
};

const columns = (props: Props & AssetListsProps) => [
  {
    id: 'site',
    width: 600,
    Header: 'Site',
    accessor: (row: Asset) => {
      const className = 'assets-list-item-site';
      return <EllipsisText id={`${className}-${row.id}`} className={className} text={row.siteName} length={120} />;
    },
    Filter: SiteFilter(props),
    isVisible: !props.assetsListing.hiddenColumns.includes('site'),
  },
  {
    id: 'name',
    width: 600,
    Header: 'Name',
    accessor: (row: Asset) => {
      const className = 'assets-list-item-name';
      return <EllipsisText id={`${className}-${row.id}`} className={className} text={row.name} length={120} />;
    },
    Filter: <NameFilter {...props} id="assets-header-name-filter" />,
    isVisible: !props.assetsListing.hiddenColumns.includes('name'),
  },
  {
    id: 'maintenance',
    width: 600,
    Header: 'Maintenance',
    accessor: (row: Asset) => {
      const className = 'assets-list-item-maintenance';
      return (
        <span>
          {row.maintenance.map((m) => {
            const timeZoneRef = props.sitesListing.sites
              .flatMap((sites) => Maybe.fromUndefined(sites.find((s) => row.monitorSiteId === s.monitorSiteId)))
              .map((s) => s.timeZoneRef)
              .orUndefined();
            return (
              <span>
                <span id={`${className}-${row.id}-start-end`}>
                  <DateTimeRender id={`${className}-${row.id}-start`} className={className} date={m.start} timeZoneRef={timeZoneRef} /> to{' '}
                  <DateTimeRender id={`${className}-${row.id}-end`} className={className} date={m.end} timeZoneRef={timeZoneRef} />
                  <br />
                </span>
              </span>
            );
          })}
        </span>
      );
    },
    Filter: IsInMaintenanceColumnFilter(props),
    isVisible: !props.assetsListing.hiddenColumns.includes('maintenance'),
  },
  {
    id: 'mappings',
    width: 600,
    Header: 'Mappings',
    accessor: (row: Asset) => {
      const className = 'assets-list-item-mappings';
      return (
        <span>
          {row.mappings.map((m, i) => {
            return (
              <span className={className} key={i}>
                {m.mapping}{' '}
                <ButtonWithConfirmation
                  data-testid="delete-button"
                  className="delete-button"
                  id={`delete-button-${m.id}`}
                  color="danger"
                  size="sm"
                  disabled={props.assetMappingDelete.isInProgress || props.assetsListing.isInProgress}
                  onClick={() => props.deleteAssetMapping(row.id, m.id)}
                  popoverCaption="Delete Asset Mapping?"
                  popoverOkButton="Yes"
                  popoverCancelButton="No"
                >
                  {props.assetMappingDelete.isInProgress && props.assetMappingDelete.mappingId.contains(m.id) ? (
                    <Spinner size="sm" />
                  ) : (
                    <Icon.TrashFill className="sm" />
                  )}
                </ButtonWithConfirmation>
                <br />
              </span>
            );
          })}
        </span>
      );
    },
    disableFilters: true,
    disableSortBy: true,
    isVisible: !props.assetsListing.hiddenColumns.includes('mappings'),
  },
  {
    id: 'actions',
    width: '200px',
    Header: 'Actions',
    accessor: (row: Asset) => ActionsFormatter(props)(row),
    disableFilters: true,
    disableSortBy: true,
    isVisible: !props.assetsListing.hiddenColumns.includes('actions'),
  },
];

interface AssetListsProps {
  user: UserState;
  assetsListing: AssetsListingState;
}

const ExportDropdown = (props: { disabled: boolean; exportData: any; onDropdownToggle: (isOpen: boolean) => void }) => {
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);

  const toggleExportDropdown = () => {
    const isOpen = !exportDropdownOpen;
    props.onDropdownToggle && props.onDropdownToggle(isOpen);
    setExportDropdownOpen(isOpen);
  };

  let csvLink: any;
  return (
    <ButtonDropdown isOpen={exportDropdownOpen && !!props.exportData && !!props.exportData.length} toggle={toggleExportDropdown}>
      <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
        Export as {exportDropdownOpen && (!props.exportData || !props.exportData.length) ? <Spinner size="sm" /> : '...'}
      </DropdownToggle>
      <DropdownMenu>
        {/*<DropdownItem key="export-to-csv" onClick={() => csvLink.link.click()}>*/}
        <DropdownItem key="export-to-csv">
          <CSVLink data={props.exportData} filename="data.csv" className="hidden" ref={(r: any) => (csvLink = r)} target="_blank">
            CSV
          </CSVLink>
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

class Component extends React.Component<Props & AssetListsProps, unknown> {
  protected socketReaction?: NodeJS.Timeout;

  componentDidMount() {
    try {
      RSA.getAccessToken(incommandProvider, config.oauth.client.url);
      if (!this.props.showTopManageHiddenCols) {
        const filter = this.props.assetsListing.filter.map((filter: any) => ({
          ...filter,
          isInMaintenance: 'Yes',
        }));
        this.props.updateAssetListingFilter(
          filter,
          // this.props.assetsListing.page,
          0,
          this.props.assetsListing.sizePerPage,
        );
      } else {
        this.refresh();
      }
    } catch (error) {
      this.props.messageForUserReloadThePage();
      this.props.logoutUser();
    }
  }
  // componentWillUnmount() {}
  refresh() {
    this.props.fetchSites();
    this.props.fetchAssets(
      this.props.assetsListing.filter,
      this.props.assetsListing.page,
      this.props.assetsListing.sizePerPage,
      this.props.assetsListing.sort,
    );
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  componentDidUpdate(prevProps: Props & AssetListsProps) {
    const isFilterUpdated = !prevProps.assetsListing.filter.equals(this.props.assetsListing.filter);
    const isSortingUpdated = !prevProps.assetsListing.sort.equals(this.props.assetsListing.sort);
    const isPageSizeUpdated = !(prevProps.assetsListing.sizePerPage === this.props.assetsListing.sizePerPage);
    const isPageChanged = !(prevProps.assetsListing.page === this.props.assetsListing.page);

    const isMaintenanceDialogClosed = prevProps.assetMaintenanceDialog.isOpen && !this.props.assetMaintenanceDialog.isOpen;
    const isMappingDialogClosed = prevProps.assetMappingDialog.isOpen && !this.props.assetMappingDialog.isOpen;
    const isMappingDeleted = prevProps.assetMappingDelete.isInProgress && !this.props.assetMappingDelete.isInProgress;

    if (
      isFilterUpdated ||
      isSortingUpdated ||
      isPageSizeUpdated ||
      isPageChanged ||
      isMaintenanceDialogClosed ||
      isMappingDialogClosed ||
      isMappingDeleted
    ) {
      this.refresh();
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.assetsListing.total / this.props.assetsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <ExportDropdown
          disabled={false}
          exportData={this.props.assetsListing.exportedAssets
            .map((alerts: any[]) => {
              return alerts.map((alert: any) => {
                return {
                  Site: this.props.sitesListing.sites
                    .flatMap((sites: any) => Maybe.fromUndefined(sites.find((s: any) => alert.monitorSiteId === s.monitorSiteId)))
                    .map((s) => s.name)
                    .getOrElse('N/A'),
                  Asset: alert.name,
                  Mappings: alert.mappings.map((s: any) => s.mapping).join(';'),
                  Maintenance: alert.maintenance
                    .map((s: any) => `${moment(s.start).format(DATE_TIME_FORMAT_DEFAULT)} To ${moment(s.end).format(DATE_TIME_FORMAT_DEFAULT)}`)
                    .join(''),
                };
              });
            })
            .orJust([])}
          // exportData={this.props.assetsListing.exportedAssets.orJust([])}
          onDropdownToggle={(isOpen: boolean) => {
            if (isOpen) {
              this.props.fetchExportedAssets(this.props.assetsListing.filter, this.props.assetsListing.sort);
            } else {
              this.props.exportedAssetsReset();
            }
          }}
        />
        <Button
          className="btn-sm reset-filter"
          onClick={() => {
            this.props.resetAssets();
          }}
          disabled={this.props.assetsListing.isInProgress}
        >
          Reset Filters
        </Button>{' '}
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.assetsListing.assets.orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.assetsListing.page !== pageIndex || this.props.assetsListing.sizePerPage !== pageSize) {
              this.props.updateAssetListingFilter(this.props.assetsListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<AssetsListSort> = sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            this.props.assetsListing.sort.equals(newSort);
            if (!this.props.assetsListing.sort.equals(newSort)) {
              this.props.updateAssetListingSort(newSort);
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onColumnVisibilityChange={(hiddenColumns) => {
            this.props.updateAssetListingHiddenColumns(hiddenColumns);
          }}
          total={this.props.assetsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.assetsListing.page,
            pageSize: this.props.assetsListing.sizePerPage,
            sortBy: this.props.assetsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
            hiddenColumns: this.props.assetsListing.hiddenColumns,
          }}
          isLoading={this.props.assetsListing.isInProgress}
          renderRow={(row: any) => {
            // console.log('Rendering row', row);
            return {
              className: row.original.isNew ? 'glow-row' : '',
            };
          }}
          onDropdownToggle={(isOpen) => {
            if (isOpen) {
              this.props.fetchExportedAssets(this.props.assetsListing.filter, this.props.assetsListing.sort);
            } else {
              this.props.exportedAssetsReset();
            }
          }}
          showTopManageHiddenCols={this.props.showTopManageHiddenCols}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAssets,
      updateAssetListingFilter,
      updateAssetListingSort,
      updateAssetListingHiddenColumns,
      deleteAssetMapping,
      openAssetMaintenanceDialog,
      openAssetMappingDialog,
      fetchSites,
      logoutUser,
      messageForUserReloadThePage,
      resetAssets,
      fetchExportedAssets,
      exportedAssetsReset,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  assetsListing: state.assetsListing,
  assetMaintenanceDialog: state.assetMaintenanceDialog,
  assetMappingDialog: state.assetMappingDialog,
  assetMappingDelete: state.assetMappingDelete,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps &
  typeof dispatchProps & {
    showTopManageHiddenCols: boolean;
  };

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
