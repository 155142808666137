import { AlertNotification, Company } from '@serverfarm/nocd-commons';
import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe, None, Some } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { showErrorNotification, showInfoNotification, showWarningNotification } from '../../../notification';
import { UserProfile } from '../../../user';
import { ListSort, AlertsListFilter, Alert, NotificationGroup } from '../../entities';
import {
  alertListingFilterUpdate,
  alertsReceive,
  alertsReceiveError,
  alertsRequest,
  alertListingSortUpdate,
  alertsReset,
  alertsReceiveNotification,
  alertResetGlow,
  allAlertsResetGlow,
  exportedAlertsReceive,
  exportedAlertsReset,
  sendStakeholderNotificationProgressStateUpdate,
  alertStakeholderNotificationClose,
  alertStakeholderNotificationOpen,
  alertSourceInformationOpen,
  alertSourceInformationClose,
  stakeholderNotificationUpdate,
  alertNocInCommandRequestClose,
  alertNocInCommandRequestOpen,
  sendNocInCommandProgressStateUpdate,
  nocInCommandRequestUpdate,
  alertsTrackingTotalCountProgressStateUpdate,
  alertListingHiddenColumnsUpdate,
  assetsInMaintenanceClose,
  assetsInMaintenanceOpen,
  alertSelect,
  monitoringSystemsOpen,
} from '../../redux/alerts/actions';

import { alertDeserialize, AlertsListQueryResponse, AlertsTotalQueryResponse } from './entities';
import { alertListingFilterSerialize } from './serdes';

export const fetchAlerts =
  (
    filter: Maybe<AlertsListFilter>,
    page: number,
    sizePerPage: number,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(alertsRequest(filter, sizePerPage, sort));
    const serializedFilter = alertListingFilterSerialize(filter.getOrElse({ updatedAt: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alerts`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : moment().subtract(1, 'hour').toISOString(),
        to: serializedFilter.to ? serializedFilter.to : moment().toISOString(),
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field.replace('updatedSiteLocal', 'updated').replace('updatedUtc', 'updated')}:${s.order}`).orUndefined(),
        source: serializedFilter.source,
        state: serializedFilter.state,

        sites: serializedFilter.providers,
        asset: serializedFilter.asset,
        severityLevel: serializedFilter.severityLevel,
        isInMaintenance: serializedFilter.isInMaintenance,
        acknowledged: serializedFilter.acknowledged,
        acknowledgedBy: serializedFilter.acknowledgedBy,
        isNotMapped: serializedFilter.isNotMapped,
        isTracked: serializedFilter.isTracked,
        hasWorkLogEntries: serializedFilter.hasWorkLogEntries,
        isSiteNotAware: serializedFilter.isSiteNotAware,
        alertId: serializedFilter.alertId,
      },
    })
      .then((result) => result.data as AlertsListQueryResponse)
      .then((alertsData) => {
        dispatch(
          alertsReceive(
            alertsData.alerts.map((alert) => alertDeserialize(alert)),
            page,
            alertsData.total,
          ),
        );
        setTimeout(() => {
          if (getState().alertsListing.isInProgress) {
            return;
          }
          dispatch(allAlertsResetGlow());
        }, 1000);
      })
      .catch((error) => {
        dispatch(alertsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const fetchExportedAlerts =
  (
    filter: Maybe<AlertsListFilter>,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    const serializedFilter = alertListingFilterSerialize(filter.getOrElse({ updatedAt: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alerts`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : moment().subtract(1, 'hour').toISOString(),
        to: serializedFilter.to ? serializedFilter.to : moment().toISOString(),
        page: 1,
        limit: 500,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
        source: serializedFilter.source,
        state: serializedFilter.state,

        sites: serializedFilter.providers,
        asset: serializedFilter.asset,
        severityLevel: serializedFilter.severityLevel,
        isInMaintenance: serializedFilter.isInMaintenance,
        acknowledged: serializedFilter.acknowledged,
        acknowledgedBy: serializedFilter.acknowledgedBy,
        isNotMapped: serializedFilter.isNotMapped,
      },
    })
      .then((result) => result.data as AlertsListQueryResponse)
      .then((alertsData) => {
        dispatch(exportedAlertsReceive(alertsData.alerts.map((alert) => alertDeserialize(alert))));
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const getTotalTrackedAlerts =
  () =>
  // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(alertsTrackingTotalCountProgressStateUpdate(Some({ isInProgress: true, error: None() }), None()));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alert/tracking/total`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as AlertsTotalQueryResponse)
      .then(({ total }) => {
        dispatch(alertsTrackingTotalCountProgressStateUpdate(Some({ isInProgress: false, error: None() }), Some(total)));
        // showInfoNotification(`Stop tracking alert ${alert.source}}`, 'Alert Tracking')(dispatch);
      })
      .catch((error) => {
        dispatch(alertsTrackingTotalCountProgressStateUpdate(Some({ isInProgress: false, error: Some(error) }), None()));
        showErrorNotification(error)(dispatch);
      });
  };

export const trackAlert =
  (alert: Alert) =>
  // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alert.id}/tracking`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as AlertsListQueryResponse)
      .then(() => {
        const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
        dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        dispatch(getTotalTrackedAlerts());
        showWarningNotification(alert.source, 'Start Alert Tracking')(dispatch);
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const unTrackAlert =
  (alert: Alert) =>
  // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    axios({
      method: 'delete',
      url: `${config.services.crawfish.api.url}/alert/${alert.id}/tracking`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as AlertsListQueryResponse)
      .then(() => {
        const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
        dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        dispatch(getTotalTrackedAlerts());
        showInfoNotification(alert.source, 'Stop Alert Tracking')(dispatch);
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const pinAlert =
  (alert: Alert) =>
  // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alert.id}/pinned`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        isPinned: true,
      },
    })
      .then(() => {
        const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
        dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        showWarningNotification(alert.source, 'Pin Alert')(dispatch);
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const unPinAlert =
  (alert: Alert) =>
  // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alert.id}/pinned`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        isPinned: false,
      },
    })
      .then(() => {
        const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
        dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        showInfoNotification(alert.source, 'Unpin Alert')(dispatch);
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const resetExportedAlerts =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(exportedAlertsReset());
  };

export const updateAlertListingFilter =
  (filter: Maybe<AlertsListFilter>, page: number, sizePerPage: number, logger: Logger = logdown('alerts:data:actions:updateAlertListingFilter')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(alertListingFilterUpdate(filter, page, sizePerPage));
  };

export const updateAlertListingHiddenColumns =
  (hiddenColumns: string[], logger: Logger = logdown('alerts:data:actions:updateAlertListingHiddenColumns')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(alertListingHiddenColumnsUpdate(hiddenColumns));
  };

export const updateAlertListingSort =
  (sort: Maybe<ListSort>, logger: Logger = logdown('alerts:data:actions:updateAlertListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
    dispatch(alertListingSortUpdate(sort));
  };

export const resetAlerts =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertsReset());
  };

let resetGlowTimeout: any;
export const alertsProcessNotification =
  (notification: AlertNotification) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    if (getState().alertsListing.isInProgress) {
      return;
    }
    dispatch(alertsReceiveNotification(notification));
    if (resetGlowTimeout) {
      clearTimeout(resetGlowTimeout);
    }
    resetGlowTimeout = setTimeout(() => {
      if (getState().alertsListing.isInProgress) {
        return;
      }
      dispatch(alertResetGlow(notification));
      resetGlowTimeout = undefined;
    }, 1000);
  };

export const requestAlertsMapping =
  (
    alertId: string,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, sizePerPage, sort));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alertId}/mapping`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      // .then((result) => result.data as AlertsListQueryResponse)
      // .then((alertsData) => {
      //   dispatch(
      //     alertsReceive(
      //       alertsData.alerts.map((alert) => alertDeserialize(alert)),
      //       page,
      //       alertsData.total,
      //     ),
      //   );
      //   setTimeout(() => {
      //     if (getState().alertsListing.isInProgress) {
      //       return;
      //     }
      //     dispatch(allAlertsResetGlow());
      //   }, 1000);
      // })
      .then(() => {
        showInfoNotification(`Asset mapping will be soon updated for the Alarm its been attached to`)(dispatch);
      })
      .catch((error) => {
        // dispatch(alertsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const openAlertSourceInformation =
  (alert: Alert) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertSourceInformationOpen(alert));
  };

export const closeAlertSourceInformation =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertSourceInformationClose());
  };

export const openAssetsInMaintenance =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetsInMaintenanceOpen());
  };

export const openMonitoringSystems =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemsOpen());
  };

export const closeAssetsInMaintenance =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetsInMaintenanceClose());
  };

export const openAlertStakeholderNotification =
  (alert: Alert) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertStakeholderNotificationOpen(alert));
  };

export const closeAlertStakeholderNotification =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertStakeholderNotificationClose());
  };

export const setSelectedAlert =
  (alertSelected: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertSelect(alertSelected));
  };
export const sendStakeholderNotification =
  (logger: Logger = logdown('alerts:data:actions:sendStakeholderNotification')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const { alert, notificationGroup, subject, content } = getState().stakeHolderNotificationDialog;

    if (alert.isNone() || subject.isNone() || content.isNone()) {
      throw new Error('Alert, subject and content have to be set');
    }

    dispatch(sendStakeholderNotificationProgressStateUpdate(Some({ isInProgress: true, error: None() })));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alert.map((a) => a.id).getOrElse('')}/stakeholder_notification`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        notificationGroupId: notificationGroup.map((g) => g.id).orUndefined(),
        subject: subject.getOrElse(''),
        content: content.getOrElse(''),
      },
    })
      .then(() => {
        dispatch(sendStakeholderNotificationProgressStateUpdate(Some({ isInProgress: false, error: None() })));
        dispatch(closeAlertStakeholderNotification());
        showInfoNotification(`Sent successfully`, 'Stakeholder Notification')(dispatch);
      })
      .catch((error) => {
        dispatch(sendStakeholderNotificationProgressStateUpdate(Some({ isInProgress: true, error: Some(error) })));
        showErrorNotification(error)(dispatch);
      });
  };

export const updateStakeholderNotification =
  (
    alert: Maybe<Alert>,
    notificationGroup: Maybe<NotificationGroup>,
    subject: Maybe<string>,
    content: Maybe<string>,
    logger: Logger = logdown('alerts:data:actions:updateStakeholderNotification'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(stakeholderNotificationUpdate(alert, notificationGroup, subject, content));
  };

export const openAlertNocInCommandRequest =
  (alert: Alert) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNocInCommandRequestOpen(alert));
  };

export const closeAlertNocInCommandRequest =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNocInCommandRequestClose());
  };

export const sendNocInCommandRequest =
  (logger: Logger = logdown('alerts:data:actions:sendNocInCommandRequest')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const { alert, assignee, alertDate, acknowledgedDate, notificationGroup, requestPriority, company, notes } = getState().nocInCommandRequestDialog;

    const { sites } = getState().sitesListing;

    if (alert.isNone() || notes.isNone()) {
      throw new Error('Alert, notes ... have to be set');
    }
    const alertId = alert.map((a) => a.id).orUndefined();

    dispatch(sendNocInCommandProgressStateUpdate(Some({ isInProgress: true, error: None() })));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/alert/${alertId}/nocd_incommand_request`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        title: alert.map((a) => a.provider).orUndefined(),
        assigneeId: assignee.flatMap((a) => Maybe.fromUndefined(a.userId)).orUndefined(),
        alertDate: alertDate.map((a) => new Date(a).toISOString()).orUndefined(),
        acknowledgedDate: acknowledgedDate.map((a) => new Date(a).toISOString()).orUndefined(),
        alertSeverityLevel: alert.map((a) => a.severityLevel).orUndefined(),
        notificationGroupId: notificationGroup.map((g) => g.id).orUndefined(),
        requestPriority: requestPriority.orUndefined(),
        companyId: company.map((c) => c.id).orUndefined(),
        siteId: sites
          .flatMap((sites) =>
            Maybe.fromUndefined(sites.find((s) => alert.map((a) => a.provider.startsWith(`/${s.monitorSiteId}/`)).getOrElse(false))).map((s) => s.id),
          )
          .orUndefined(),
        notes: notes.orUndefined(),
      },
    })
      .then((response) => {
        dispatch(sendNocInCommandProgressStateUpdate(Some({ isInProgress: false, error: None() })));
        dispatch(closeAlertNocInCommandRequest());
        showInfoNotification(
          `InCommand NOC Alert Request has been created. Request id is #${response.data.requestId}`,
          'InCommand NOC Alert',
        )(dispatch);
      })
      .catch((error) => {
        dispatch(sendNocInCommandProgressStateUpdate(Some({ isInProgress: false, error: Some(error) })));
        showErrorNotification(error)(dispatch);
      });
  };

export const updateNocInCommandRequest =
  (
    alert: Maybe<Alert>,
    assignee: Maybe<UserProfile>,
    alertDate: Maybe<Date>,
    acknowledgedDate: Maybe<Date>,
    notificationGroup: Maybe<NotificationGroup>,
    requestPriority: Maybe<string>,
    company: Maybe<Company>,
    notes: Maybe<string>,
    logger: Logger = logdown('alerts:data:actions:updateNocInCommandRequest'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(nocInCommandRequestUpdate(alert, assignee, alertDate, acknowledgedDate, notificationGroup, requestPriority, company, notes));
  };
