import { Dispatch } from 'redux';

import config from '../../config';
import { State } from '../../reducers';
import axios from '../../services/axios';
import { showErrorNotification } from '../notification';
import {
  sitesGroupsReceive,
  sitesGroupsReceiveError,
  sitesGroupsRequest,
  sitesReceive,
  sitesReceiveError,
  sitesRequest,
  sitesReset,
} from '../redux/sites/actions';

import { SiteApiEntity, siteDeserialize, SitesGroupsListQueryResponse, SitesListQueryResponse } from './entities';

import { SitesGroupApiEntity, sitesGroupDeserialize } from '.';

export const fetchSites =
  () =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(sitesRequest());
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/in-command/sites`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as SitesListQueryResponse)
      .then((sitesData) => {
        dispatch(sitesReceive(sitesData.sites.map((site: SiteApiEntity) => siteDeserialize(site))));
      })
      .catch((error) => {
        dispatch(sitesReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const fetchSitesGroups =
  () =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(sitesGroupsRequest());
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/in-command/sites/groups`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as SitesGroupsListQueryResponse)
      .then((sitesData) => {
        dispatch(sitesGroupsReceive(sitesData.sitesGroups.map((sitesGroup: SitesGroupApiEntity) => sitesGroupDeserialize(sitesGroup))));
      })
      .catch((error) => {
        dispatch(sitesGroupsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };
export const resetSite =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(sitesReset());
  };
