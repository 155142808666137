import { MonitoringSystemsListFilter } from '../../entities';

import { MonitoringSystemsListFilterApiEntity } from './entities';

export const monitoringSystemListingFilterSerialize = (filter: MonitoringSystemsListFilter): MonitoringSystemsListFilterApiEntity => {
  const dsFilter: MonitoringSystemsListFilterApiEntity = {
    providers: filter.providers,
    sites: filter.sites,
  };
  return dsFilter;
};
