import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Card, CardBody, CardHeader, Form, Spinner, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';

import { State } from '../../reducers';

import { loginUser, removeUserLoginAttempt } from './data/actions';

let toggleHowToGetAccessModal: () => void;

const HowToGetAccessModal = () => {
  const [isOpen, setOpen] = useState(false);

  toggleHowToGetAccessModal = () => setOpen((prevState: boolean) => !prevState);

  return (
    <Modal className="full-screen-modal" contentClassName="h-100 w-100" isOpen={isOpen} toggle={toggleHowToGetAccessModal}>
      <ModalHeader>User Guide: How to get access?</ModalHeader>
      <ModalBody>
        <iframe title="user guide" src="/user_guide_login.pdf" width="100%" height="100%" frameBorder="0"></iframe>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleHowToGetAccessModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

class LoginPanel extends React.Component<Props> {
  protected howToGetAccessTooltipRef = React.createRef<UncontrolledTooltip>();

  componentDidMount() {
    if (this.howToGetAccessTooltipRef.current) this.howToGetAccessTooltipRef.current.setState({ isOpen: true });
    setTimeout(() => {
      if (this.howToGetAccessTooltipRef.current) this.howToGetAccessTooltipRef.current.setState({ isOpen: false });
    }, 3000);
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader className="text-center">
            Welcome to
            <br />
            <strong>NOC Dashboard</strong>
          </CardHeader>
          <CardBody>
            <Form>
              <Button id="login-button" color="primary" onClick={() => this.props.loginUser()}>
                Login with InCommand
              </Button>
            </Form>
          </CardBody>
        </Card>
        <HowToGetAccessModal />
      </div>
    );
  }
}

class Component extends React.Component<Props, unknown> {
  componentDidMount() {
    this.props.removeUserLoginAttempt();
  }
  public render() {
    return (
      <div className="page-center main-panel-bkg-color">
        {this.props.user.isLoggingIn ? <Spinner style={{ width: '4rem', height: '4rem' }} color="primary" /> : <LoginPanel {...this.props} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loginUser,
      removeUserLoginAttempt,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, unknown>(mapStateToProps, mapDispatchToProps)(Component);
