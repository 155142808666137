import logdown from 'logdown';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Dispatch, bindActionCreators } from 'redux';

import { State } from '../../reducers';
import {
  openAssetMappingDialog,
  openAssetMaintenanceDialog,
  messageForUserReloadThePage,
  deleteAssetMapping,
  exportedAssetsReset,
  fetchAssets,
  fetchExportedAssets,
  resetAssets,
  updateAssetListingFilter,
  updateAssetListingHiddenColumns,
  updateAssetListingSort,
} from '../assets';
import AssetsList from '../assets/AssetsList';
import { fetchSites } from '../sites';
import { logoutUser } from '../user';

import { closeAssetsInMaintenance } from './data/alerts';

const logger = logdown('component:AssetsInMaintenanceModal');

class Component extends React.Component<Props, unknown> {
  public render() {
    return (
      <Modal
        id="assets-in-maintenance-information-modal"
        data-testid="assets-in-maintenance-information-modal"
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.assetsInMaintenanceDialog.isOpen}
        toggle={this.close.bind(this)}
      >
        <ModalHeader data-testid="assets-in-maintenance-modal-header">
          <EllipsisText className="stakeholder-assetsinmaintenance-header" text={`Assets In Maintenance`} length={75} />
        </ModalHeader>
        <ModalBody>
          <AssetsList showTopManageHiddenCols={false} {...this.props} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="stakeholder-notifications-cancel-button"
            data-testid="alert-source-information-modal-cancel-button"
            color="secondary"
            onClick={this.close.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected close(e: any) {
    e.preventDefault();
    // if (this.props.stakeHolderNotificationSending.workLogs.isSome()) {
    //   return this.props.closeAlertStakeholderNotification();
    // }
    return this.props.closeAssetsInMaintenance();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      closeAssetsInMaintenance,
      fetchAssets,
      updateAssetListingFilter,
      updateAssetListingSort,
      updateAssetListingHiddenColumns,
      deleteAssetMapping,
      openAssetMaintenanceDialog,
      openAssetMappingDialog,
      fetchSites,
      logoutUser,
      messageForUserReloadThePage,
      resetAssets,
      fetchExportedAssets,
      exportedAssetsReset,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  assetsInMaintenanceDialog: state.assetsInMaintenanceDialog,
  assetsListing: state.assetsListing,
  assetMaintenanceDialog: state.assetMaintenanceDialog,
  assetMappingDialog: state.assetMappingDialog,
  assetMappingDelete: state.assetMappingDelete,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
