import { Comment } from '@serverfarm/nocd-commons';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemText } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import { commentNotification, editNotificationComment } from './data/alert-notifications';

const messagesEndRef = React.createRef<HTMLDivElement>();
const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};

const CommentsList = (comments: Comment[]) => {
  return (
    <ListGroup
      style={{
        overflow: 'scroll',
        maxHeight: '400px',
      }}
    >
      {comments.length > 0 ? (
        comments.map((c, idx) => {
          return (
            <ListGroupItem
              style={{
                backgroundColor: idx % 2 === 0 ? '#eee' : 'inherit',
              }}
              className="alert-notification-comment"
              key={`comment-${idx}-${c.id}`}
            >
              <ListGroupItemText>
                <span className="pull-right">{moment(c.createdAt).format('DD-MM-YYYY HH:mm:ss')}</span>
                {/* <span className="pull-left"><strong>{c.userName}</strong></span> */}
              </ListGroupItemText>
              <ListGroupItemText className="alert-notification-comment-text">
                <hr className="hr separator" style={{ marginTop: '25px' }} />
                {c.comment}
              </ListGroupItemText>
            </ListGroupItem>
          );
        })
      ) : (
        <strong className="alert-notification-comment-text">There are no comments on this Notification.</strong>
      )}{' '}
      <div ref={messagesEndRef} />
      <hr />
    </ListGroup>
  );
};

const CommentArea = (props: Props) => {
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.alertNotificationsListing.notificationComment.forEach((n) => {
            props.commentNotification(n, props.alertNotificationsListing.commentEditing.getOrElse(''));
          });
          scrollToBottom();
        }}
      >
        <FormGroup>
          <Label for="comment">Coment</Label>
          <Input
            data-testid="data-source-editor-name-input"
            id="alert-notification-comment-area"
            type="textarea"
            value={props.alertNotificationsListing.commentEditing.getOrElse('')}
            onChange={(e) => props.editNotificationComment(e.target.value)}
          />
        </FormGroup>
        <Button color="primary" id="comment-btn" disabled={props.alertNotificationsListing.isInProgress}>
          Comment
        </Button>
      </Form>
    </div>
  );
};

class NotificationComments extends React.Component<Props, unknown> {
  componentDidMount() {
    scrollToBottom();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.alertNotificationsListing.notificationComment
        .map((n) => n.comments || [])
        .equals(this.props.alertNotificationsListing.notificationComment.map((n) => n.comments || []))
    ) {
      scrollToBottom();
    }
  }

  public render() {
    // const pageCount = Math.floor(this.props.alertNotificationsListing.total / this.props.alertNotificationsListing.sizePerPage) + 1;
    return (
      <div className="content">
        {this.props.alertNotificationsListing.notificationComment
          .map((n) => n.comments || [])
          .map((comments) => {
            return CommentsList(comments);
          })
          .orUndefined()}
        {CommentArea(this.props)}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      commentNotification,
      editNotificationComment,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  alertNotificationsListing: state.alertNotificationsListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(NotificationComments);
