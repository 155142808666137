import { WorkLog } from '@serverfarm/nocd-commons';
import { Maybe, Some } from 'monet';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemText, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { Site } from '../redux/entities';
import { fetchSites } from '../sites';
import { DateTimeRender } from '../utils';

import { addWorkLogEntry, fetchAlertWorkLog } from './data/alert-worklog';
import { updateAlertWorkLog, updateAlertWorkLogIsSiteAware } from './data/alert-worklog/actions';
import { Alert } from './entities';

const messagesEndRef = React.createRef<HTMLDivElement>();
const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};

const WorkLogList = (alert: Maybe<Alert>, workLog: WorkLog[], sites: Maybe<Site[]>) => {
  const IsSiteAwareIcon = (props: { id: string; isSiteAware: boolean }) => {
    return props.isSiteAware === false ? (
      <span className="text-danger">
        <Icon.ExclamationCircle id={`alert-work-log-comment-site-aware-${props.id}`} className="sm" />
        {doesTargetExist(`alert-work-log-comment-site-aware-${props.id}`) && (
          <UncontrolledTooltip target={`alert-work-log-comment-site-aware-${props.id}`}>
            Site was not aware of the event described
          </UncontrolledTooltip>
        )}
      </span>
    ) : (
      <span></span>
    );
  };

  const CommentIcon = (props: { id: string; type: string }) => {
    switch (props.type) {
      case 'comment':
        return (
          <span>
            <Icon.ChatText id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Operator comment</UncontrolledTooltip>
            )}
          </span>
        );
      case 'pinning':
        return (
          <span>
            <Icon.Pin id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Item Pinning comment</UncontrolledTooltip>
            )}
          </span>
        );
      case 'tracking':
        return (
          <span>
            <Icon.Eye id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Item Tracking comment</UncontrolledTooltip>
            )}
          </span>
        );
      case 'add_mapping':
        return (
          <span>
            <Icon.Plus id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Item Add Mapping comment</UncontrolledTooltip>
            )}
          </span>
        );
      case 'stakeholder_notification':
        return (
          <span>
            <Icon.Bell id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Item Stakeholder Notification comment</UncontrolledTooltip>
            )}
          </span>
        );
      case 'nocd_alert_request_creation':
        return (
          <span>
            <Icon.Check id={`alert-work-log-comment-icon-${props.id}`} className="sm" />
            {doesTargetExist(`alert-work-log-comment-icon-${props.id}`) && (
              <UncontrolledTooltip target={`alert-work-log-comment-icon-${props.id}`}>Item NOCD Alert Request created comment</UncontrolledTooltip>
            )}
          </span>
        );
      default:
        return <span></span>;
    }
  };
  return (
    <ListGroup
      style={{
        overflow: 'scroll',
        maxHeight: '400px',
      }}
    >
      {workLog.length > 0 ? (
        workLog.map((wL, idx) => {
          return (
            <ListGroupItem
              style={{
                backgroundColor: idx % 2 === 0 ? '#eee' : 'inherit',
              }}
              className="alert-work-log-comment"
              key={`comment-${idx}-${wL.id}`}
            >
              <ListGroupItemText>
                <span className="pull-left">
                  <CommentIcon id={wL.id} type={wL.type} /> <IsSiteAwareIcon id={wL.id} isSiteAware={wL.metadata?.isSiteAware} />
                </span>
                <span className="pull-right">
                  <DateTimeRender
                    id={`alert-work-log-date-${wL.id}`}
                    className={'alert-work-log-comment'}
                    date={wL.createdAt}
                    timeZoneRef={sites
                      .flatMap((sites) =>
                        Maybe.fromUndefined(
                          sites.find(
                            (s) =>
                              alert
                                .map((a) => a.provider)
                                .getOrElse('')
                                .indexOf(`/${s.monitorSiteId}`) === 0,
                          ),
                        ),
                      )
                      .map((s) => s.timeZoneRef)
                      .orUndefined()}
                  />{' '}
                  by <strong>{wL.userName}</strong>
                </span>
              </ListGroupItemText>
              <ListGroupItemText className="alert-work-log-comment-text">
                <hr className="hr separator" style={{ marginTop: '25px' }} />
                {wL.workLog}
              </ListGroupItemText>
            </ListGroupItem>
          );
        })
      ) : (
        <strong className="alert-work-log-comment-text">There are no Work Log entries found for the Alert. Add one using the form below</strong>
      )}{' '}
      <div ref={messagesEndRef} />
      <hr />
    </ListGroup>
  );
};

const CommentArea = (props: Props) => {
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const workLog = props.alertWorkLogAdding.workLog.orUndefined();
          const isSiteAware = props.alertWorkLogAdding.isSiteAware.orUndefined();
          props.addWorkLogEntry(props.alertWorkLogAdding.alert.map((a) => a.id).getOrElse(''), workLog, isSiteAware);
          scrollToBottom();
        }}
      >
        <FormGroup>
          <Label for="comment">Add to Work Log</Label>
          <Input
            data-testid="work-log-editor-name-input"
            id="work-log-editor-name-input"
            type="textarea"
            value={props.alertWorkLogAdding.workLog.getOrElse('')}
            onChange={(e) => props.updateAlertWorkLog(props.alertWorkLogListing.alert, Some(e.target.value))}
          />
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              id="work-log-is-site-aware-checkbox"
              defaultChecked={false}
              onChange={(e) => {
                props.updateAlertWorkLogIsSiteAware(props.alertWorkLogListing.alert, Some(!e.target.checked));
              }}
            />
            Site is not aware
          </Label>
        </FormGroup>
        <Button color="primary" id="comment-btn" disabled={props.alertWorkLogAdding.isInProgress}>
          Add
        </Button>
      </Form>
    </div>
  );
};

class WorkLogComponent extends React.Component<Props, unknown> {
  refresh() {
    // const filter = this.props.alertsListing.filter.orElse(Some({ updated: None<DateRange>() }));
    this.props.fetchSites();
    this.props.fetchAlertWorkLog(
      this.props.alertWorkLogListing.alert.map((a) => a.id).getOrElse(''),
      this.props.alertWorkLogListing.filter,
      this.props.alertWorkLogListing.sort,
      this.props.alertWorkLogListing.page,
      this.props.alertWorkLogListing.sizePerPage,
    );
  }

  // componentDidUpdate(prevProps: Props) {
  //   if (!prevProps.alertWorkLogListing.workLogs.equals(this.props.alertWorkLogListing.workLogs)) {
  //     scrollToBottom();
  //   }
  // }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    const isFilterUpdated = !prevProps.alertWorkLogListing.filter.equals(this.props.alertWorkLogListing.filter);
    const isSortingUpdated = !prevProps.alertWorkLogListing.sort.equals(this.props.alertWorkLogListing.sort);
    const isPageSizeUpdated = !(prevProps.alertWorkLogListing.sizePerPage === this.props.alertWorkLogListing.sizePerPage);
    const isPageChanged = !(prevProps.alertWorkLogListing.page === this.props.alertWorkLogListing.page);
    const workLogWasAdded = !this.props.alertWorkLogListing.isInProgress && prevProps.alertWorkLogAdding.isInProgress;
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged || workLogWasAdded) {
      this.refresh();
    }
  }

  public render() {
    // const pageCount = Math.floor(this.props.alertNotificationsListing.total / this.props.alertNotificationsListing.sizePerPage) + 1;
    return (
      <div className="content">
        {this.props.alertWorkLogListing.isInProgress ? (
          <Spinner size="sm" />
        ) : (
          this.props.alertWorkLogListing.workLogs
            .map((workLogs) => {
              return WorkLogList(this.props.alertWorkLogListing.alert, workLogs, this.props.sitesListing.sites);
            })
            .orUndefined()
        )}
        <CommentArea {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      updateAlertWorkLog,
      updateAlertWorkLogIsSiteAware,
      addWorkLogEntry,
      fetchSites,
      fetchAlertWorkLog,
      // commentNotification,
      // editNotificationComment,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  alertWorkLogListing: state.alertWorkLogListing,
  alertWorkLogAdding: state.alertWorkLogAdding,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(WorkLogComponent);
