import { SeverityLevel } from '@serverfarm/nocd-commons';
import { Maybe, None } from 'monet';

import { parseDateRangeValue } from '../../../utils/dateRangeFilter';
import { AlertsListFilter } from '../../entities';

import { AlertsListFilterApiEntity } from './entities';

export const alertListingFilterSerialize = (filter: AlertsListFilter = { updatedAt: None() }): AlertsListFilterApiEntity => {
  const dsFilter: AlertsListFilterApiEntity = {
    source: filter.source,
    providers: filter.providers,
    asset: filter.asset,
    state: filter.state,
    severityLevel:
      filter.severityLevel && filter.severityLevel.length && Array.isArray(filter.severityLevel)
        ? filter.severityLevel.map((item: number) => SeverityLevel[item] as unknown as number)
        : undefined,
    isInMaintenance:
      filter.isInMaintenance !== undefined
        ? filter.isInMaintenance === 'Yes'
          ? 'true'
          : filter.isInMaintenance === 'No'
          ? 'false'
          : undefined
        : undefined,
    acknowledged:
      filter.acknowledged !== undefined ? (filter.acknowledged === 'Yes' ? 'true' : filter.acknowledged === 'No' ? 'false' : undefined) : undefined,
    acknowledgedBy: filter.acknowledgedBy,
    isNotMapped:
      filter.isNotMapped !== undefined ? (filter.isNotMapped === true ? 'true' : filter.isNotMapped === false ? 'false' : undefined) : undefined,
    isTracked: filter.isTracked !== undefined ? (filter.isTracked === true ? 'true' : filter.isTracked === false ? 'false' : undefined) : undefined,
    hasWorkLogEntries:
      filter.hasWorkLogEntries !== undefined
        ? filter.hasWorkLogEntries === true
          ? 'true'
          : filter.hasWorkLogEntries === false
          ? 'false'
          : undefined
        : undefined,
    isSiteNotAware:
      filter.isSiteNotAware !== undefined
        ? filter.isSiteNotAware === true
          ? 'true'
          : filter.isSiteNotAware === false
          ? 'false'
          : undefined
        : undefined,
    alertId: filter.alertId,
  };
  if (filter.updatedAt) {
    dsFilter.from = filter.updatedAt
      .flatMap((updatedAt) => {
        const fromDate = parseDateRangeValue(updatedAt)?.from;
        if (fromDate instanceof Date) {
          return Maybe.fromUndefined(fromDate.toISOString());
        }
        return Maybe.some('');
      })
      .orUndefined();

    dsFilter.to = filter.updatedAt
      .flatMap((updatedAt) => {
        const toDate = parseDateRangeValue(updatedAt)?.to;
        if (toDate instanceof Date) {
          return Maybe.fromUndefined(toDate.toISOString());
        }
        return Maybe.some('');
      })
      .orUndefined();
  }
  return dsFilter;
};
