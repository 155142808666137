import { WorkLog, WorkLogSerialized } from '@serverfarm/nocd-commons';
import moment from 'moment';

export interface AlertWorkLogListFilter {
  alertId: string;
  userName?: string;
  workLog?: string;
}

export interface AlertWorkLogListQueryResponse {
  workLogs: WorkLogSerialized[];
  page: number;
  perPage: number;
  total: number;
}

export const alertWorkLogDeserialize = (workLog: WorkLogSerialized): WorkLog => {
  return {
    id: workLog.id,
    userId: workLog.userId,
    userName: workLog.userName,
    companyId: workLog.companyId,
    workLog: workLog.workLog,
    type: workLog.type,
    metadata: workLog.metadata,
    createdAt: moment(workLog.createdAt).toDate(),
  };
};
