import { combineReducers } from 'redux';

import {
  AlertsListingState,
  alertsListingReducer,
  AlertNotificationsListingState,
  AlertWorkLogListingState,
  AlertWorkLogAddingState,
  StakeholderNotificationDialogState,
  stakeHolderNotificationDialogReducer,
  AlertSourceInformationDialogState,
  alertSourceInformationDialogReducer,
  NotificationGroupsListingState,
  NocInCommandRequestDialogState,
  nocInCommandRequestDialogReducer,
  alertsTrackingTotalCountReducer,
  AlertsTrackingTotalCountState,
  SitesGroupsListingState,
  AssetsInMaintenanceDialogState,
  assetsInMaintenanceDialogReducer,
  monitoringSystemsDialogReducer,
} from './components/alerts';
import { alertNotificationsListingReducer } from './components/alerts/redux/alert-notifications/reducers';
import { alertWorkLogAddingReducer, alertWorkLogListingReducer } from './components/alerts/redux/alert-worklog/reducers';
import { notificationGroupsListingReducer } from './components/alerts/redux/notification-groups/reducers';
import { assetMappingDeleteReducer, AssetMappingDeleteState, assetsListingReducer, AssetsListingState } from './components/assets';
import { AssetMaintenanceDialogState } from './components/assets/redux/asset-maintenance/entities';
import { assetMaintenanceDialogReducer } from './components/assets/redux/asset-maintenance/reducers';
import { AssetMappingDialogState } from './components/assets/redux/asset-mapping/entities';
import { assetMappingDialogReducer } from './components/assets/redux/asset-mapping/reducers';
import { eventsListingReducer, EventsListingState } from './components/events';
import {
  MonitoringSystemEditingState,
  MonitoringSystemsListingState,
  MonitoringSystemSavingState,
  MonitoringSystemDeletingState,
  MonitoringSystemsDialogState,
  MonitoringSystemIncidentInformationDialogState,
} from './components/monitoring-systems/redux/entities';
import {
  monitoringSystemEditingReducer,
  monitoringSystemsListingReducer,
  monitoringSystemSavingReducer,
  monitoringSystemDeletingReducer,
  monitoringSystemIncidentInformationDialogReducer,
} from './components/monitoring-systems/redux/monitoring-systems/reducers';
import { RoutesState, SideBarState, NavBarState, routesReducer, sideBarReducer, navBarReducer } from './components/navigation';
import { notificationsReducer, NotificationState } from './components/notification';
import { MessageInformationDialogState, RawNotificationsListingState } from './components/notifications-status/redux/entities';
import { messageInformationDialogReducer, rawNotificationsListingReducer } from './components/notifications-status/redux/raw-notifications/reducers';
import { SitesListingState } from './components/redux/entities';
import { sitesGroupsListingReducer, sitesListingReducer } from './components/redux/sites/reducers';
import { ColleaguesListingState, companiesListingReducer, CompaniesListingState, userReducer, UserState } from './components/user';
import { colleaguesListingReducer } from './components/user/redux/reducers';

export interface State {
  user: UserState;
  companiesListing: CompaniesListingState;
  colleaguesListing: ColleaguesListingState;
  routes: RoutesState;
  sideBar: SideBarState;
  navBar: NavBarState;
  notifications: NotificationState[];
  eventsListing: EventsListingState;
  alertsListing: AlertsListingState;
  rawNotificationsListing: RawNotificationsListingState;
  monitoringSystemsListing: MonitoringSystemsListingState;
  monitoringSystemEditing: MonitoringSystemEditingState;
  monitoringSystemSaving: MonitoringSystemSavingState;
  monitoringSystemDeleting: MonitoringSystemDeletingState;
  alertNotificationsListing: AlertNotificationsListingState;
  sitesListing: SitesListingState;
  sitesGroupsListing: SitesGroupsListingState;
  notificationGroupsListing: NotificationGroupsListingState;
  assetsListing: AssetsListingState;
  assetMaintenanceDialog: AssetMaintenanceDialogState;
  assetMappingDialog: AssetMappingDialogState;
  assetMappingDelete: AssetMappingDeleteState;
  alertWorkLogListing: AlertWorkLogListingState;
  alertWorkLogAdding: AlertWorkLogAddingState;
  alertSourceInformationDialog: AlertSourceInformationDialogState;
  stakeHolderNotificationDialog: StakeholderNotificationDialogState;
  nocInCommandRequestDialog: NocInCommandRequestDialogState;
  alertsTrackingTotalCount: AlertsTrackingTotalCountState;
  assetsInMaintenanceDialog: AssetsInMaintenanceDialogState;
  messageInformationDialog: MessageInformationDialogState;
  monitoringSystemsDialog: MonitoringSystemsDialogState;
  monitoringSystemIncidentInformationDialog: MonitoringSystemIncidentInformationDialogState;
}

export default combineReducers<State>({
  user: userReducer,
  companiesListing: companiesListingReducer,
  colleaguesListing: colleaguesListingReducer,
  routes: routesReducer,
  sideBar: sideBarReducer,
  navBar: navBarReducer,
  notifications: notificationsReducer,
  eventsListing: eventsListingReducer,
  alertsListing: alertsListingReducer,
  rawNotificationsListing: rawNotificationsListingReducer,
  monitoringSystemsListing: monitoringSystemsListingReducer,
  monitoringSystemEditing: monitoringSystemEditingReducer,
  monitoringSystemSaving: monitoringSystemSavingReducer,
  monitoringSystemDeleting: monitoringSystemDeletingReducer,
  alertNotificationsListing: alertNotificationsListingReducer,
  sitesListing: sitesListingReducer,
  sitesGroupsListing: sitesGroupsListingReducer,
  notificationGroupsListing: notificationGroupsListingReducer,
  assetsListing: assetsListingReducer,
  assetMaintenanceDialog: assetMaintenanceDialogReducer,
  assetMappingDialog: assetMappingDialogReducer,
  assetMappingDelete: assetMappingDeleteReducer,
  alertWorkLogListing: alertWorkLogListingReducer,
  alertWorkLogAdding: alertWorkLogAddingReducer,
  alertSourceInformationDialog: alertSourceInformationDialogReducer,
  stakeHolderNotificationDialog: stakeHolderNotificationDialogReducer,
  nocInCommandRequestDialog: nocInCommandRequestDialogReducer,
  alertsTrackingTotalCount: alertsTrackingTotalCountReducer,
  assetsInMaintenanceDialog: assetsInMaintenanceDialogReducer,
  messageInformationDialog: messageInformationDialogReducer,
  monitoringSystemsDialog: monitoringSystemsDialogReducer,
  monitoringSystemIncidentInformationDialog: monitoringSystemIncidentInformationDialogReducer,
});
