import logdown from 'logdown';
import { Maybe } from 'monet';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, Input, Label, ModalFooter, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { IconType } from '../../components/notification/Notification';
import { State } from '../../reducers';
import { showNotification } from '../notification';
import { Site } from '../redux/entities';
import { DateTimeRender } from '../utils';

import {
  acknowledgeMonitoringSystemIncident,
  closeMonitoringSystemIncident,
  closeMonitoringSystemIncidentInformation,
} from './data/monitoring-systems';

const logger = logdown('component:AlertSourceInformationModal');

class Component extends React.Component<Props, unknown> {
  inputRef: HTMLTextAreaElement | HTMLInputElement | null = null;

  componentDidMount() {
    this.updateInputHeight();
  }

  componentDidUpdate() {
    this.updateInputHeight();
  }

  updateInputHeight() {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.style.height = 'auto';
        this.inputRef.style.height = `${this.inputRef.scrollHeight}px`;
        this.inputRef.style.overflow = 'hidden';
      }
    }, 250);
  }

  copyToClipBoard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.props.showNotification(`Copied to clipboard`, IconType.SUCCESS);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  public render() {
    logger.log(`Monitoring System Incident => ${this.props.monitoringSystemIncidentInformationDialog.monitoringSystem.isSome()}`);

    const incident = this.props.monitoringSystemIncidentInformationDialog.monitoringSystem.map((a: any) => a.lastIncident).getOrElse(null);
    const isClosed = incident && incident.status === 'closed';
    const isAcknowledged = incident && incident.status === 'acknowledged';

    return (
      <Modal
        id="alert-source-information-modal"
        data-testid="alert-source-information-modal"
        size="lg"
        isOpen={this.props.monitoringSystemIncidentInformationDialog.isOpen}
        toggle={this.close.bind(this)}
      >
        <ModalHeader data-testid="alert-source-information-modal-header">
          <EllipsisText className="stakeholder-notifications-header" text={`Incident Information Details`} length={75} />
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: '8px' }}>
            <Label className="mr-sm-2">Monitoring System Affected</Label>
            <Input
              name="monitoringSystem"
              id="monitoringSystem"
              value={this.props.monitoringSystemIncidentInformationDialog.monitoringSystem.map((a: any) => a.name || '').getOrElse('N/A')}
              disabled={true}
            />
          </div>
          <div className="row" style={{ marginBottom: '8px' }}>
            <div className="col">
              <Label className="mr-sm-2">Last Event Received (Site Local)</Label>
              <div className="input-like">
                <DateTimeRender
                  id={`incident-last-event-received-at-local`}
                  date={this.props.monitoringSystemIncidentInformationDialog.monitoringSystem
                    .map((a: any) => a.lastEventReceivedAt || '')
                    .getOrElse('N/A')}
                  timeZoneRef={this.props.sitesListing.sites
                    .flatMap((sites: Site[]) =>
                      Maybe.fromUndefined(
                        sites.find(
                          (s) =>
                            this.props.monitoringSystemIncidentInformationDialog.monitoringSystem
                              .map((a: any) => a.site || '')
                              .getOrElse('N/A')
                              .indexOf(`${s.monitorSiteId}`) === 0,
                        ),
                      ),
                    )
                    .map((s: Site) => s.timeZoneRef)
                    .orUndefined()}
                />
              </div>
            </div>
            <div className="col">
              <Label className="mr-sm-2">Last Event Received (UTC)</Label>
              <div className="input-like">
                <DateTimeRender
                  id={`incident-last-event-received-at-utc`}
                  date={this.props.monitoringSystemIncidentInformationDialog.monitoringSystem
                    .map((a: any) => a.lastEventReceivedAt || '')
                    .getOrElse('N/A')}
                  isUtc={true}
                />
              </div>
            </div>
          </div>
          <Label className="mr-sm-2">Opsgenie Alert Id</Label>
          <InputGroup>
            <Input name="alertId" id="alertId" value={incident ? incident.alertId : 'N/A'} disabled={true} />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ background: '#e3e3e3', cursor: 'pointer' }}>
                <Icon.Clipboard
                  onClick={() => {
                    this.copyToClipBoard(incident ? incident.alertId : 'N/A');
                  }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <div className="row" style={{ marginBottom: '8px' }}>
            <div className="col">
              <Label className="mr-sm-2">Creation Date (UTC)</Label>
              <div className="input-like">
                <DateTimeRender
                  id={`incident-last-event-received-at-utc`}
                  date={incident && incident.createdAt ? incident.createdAt : new Date()}
                  isUtc={true}
                />
              </div>
            </div>
            <div className="col">
              <Label className="mr-sm-2">Status</Label>
              <Input name="status" id="status" value={incident ? incident.status.toUpperCase() : 'N/A'} disabled={true} />
            </div>
          </div>

          {incident && incident.acknowledgedBy && incident.acknowledgedAt && (
            <>
              <div className="row" style={{ marginBottom: '8px' }}>
                <div className="col">
                  <Label className="mr-sm-2">Acknowledged By</Label>
                  <Input name="acknowledgedBy" id="acknowledgedBy" value={incident.acknowledgedBy} disabled={true} />
                </div>
                <div className="col">
                  <Label className="mr-sm-2">Acknowledged At</Label>
                  <Input name="acknowledgedAt" id="acknowledgedAt" value={new Date(incident.acknowledgedAt).toLocaleString()} disabled={true} />
                </div>
              </div>
            </>
          )}
          {isClosed ? (
            <>
              <div className="row" style={{ marginBottom: '8px' }}>
                <div className="col">
                  <Label className="mr-sm-2">Closed By</Label>
                  <Input name="closedBy" id="closedBy" value={incident.closedBy || 'N/A'} disabled={true} />
                </div>
                <div className="col">
                  <Label className="mr-sm-2">Closed At</Label>
                  <Input
                    name="closedAt"
                    id="closedAt"
                    value={incident.closedAt ? new Date(incident.closedAt).toLocaleString() : 'N/A'}
                    disabled={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {!isAcknowledged && (
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.acknowledgeMonitoringSystemIncident(incident.alertId);
                  }}
                >
                  Acknowledge Alert
                </Button>
              )}
              {isAcknowledged && (
                <>
                  <Button color="secondary" disabled>
                    Acknowledge Alert
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.props.closeMonitoringSystemIncident(incident.alertId);
                    }}
                  >
                    Close Alert
                  </Button>
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="stakeholder-notifications-cancel-button"
            data-testid="alert-source-information-modal-cancel-button"
            color="secondary"
            onClick={this.close.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected close(e: any) {
    e.preventDefault();
    return this.props.closeMonitoringSystemIncidentInformation();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      closeMonitoringSystemIncidentInformation,
      showNotification,
      acknowledgeMonitoringSystemIncident,
      closeMonitoringSystemIncident,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  monitoringSystemIncidentInformationDialog: state.monitoringSystemIncidentInformationDialog,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
