import logdown from 'logdown';
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
// @ts-ignore
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { bindActionCreators, Dispatch } from 'redux';
import io from 'socket.io-client';

import config from '../../config';
import { State } from '../../reducers';
import { showErrorNotification } from '../notification';
import { UserState, CompaniesListingState } from '../user';
import { DateTimeRender } from '../utils';
import { DateRangeFilter, DateRange, DateRangeFn } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import { fetchEvents, updateEventListingFilter, updateEventListingSort } from './data/actions';
import { Event, EventsListSort } from './entities';
import { EventsListingState } from './redux/entities';

const logger = logdown('component:EventsList');

class SourceFilter extends React.Component<Props & EventListsProps> {
  shouldComponentUpdate(nextProps: Props & EventListsProps) {
    const isIsInProgressChanged = nextProps.eventsListing.isInProgress !== this.props.eventsListing.isInProgress;
    const isTotalChanged = nextProps.eventsListing.total !== this.props.eventsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        placeholder={`Search ${this.props.eventsListing.total} ${pluralize('record', this.props.eventsListing.total)}...`}
        isSearching={this.props.eventsListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.eventsListing.filter.exists((filter) => filter.source !== valueToSet)) {
            this.props.updateEventListingFilter(
              this.props.eventsListing.filter.map((filter) => ({ ...filter, source: valueToSet })),
              this.props.eventsListing.page,
              this.props.eventsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

class MessageFilter extends React.Component<Props & EventListsProps> {
  shouldComponentUpdate(nextProps: Props & EventListsProps) {
    const isIsInProgressChanged = nextProps.eventsListing.isInProgress !== this.props.eventsListing.isInProgress;
    const isTotalChanged = nextProps.eventsListing.total !== this.props.eventsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        placeholder={`Search ${this.props.eventsListing.total} ${pluralize('record', this.props.eventsListing.total)}...`}
        isSearching={this.props.eventsListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.eventsListing.filter.exists((filter) => filter.message !== valueToSet)) {
            this.props.updateEventListingFilter(
              this.props.eventsListing.filter.map((filter) => ({ ...filter, message: valueToSet })),
              this.props.eventsListing.page,
              this.props.eventsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const CreatedDateRangeFilter = (props: Props & { eventsListing: EventsListingState }) => (
  <DateRangeFilter
    id="date-range-filter-created"
    showErrorNotification={props.showErrorNotification}
    value={props.eventsListing.filter.flatMap((filter) => filter.createdAt)}
    onReset={() => {
      props.updateEventListingFilter(
        props.eventsListing.filter.map((filter) => ({
          ...filter,
          created: None(),
        })),
        props.eventsListing.page,
        props.eventsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange | DateRangeFn) => {
      props.updateEventListingFilter(
        props.eventsListing.filter.map((filter) => ({
          ...filter,
          created: Some(range as DateRange),
        })),
        props.eventsListing.page,
        props.eventsListing.sizePerPage,
      );
    }}
  />
);

const columns = (props: Props & EventListsProps) => [
  {
    id: 'source',
    width: 200,
    Header: 'Source',
    accessor: (row: Event) => {
      const className = 'events-list-item-source';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.source}
        </span>
      );
    },
    Filter: <SourceFilter {...props} />,
  },
  {
    id: 'message',
    width: 'auto',
    Header: 'Message',
    accessor: (row: Event) => {
      const className = 'events-list-item-message';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.message}
        </span>
      );
    },
    Filter: <MessageFilter {...props} />,
    // disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'created',
    width: 200,
    Header: 'Created',
    accessor: (row: Event) => {
      const className = 'events-list-item-created';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.created} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: CreatedDateRangeFilter(props),
  },
];

interface EventListsProps {
  user: UserState;
  eventsListing: EventsListingState;
  companiesListing: CompaniesListingState;
}

const crawfishUrl = new URL(config.services.crawfish.api.url);

class Component extends React.Component<Props & EventListsProps, unknown> {
  protected readonly socket = io(crawfishUrl.host, {
    path: `${crawfishUrl.pathname.replace(/\/$/, '')}/socket.io`,
    transports: ['websocket'],
    // upgrade: false,
  });
  protected socketReaction?: NodeJS.Timeout;

  componentDidMount() {
    const filter = Some({ createdAt: None<DateRange>(), updatedAt: None<DateRange>() });
    this.props.updateEventListingFilter(filter, this.props.eventsListing.page, this.props.eventsListing.sizePerPage);

    logger.log('will connect socket');

    // var socket = io.connect(source, { transports: ['websocket'], upgrade: false });
    // /** ***** Socket events *********** */
    // socket.on(socketConstants.USERS_LOGGED_CHANGED, function () {
    //   console.log('socketService: users logged changed');
    //   $rootScope.$broadcast(socketConstants.USERS_LOGGED_CHANGED);
    // });

    // this.socket.on('connect', () => {
    //   logger.log('socket connect');
    // });
    // this.socket.on('disconnect', () => {
    //   logger.log('socket disconnect');
    // });

    this.socket.on('events.list.updated', (data: any) => {
      logger.log(`Received socket event to 'events.list.updated'. Payload: '${JSON.stringify(data)}'`);
      if (this.socketReaction) {
        clearTimeout(this.socketReaction);
      }
      this.socketReaction = setTimeout(() => {
        this.props.fetchEvents(
          this.props.eventsListing.filter,
          this.props.eventsListing.page,
          this.props.eventsListing.sizePerPage,
          this.props.eventsListing.sort,
        );
      }, 1000);
    });
  }

  componentDidUpdate(prevProps: Props & EventListsProps) {
    const isFilterUpdated = !prevProps.eventsListing.filter.equals(this.props.eventsListing.filter);
    const isSortingUpdated = !prevProps.eventsListing.sort.equals(this.props.eventsListing.sort);
    const isPageSizeUpdated = !(prevProps.eventsListing.sizePerPage === this.props.eventsListing.sizePerPage);
    const isPageChanged = !(prevProps.eventsListing.page === this.props.eventsListing.page);
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged) {
      this.props.fetchEvents(
        this.props.eventsListing.filter,
        this.props.eventsListing.page,
        this.props.eventsListing.sizePerPage,
        this.props.eventsListing.sort,
      );
      return;
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.eventsListing.total / this.props.eventsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.eventsListing.events.orJust([])}
          exportData={this.props.eventsListing.events.orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.eventsListing.page !== pageIndex || this.props.eventsListing.sizePerPage !== pageSize) {
              this.props.updateEventListingFilter(this.props.eventsListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<EventsListSort> = sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            this.props.eventsListing.sort.equals(newSort);
            if (!this.props.eventsListing.sort.equals(newSort)) {
              this.props.updateEventListingSort(newSort);
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onColumnVisibilityChange={() => {}}
          onReset={() => {
            this.props.updateEventListingFilter(
              this.props.eventsListing.filter.map((filter) => ({ ...filter })),
              this.props.eventsListing.page,
              this.props.eventsListing.sizePerPage,
            );
          }}
          total={this.props.eventsListing.total}
          controlledPageCount={pageCount}
          initialState={{
            pageIndex: this.props.eventsListing.page,
            pageSize: this.props.eventsListing.sizePerPage,
            sortBy: this.props.eventsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
          }}
          isLoading={this.props.eventsListing.isInProgress}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchEvents,
      updateEventListingFilter,
      updateEventListingSort,
      showErrorNotification,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (state: State) => ({});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
