import { Maybe, None, Some } from 'monet';

import { Alert } from '../../../alerts';
import { Action, InProgressState } from '../../../redux/actions';
import { Asset } from '../../entities';

/**
 * Action types
 */

export enum AssetMaintenanceDialogActionType {
  OPEN_ASSET_MAINTENANCE_DIALOG = 'OPEN_ASSET_MAINTENANCE_DIALOG',
  UPDATE_ASSET_MAINTENANCE_DIALOG_DATE_RANGE = 'UPDATE_ASSET_MAINTENANCE_DIALOG_DATE_RANGE',
  UPDATE_ASSET_MAINTENANCE_DIALOG_PROGRESS_STATE = 'UPDATE_ASSET_MAINTENANCE_DIALOG_PROGRESS_STATE',
  CLOSE_ASSET_MAINTENANCE_DIALOG = 'CLOSE_ASSET_MAINTENANCE_DIALOG',
}

interface AssetMaintenanceDialogToggleAction extends Action<AssetMaintenanceDialogActionType> {
  asset: Maybe<Asset>;
  alert: Maybe<Alert>;
  acknowledgeAlert: Maybe<boolean>;
}

interface AssetMaintenanceDialogUpdateDateRangeAction extends Action<AssetMaintenanceDialogActionType> {
  startDate: Maybe<Date>;
  endDate: Maybe<Date>;
}

interface AssetMaintenanceDialogUpdateProgressStateAction extends Action<AssetMaintenanceDialogActionType> {
  assetMaintenanceProgressState: Maybe<InProgressState>;
}

export type AssetMaintenanceDialogAction = AssetMaintenanceDialogToggleAction &
  AssetMaintenanceDialogUpdateDateRangeAction &
  AssetMaintenanceDialogUpdateProgressStateAction;

export const assetMaintenanceDialogOpen = (asset: Asset, alert?: Alert, acknowledgeAlert?: boolean): AssetMaintenanceDialogToggleAction => ({
  type: AssetMaintenanceDialogActionType.OPEN_ASSET_MAINTENANCE_DIALOG,
  asset: Some(asset),
  alert: Maybe.fromUndefined(alert),
  acknowledgeAlert: Maybe.fromUndefined(acknowledgeAlert),
});

export const assetMaintenanceDialogUpdateDateRange = (startDate: Date, endDate: Date): AssetMaintenanceDialogUpdateDateRangeAction => ({
  type: AssetMaintenanceDialogActionType.UPDATE_ASSET_MAINTENANCE_DIALOG_DATE_RANGE,
  startDate: Some(startDate),
  endDate: Some(endDate),
});

export const assetMaintenanceDialogUpdateProgressState = (
  progressState: Maybe<InProgressState>,
): AssetMaintenanceDialogUpdateProgressStateAction => ({
  type: AssetMaintenanceDialogActionType.UPDATE_ASSET_MAINTENANCE_DIALOG_PROGRESS_STATE,
  assetMaintenanceProgressState: progressState,
});

export const assetMaintenanceDialogClose = (): AssetMaintenanceDialogToggleAction => ({
  type: AssetMaintenanceDialogActionType.CLOSE_ASSET_MAINTENANCE_DIALOG,
  asset: None(),
  alert: None(),
  acknowledgeAlert: None(),
});
