import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { ActionType, EventsFetchAction } from './actions';
import { EVENT_INITIAL_STATE } from './constants';
import { EventsListingState } from './entities';

export const eventsListingReducer: Reducer<EventsListingState, EventsFetchAction> = (state = EVENT_INITIAL_STATE.eventsListingState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_EVENTS:
      return {
        ...state,
        isInProgress: true,
        events: Maybe.None(),
        total: 0,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_EVENTS:
      return {
        ...state,
        isInProgress: false,
        events: action.events.length ? Maybe.Some(action.events) : Maybe.None(),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_EVENTS_ERROR:
      return {
        ...state,
        isInProgress: false,
        events: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.UPDATE_EVENTS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_EVENTS_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_EVENTS:
      return EVENT_INITIAL_STATE.eventsListingState;
    default:
      return state;
  }
};
