import moment from 'moment';

import { Event } from '../entities';

export interface EventsListFilterApiEntity {
  from?: string;
  to?: string;
  fromUpdated?: string;
  toUpdated?: string;
  name?: string;
  companyId?: string;
  companyIds?: string[];
}

export interface EventApiEntity {
  id: string;
  source: string;
  message: string;
  updated?: string;
  created: string;
}

export interface EventsListQueryResponse {
  events: EventApiEntity[];
  page: number;
  total: number;
}

export const eventSerialize = (ds: Event): EventApiEntity => ({
  id: ds.id,
  source: ds.source,
  message: ds.message,
  created: ds.created.toISOString(),
});

export const eventDeserialize = (dss: EventApiEntity): Event => {
  return {
    id: dss.id,
    source: dss.source,
    message: dss.message,
    created: moment(dss.created).toDate(),
  };
};
