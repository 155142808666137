import { AlertState } from '@serverfarm/nocd-commons';
import moment from 'moment';

import { AssetApiEntity, assetDeserialize } from '../../../assets/data/entities';
import { Alert } from '../../entities';
import { NotificationApiEntity } from '../alert-notifications';

export interface AlertsListFilterApiEntity {
  from?: string;
  to?: string;
  fromUpdated?: string;
  toUpdated?: string;
  source?: string;
  providers?: string[];
  asset?: string;
  severityLevel?: number[];
  state?: string;
  companyId?: string;
  companyIds?: string[];
  isInMaintenance?: string;
  acknowledged?: string;
  acknowledgedBy?: string;
  isNotMapped?: string;
  isTracked?: string;
  hasWorkLogEntries?: string;
  isSiteNotAware?: string;
  alertId?: string[];
}

// export interface AlertAssetApiEntity {
//   id: number;
//   name: string;
// }

export interface AlertApiEntity {
  updatedAt: string;
  id: string;
  lastNotification: NotificationApiEntity;
  notificationsCount: number;
  commentsCount: number;
  alertWorkLogs: any;
  alertWorkLogsCount: number;
  alertWorkLogsCountByType: {
    comment?: number;
    pinning?: number;
    tracking?: number;
    add_mapping?: number;
    stakeholder_notification?: number;
    nocd_alert_request_creation?: number;
  };
  severityLevel: number;
  state: AlertState;
  source: string;
  isInMaintenance: boolean;
  isPinned: boolean;
  provider: string;
  assets: AssetApiEntity[];
  isTracked?: boolean;
}

export interface AlertsListQueryResponse {
  alerts: AlertApiEntity[];
  page: number;
  perPage: number;
  total: number;
}

export interface AlertsTotalQueryResponse {
  total: number;
}

export const alertDeserialize = (dss: AlertApiEntity): Alert => {
  // console.log('ALERT', dss);
  return {
    updatedAt: moment(dss.updatedAt).toDate(),
    id: dss.id,
    notificationsCount: dss.notificationsCount,
    commentsCount: dss.commentsCount,
    alertWorkLogs: dss.alertWorkLogs,
    alertWorkLogsCount: dss.alertWorkLogsCount,
    alertWorkLogsCountByType: dss.alertWorkLogsCountByType,
    severityLevel: dss.severityLevel,
    source: dss.source,
    state: dss.state,
    isInMaintenance: dss.isInMaintenance,
    isPinned: dss.isPinned,
    provider: dss.provider,
    assets: dss.assets.map(assetDeserialize),
    // @ts-ignore
    lastNotification: dss.lastNotification,
    isTracked: dss.isTracked,
  };
};
