import { AlertNotification } from '@serverfarm/nocd-commons';
import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { Alert } from '../../entities';
import {
  ackNotificationReceive,
  ackNotificationReceiveError,
  ackNotificationRequest,
  alertNotificationCommentsClose,
  alertNotificationCommentsOpen,
  alertNotificationListClose,
  alertNotificationListOpen,
  alertNotificationResetGlow,
  alertNotificationsListingFilterUpdate,
  alertNotificationsListingSortUpdate,
  alertNotificationsReceive,
  alertNotificationsReceiveError,
  alertNotificationsRequest,
  alertNotificationReceiveNotification,
  bulkAckNotificationReceive,
  bulkAckNotificationReceiveError,
  bulkAckNotificationRequest,
  commentNotificationReceive,
  commentNotificationReceiveError,
  commentNotificationRequest,
  notificationEditComment,
  toggleAllNotificationSelected,
  toggleNotificationSelected,
  alertNotificationReset,
} from '../../redux/alert-notifications/actions';
import { alertNotificationsListingFilterSerialize } from '../../redux/alert-notifications/serdes';
import { fetchAlerts } from '../alerts';
// import { fetchAlerts } from '../alerts';

import {
  alertNotificationDeserialize,
  AlertNotificationListFilter,
  AlertNotificationListSort,
  AlertNotificationsListQueryResponse,
  Notification,
} from './entities';

export const openAlertNotificationsList =
  (alert: Alert) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationListOpen(alert));
  };

export const closeAlertNotificationsList =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationListClose());
  };

export const updateAlertNotificationListingFilter =
  (
    filter: Maybe<AlertNotificationListFilter>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('reports:data:actions:updateAlertNotificationListingFilter'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(alertNotificationsListingFilterUpdate(filter, page, sizePerPage));
  };
export const updateAlertNotificationListingPages =
  (
    page: number,
    sizePerPage: number,
    // logger: Logger = logdown('reports:data:actions:updateAlertNotificationListingPages'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const filter = getState().alertNotificationsListing.filter;
    dispatch(alertNotificationsListingFilterUpdate(filter, page, sizePerPage));
  };
export const updateAlertNotificationListingSort =
  (listingSort: Maybe<AlertNotificationListSort>, logger: Logger = logdown('reports:data:actions:updateAlertNotificationListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(listingSort.orUndefined())}`);
    dispatch(alertNotificationsListingSortUpdate(listingSort));
  };

export const fetchAlertNotifications =
  (
    alert: Alert,
    filter: Maybe<AlertNotificationListFilter>,
    sort: Maybe<AlertNotificationListSort>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('reports:data:actions:fetchRenderedReportsHistory'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationsRequest(filter, sizePerPage, sort));

    const serializedFilter = alertNotificationsListingFilterSerialize(
      filter.getOrElse({
        alertId: alert.id,
        createdAt: Maybe.none(),
      }),
    );

    logger.log(`serializedFilter => ${serializedFilter}`);
    const lastMonthDate = new Date();
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alert/${alert.id}/notifications`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : moment().subtract(1, 'hour').toISOString(),
        to: serializedFilter.to ? serializedFilter.to : moment().toISOString(),
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
        isInMaintenance: serializedFilter.isInMaintenance,
        provider: serializedFilter.provider,
        source: serializedFilter.source,
        state: serializedFilter.state,
        severityLevel: serializedFilter.severityLevel,
      },
    })
      .then((response) => response.data as AlertNotificationsListQueryResponse)
      .then((data) => {
        dispatch(alertNotificationsReceive(data.notifications.map(alertNotificationDeserialize), page, data.total));
      })
      .catch((err) => {
        dispatch(alertNotificationsReceiveError(err));
      });
  };

export const openAlertNotificationComments =
  (notification: Notification) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationCommentsOpen(notification));
  };

export const closeAlertNotificationComments =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationCommentsClose());
  };

export const editNotificationComment =
  (comment: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationEditComment(comment));
  };

export const commentNotification =
  (notification: Notification, comment: string) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    if (!comment) {
      return;
    }
    const user = getState().user;
    dispatch(commentNotificationRequest(notification));

    axios({
      method: 'POST',
      url: `${config.services.crawfish.api.url}/notifications/${notification.id}/comment`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        comment,
      },
    })
      .then((response) => response.data)
      .then((/*data*/) => {
        dispatch(commentNotificationReceive(user.profile.fullName));
      })
      .catch((err) => {
        dispatch(commentNotificationReceiveError(err));
      });
  };

export const ackAndPutInMaintByAst =
  (astId: number) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const user = getState().user;

    axios({
      method: 'POST',
      url: `${config.services.crawfish.api.url}/notifications/acknowledgebyast`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        astId,
      },
    })
      .then((response) => response.data)
      .then((/*data*/) => {
        dispatch(ackNotificationReceive(user.profile.fullName));

        const { alert, filter, page, sizePerPage, sort } = getState().alertNotificationsListing;
        if (alert.isJust()) {
          dispatch(fetchAlertNotifications(alert.some(), filter, sort, page, sizePerPage));
        } else {
          const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
          dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        }
      })
      .catch((err) => {
        dispatch(ackNotificationReceiveError(err));
      });
  };

export const ackNotification =
  (notification: Notification, putAlertInMaintenance: boolean) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const user = getState().user;
    dispatch(ackNotificationRequest(notification));

    axios({
      method: 'POST',
      url: `${config.services.crawfish.api.url}/notifications/${notification.id}/acknowledge`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        putAlertInMaintenance,
      },
    })
      .then((response) => response.data)
      .then((/*data*/) => {
        dispatch(ackNotificationReceive(user.profile.fullName));

        const { alert, filter, page, sizePerPage, sort } = getState().alertNotificationsListing;
        if (alert.isJust()) {
          dispatch(fetchAlertNotifications(alert.some(), filter, sort, page, sizePerPage));
        } else {
          const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
          dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
        }
      })
      .catch((err) => {
        dispatch(ackNotificationReceiveError(err));
      });
  };

export const toggleNotificationSelect =
  (notificationId: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(toggleNotificationSelected(notificationId));
  };

export const toggleAllNotificationSelect =
  (selected: boolean) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(toggleAllNotificationSelected(selected));
  };

export const resetAlertNotification =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertNotificationReset());
  };

export const bulkAckNotifications =
  (notificationIds: string[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(bulkAckNotificationRequest());

    axios({
      method: 'POST',
      url: `${config.services.crawfish.api.url}/notifications/acknowledge`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        notificationIds,
      },
    })
      .then((response) => response.data)
      .then((/*data*/) => {
        dispatch(bulkAckNotificationReceive(notificationIds));
      })
      .catch((err) => {
        dispatch(bulkAckNotificationReceiveError(err));
      });
  };

let resetGlowTimeout: any;
export const alertNotificationProcessNotification =
  (notification: AlertNotification) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    if (getState().alertsListing.isInProgress) {
      return;
    }
    dispatch(alertNotificationReceiveNotification(notification));
    if (resetGlowTimeout) {
      clearTimeout(resetGlowTimeout);
    }
    resetGlowTimeout = setTimeout(() => {
      if (getState().alertsListing.isInProgress) {
        return;
      }
      dispatch(alertNotificationResetGlow(notification));
    }, 1000);
  };
