export const guid = (): string => {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const doesTargetExist = (target: string): boolean => {
  let selection = document.querySelectorAll(target);
  if (!selection.length) {
    selection = document.querySelectorAll('#' + target);
  }
  return selection && selection.length ? true : false;
};
