import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { Alert } from '../../entities';
import { AlertsListingState } from '../entities';

/**
 * Entities SerDes
 */

const alertSerialize = (ds: Alert) => {
  return {
    updatedAt: ds.updatedAt.toISOString(),
    id: ds.id,
    lastNotification: ds.lastNotification,
    notificationsCount: ds.notificationsCount,
    commentsCount: ds.commentsCount,
    alertWorkLogsCount: ds.alertWorkLogsCount,
    alertWorkLogsCountByType: ds.alertWorkLogsCountByType,
    severityLevel: ds.severityLevel,
    state: ds.state,
    source: ds.source,
    isInMaintenance: ds.isInMaintenance,
    isPinned: ds.isPinned,
    provider: ds.provider,
    assets: ds.assets,
  };
};

const alertDeserialize = (dss: any): Alert => ({
  updatedAt: moment(dss.updatedAt).toDate(),
  id: dss.id,
  lastNotification: dss.lastNotification,
  notificationsCount: dss.notificationsCount,
  commentsCount: dss.commentsCount,
  alertWorkLogs: dss.alertWorklogs,
  alertWorkLogsCount: dss.alertWorkLogsCount,
  alertWorkLogsCountByType: dss.alertWorkLogsCountByType,
  severityLevel: dss.severityLevel,
  state: dss.state,
  source: dss.source,
  isInMaintenance: dss.isInMaintenance,
  isPinned: dss.isPinned,
  provider: dss.provider,
  assets: dss.assets,
});

/**
 * Transformations
 */

export const createAlertsListingTransform = (logger: Logger = logdown('redux-persist:transform:alertsListing')): Transform<AlertsListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key /*, state*/) => {
      const result = {
        ...subState,
        sort: subState.sort.orUndefined(),
        alerts: subState.alerts.map((alerts) => alerts.map(alertSerialize)).orUndefined(),
        exportedAlerts: subState.exportedAlerts.map((alerts) => alerts.map(alertSerialize)).orUndefined(),
        filter: subState.filter
          .map((filter) => ({
            ...filter,
            ...(filter.updatedAt ? { updatedAt: filter.updatedAt.orUndefined() } : undefined),
          }))
          .orUndefined(),
        notification: subState.notification.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialize alerts=> (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key /*, rawState*/) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize alerts=> (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        sort: Maybe.fromUndefined(state.sort),
        alerts: Maybe.fromUndefined(state.alerts).map((alerts) => alerts.map(alertDeserialize)),
        exportedAlerts: Maybe.fromUndefined(state.exportedAlerts).map((alerts) => alerts.map(alertDeserialize)),
        // filter: Maybe.fromUndefined(state.filter),
        filter: state.filter
          ? Maybe.fromUndefined({
              ...(state.filter as any),
              updatedAt: Maybe.fromUndefined((state.filter as any).updatedAt),
            })
          : Maybe.None(),
        notification: Maybe.fromUndefined(state.notification),
        error: Maybe.fromUndefined(state.error).map((state) => new Error(state)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['alertsListing'] },
  );
