// @ts-ignore: No declarations available
// import logdown from 'logdown';
// @ts-ignore: No declarations available
import { Maybe } from 'monet';
import * as React from 'react';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { Badge, Button, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import AssetMaintenanceDialog from './AssetMaintenanceDialog';
import AssetMappingDialog from './AssetMappingDialog';
import AssetsList from './AssetsList';
import { fetchAssets, updateAssetListingFilter, updateAssetListingSort, resetAssets } from './data';

// const logger = logdown('component:Assets');

class Component extends React.Component<Props, unknown> {
  refresh() {
    this.props.fetchAssets(
      this.props.assetsListing.filter,
      this.props.assetsListing.page,
      this.props.assetsListing.sizePerPage,
      this.props.assetsListing.sort,
    );
  }
  reset() {
    this.props.updateAssetListingFilter(Maybe.none(), 1, this.props.assetsListing.sizePerPage);
    this.props.updateAssetListingSort(Maybe.none());
  }

  public render() {
    return (
      <div className="content">
        <Button
          data-testid="assets-list-reload-button"
          id="assets-list-reload-button"
          color="secondary"
          size="sm"
          disabled={this.props.assetsListing.isInProgress}
          onClick={this.refresh.bind(this)}
        >
          <Spinner size="sm" hidden={!this.props.assetsListing.isInProgress} /> Refresh
        </Button>
        <AssetsList showTopManageHiddenCols {...this.props} />
        <AssetMaintenanceDialog {...this.props} />
        <AssetMappingDialog {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAssets,
      updateAssetListingFilter,
      updateAssetListingSort,
      resetAssetsState: resetAssets,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  assetsListing: state.assetsListing,
  assetMaintenanceDialog: state.assetMaintenanceDialog,
  assetMappingDialog: state.assetMappingDialog,
  assetMappingDelete: state.assetMappingDelete,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
