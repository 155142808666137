import logdown from 'logdown';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Dispatch, bindActionCreators } from 'redux';

import { State } from '../../reducers';
import { closeMonitoringSystems, deleteMonitoringSystem, editMonitoringSystem, saveMonitoringSystem } from '../monitoring-systems';
import MonitoringSystemsList from '../monitoring-systems/MonitoringSystemsList';

const logger = logdown('component:MonitoringSystemsModal');

class Component extends React.Component<Props, unknown> {
  public render() {
    return (
      <Modal
        id="monitoring-systems-information-modal"
        data-testid="monitoring-systems-information-modal"
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.monitoringSystemsDialog.isOpen}
        toggle={this.close.bind(this)}
      >
        <ModalHeader data-testid="monitoring-systems-modal-header">
          <EllipsisText className="stakeholder-monitoringsystems-header" text={`Monitoring Systems`} length={75} />
        </ModalHeader>
        <ModalBody>
          <MonitoringSystemsList showTopManageHiddenCols={false} {...this.props} />
          {/* <AssetsList showTopManageHiddenCols={false} {...this.props} /> */}
        </ModalBody>
        <ModalFooter>
          <Button
            className="monitoring-systems-cancel-button"
            data-testid="monitoring-systems-modal-cancel-button"
            color="secondary"
            onClick={this.close.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected close(e: any) {
    e.preventDefault();
    // if (this.props.stakeHolderNotificationSending.workLogs.isSome()) {
    //   return this.props.closeAlertStakeholderNotification();
    // }
    return this.props.closeMonitoringSystems();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      closeMonitoringSystems,
      deleteMonitoringSystem,
      saveMonitoringSystem,
      editMonitoringSystem,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  monitoringSystemsDialog: state.monitoringSystemsDialog,
  monitoringSystemsListing: state.monitoringSystemsListing,
  monitoringSystemEditing: state.monitoringSystemEditing,
  monitoringSystemDeleting: state.monitoringSystemDeleting,
  monitoringSystemSaving: state.monitoringSystemSaving,
  monitoringSystemIncidentInformationDialog: state.monitoringSystemIncidentInformationDialog,
  sitesGroupsListing: state.sitesGroupsListing,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
