import { RawNotification } from '@serverfarm/nocd-commons';
import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe, None } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { showErrorNotification } from '../../../notification';
import { ListSort, RawNotificationsListFilter } from '../../entities';
import {
  allRawNotificationsResetGlow,
  exportedRawNotificationsReceive,
  exportedRawNotificationsReset,
  messageInformationClose,
  messageInformationOpen,
  rawNotificationListingFilterUpdate,
  rawNotificationListingHiddenColumnsUpdate,
  rawNotificationsReceive,
  rawNotificationsReceiveError,
  rawNotificationsRequest,
  rawNotificationsReset,
} from '../../redux/raw-notifications/actions';

import { RawNotificationsListQueryResponse, rawNotificationDeserialize } from './entities';
import { rawNotificationListingFilterSerialize } from './serdes';

export const updateRawNotificationListingFilter =
  (
    filter: Maybe<RawNotificationsListFilter>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('alerts:data:actions:updateRawNotificationListingFilter'),
  ) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(rawNotificationListingFilterUpdate(filter, page, sizePerPage));
  };

export const updateRawNotificationListingSort =
  (sort: Maybe<ListSort>, logger: Logger = logdown('alerts:data:actions:updateRawNotificationListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
    dispatch(sort);
  };

export const updateRawNotificationListingHiddenColumns =
  (hiddenColumns: string[], logger: Logger = logdown('alerts:data:actions:updateRawNotificationListingHiddenColumns')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(rawNotificationListingHiddenColumnsUpdate(hiddenColumns));
  };

export const resetRawNotifications =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(rawNotificationsReset());
  };

export const resetExportedRawNotifications =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(exportedRawNotificationsReset());
  };

export const openMessageInformation =
  (rawNotification: RawNotification) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(messageInformationOpen(rawNotification));
  };

export const closeMessageInformation =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(messageInformationClose());
  };

export const fetchRawNotifications =
  (
    filter: Maybe<RawNotificationsListFilter>,
    page: number,
    sizePerPage: number,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(rawNotificationsRequest(filter, sizePerPage, sort));
    const serializedFilter = rawNotificationListingFilterSerialize(filter.getOrElse({ updatedAt: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/rawnotifications`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : moment().subtract(1, 'hour').toISOString(),
        to: serializedFilter.to ? serializedFilter.to : moment().toISOString(),
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field.replace('updatedSiteLocal', 'updatedAt').replace('updatedUtc', 'updatedAt')}:${s.order}`).orUndefined(),
        message: serializedFilter.message,
        status: serializedFilter.status,
        providers: serializedFilter.providers,
      },
    })
      .then((result) => result.data as RawNotificationsListQueryResponse)
      .then((rawNotificationsData) => {
        dispatch(
          rawNotificationsReceive(
            rawNotificationsData.rawNotifications.map((rawNotification) => rawNotificationDeserialize(rawNotification)),
            page,
            rawNotificationsData.total,
          ),
        );
        setTimeout(() => {
          if (getState().alertsListing.isInProgress) {
            return;
          }
          dispatch(allRawNotificationsResetGlow());
        }, 1000);
      })
      .catch((error) => {
        console.log('error here', error);
        dispatch(rawNotificationsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const fetchExportedRawNotifications =
  (
    filter: Maybe<RawNotificationsListFilter>,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    const serializedFilter = rawNotificationListingFilterSerialize(filter.getOrElse({ updatedAt: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alerts`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : moment().subtract(1, 'hour').toISOString(),
        to: serializedFilter.to ? serializedFilter.to : moment().toISOString(),
        page: 1,
        limit: 500,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
        message: serializedFilter.message,
        status: serializedFilter.status,
        providers: serializedFilter.providers,
      },
    })
      .then((result) => result.data as RawNotificationsListQueryResponse)
      .then((alertsData) => {
        dispatch(exportedRawNotificationsReceive(alertsData.rawNotifications.map((rawNotification) => rawNotificationDeserialize(rawNotification))));
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };
