import { Company, AlertNotification as MEPNotification, AlertState } from '@serverfarm/nocd-commons';
import moment from 'moment';
import { Maybe } from 'monet';

import { DateRange, DateRangeFn } from '../../../utils/dateRangeFilter';

export interface AlertNotificationListFilter {
  alertId: string;
  createdAt: Maybe<DateRange | DateRangeFn>;
  isInMaintenance?: string;
  source?: string;
  state?: string;
  provider?: string;
  severityLevel?: string;
}

export interface AlertNotificationListSort {
  field: string;
  order: 'asc' | 'desc';
}

export interface Notification extends MEPNotification {
  selected: boolean;
  isNew?: boolean;
}

export interface AlertEventsListFilterApiEntity {
  alertId: string;
  from?: string;
  to?: string;
  companyId?: string;
  companyIds?: string[];
  isInMaintenance?: string;
  provider?: string;
  state?: string;
  source?: string;
  severityLevel?: number;
}

export interface NotificationCommentApiEntity {
  id: string;
  companyId: number;
  comment: string;
  userName: string;
  createdAt: string;
}

export interface NotificationAckApiEntity {
  userId: number;
  userName: string;
  companyId: number;
  createdAt: Date;
}

export interface NotificationApiEntity {
  id: string;
  severityLevel: number;
  state: string;
  source: string;
  provider: string;
  createdAt: string;
  timestamp: string;
  isInMaintenance: boolean;
  isPinned: boolean;
  companies: Company[];
  comments: NotificationCommentApiEntity[];
  acknowledged: NotificationAckApiEntity;
}

export interface AlertNotificationsListQueryResponse {
  notifications: NotificationApiEntity[];
  page: number;
  perPage: number;
  total: number;
}

export const alertNotificationDeserialize = (not: NotificationApiEntity): Notification => {
  return {
    createdAt: moment(not.createdAt).toDate(),
    id: not.id,
    severityLevel: not.severityLevel,
    source: not.source,
    state: not.state as AlertState,
    provider: not.provider,
    isInMaintenance: not.isInMaintenance,
    comments: not.comments
      ? not.comments.map((c) => ({
          comment: c.comment,
          companyId: c.companyId,
          createdAt: moment(c.createdAt).toDate(),
          id: c.id,
          userName: c.userName,
        }))
      : [],
    acknowledged: not.acknowledged,
    selected: false,
    alertId: '',
  };
};
