import { Maybe, None, Some } from 'monet';
import { Action } from 'redux';

import { ErrorAction } from '../../../redux/actions';
import { ListSort, RawNotification, RawNotificationsListFilter } from '../../entities';

export enum ActionType {
  UPDATE_RAW_NOTIFICATIONS_LISTING_FILTER = 'UPDATE_RAW_NOTIFICATIONS_LISTING_FILTER',
  RESET_EXPORTED_RAW_NOTIFICATIONS = 'RESET_EXPORTED_RAW_NOTIFICATIONS',
  RESET_RAW_NOTIFICATIONS = 'RESET_RAW_NOTIFICATIONS',
  RECEIVE_EXPORTED_RAW_NOTIFICATIONS = 'RECEIVE_EXPORTED_RAW_NOTIFICATIONS',
  REQUEST_RAW_NOTIFICATIONS = 'REQUEST_RAW_NOTIFICATIONS',
  RECEIVE_RAW_NOTIFICATIONS = 'RECEIVE_RAW_NOTIFICATIONS',
  RESET_ALL_RAW_NOTIFICATION_GLOW = 'RESET_ALL_RAW_NOTIFICATION_GLOW',
  RECEIVE_RAW_NOTIFICATIONS_ERROR = 'RECEIVE_RAW_NOTIFICATIONS_ERROR',
  UPDATE_RAW_NOTIFICATIONS_LISTING_HIDDEN_COLUMNS = 'UPDATE_RAW_NOTIFICATIONS_LISTING_HIDDEN_COLUMNS',
  UPDATE_RAW_NOTIFICATIONS_SORT_LIST = 'UPDATE_RAW_NOTIFICATIONS_SORT_LIST',
  RECEIVE_NEW_RAW_NOTIFICATION = 'RECEIVE_NEW_RAW_NOTIFICATION',
  OPEN_MESSAGE_INFORMATION = 'OPEN_MESSAGE_INFORMATION',
  CLOSE_MESSAGE_INFORMATION = 'CLOSE_MESSAGE_INFORMATION',
}
type DataSourceResetAction = Action<ActionType>;

type RawNotificationListingResetGlowAction = Action<ActionType>;

type RawNotificationsReceiveErrorAction = ErrorAction<ActionType>;

interface RawNotificationsReceiveAction extends Action<ActionType> {
  rawNotifications: RawNotification[];
  page: number;
  total: number;
}

interface RawNotificationsRequestAction extends Action<ActionType> {
  filter: Maybe<RawNotificationsListFilter>;
  sizePerPage: number;
  sort: Maybe<ListSort>;
}

interface RawNotificationListingHiddenColumnsUpdateAction extends Action<ActionType> {
  hiddenColumns: string[];
}

interface RawNotificationListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<ListSort>;
}

export const rawNotificationListingFilterUpdate = (
  filter: Maybe<RawNotificationsListFilter>,
  page: number,
  sizePerPage: number,
): RawNotificationListingFilterUpdateAction => ({
  type: ActionType.UPDATE_RAW_NOTIFICATIONS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

interface ExportedRawNotificationsReceiveAction extends Action<ActionType> {
  exportedRawNotifications: RawNotification[];
}

interface RawNotificationListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<RawNotificationsListFilter>;
  page: number;
  sizePerPage: number;
}

interface MessageInformationToggleAction extends Action<ActionType> {
  rawNotification: Maybe<RawNotification>;
}

export type RawNotificationsFetchAction = RawNotificationsReceiveAction &
  ExportedRawNotificationsReceiveAction &
  RawNotificationListingFilterUpdateAction &
  RawNotificationsRequestAction &
  RawNotificationListingResetGlowAction &
  RawNotificationListingHiddenColumnsUpdateAction &
  RawNotificationListingSortUpdateAction &
  RawNotificationsReceiveErrorAction &
  MessageInformationToggleAction;

export const rawNotificationListingSortUpdate = (sort: Maybe<ListSort>): RawNotificationListingSortUpdateAction => ({
  type: ActionType.UPDATE_RAW_NOTIFICATIONS_SORT_LIST,
  sort,
});

export const rawNotificationListingHiddenColumnsUpdate = (hiddenColumns: string[]): RawNotificationListingHiddenColumnsUpdateAction => ({
  type: ActionType.UPDATE_RAW_NOTIFICATIONS_LISTING_HIDDEN_COLUMNS,
  hiddenColumns,
});

export const rawNotificationsReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_RAW_NOTIFICATIONS,
});

export const exportedRawNotificationsReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_EXPORTED_RAW_NOTIFICATIONS,
});

export const rawNotificationsReceive = (rawNotifications: RawNotification[], page: number, total: number): RawNotificationsReceiveAction => ({
  type: ActionType.RECEIVE_RAW_NOTIFICATIONS,
  rawNotifications,
  page,
  total,
});
export const exportedRawNotificationsReceive = (exportedRawNotifications: RawNotification[]): ExportedRawNotificationsReceiveAction => ({
  type: ActionType.RECEIVE_EXPORTED_RAW_NOTIFICATIONS,
  exportedRawNotifications,
});

export const rawNotificationsRequest = (
  filter: Maybe<RawNotificationsListFilter>,
  sizePerPage: number,
  sort: Maybe<ListSort>,
): RawNotificationsRequestAction => ({
  type: ActionType.REQUEST_RAW_NOTIFICATIONS,
  filter,
  sizePerPage,
  sort,
});

export const allRawNotificationsResetGlow = (): RawNotificationListingResetGlowAction => ({
  type: ActionType.RESET_ALL_RAW_NOTIFICATION_GLOW,
});

export const rawNotificationsReceiveError = (error: Error): RawNotificationsReceiveErrorAction => ({
  type: ActionType.RECEIVE_RAW_NOTIFICATIONS_ERROR,
  error,
});

export const messageInformationOpen = (rawNotification: RawNotification): MessageInformationToggleAction => ({
  type: ActionType.OPEN_MESSAGE_INFORMATION,
  rawNotification: Some(rawNotification),
});

export const messageInformationClose = (): MessageInformationToggleAction => ({
  type: ActionType.CLOSE_MESSAGE_INFORMATION,
  rawNotification: None(),
});
