// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import { Maybe, Some } from 'monet';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Form, FormGroup, Input, FormText } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { requestAlertsMapping } from '../alerts';
import { showInfoNotification } from '../notification';
import { UserState } from '../user/redux/entities';

import {
  addAssetMapping,
  closeAssetMappingDialog,
  fetchAssets,
  updateAssetListingFilter,
  updateAssetMappingDialogAsset,
  updateAssetMappingDialogMappingString,
  updateAssetMappingDialogProgressState,
} from './data/actions';
import { AssetsListingState } from './redux/entities';

interface AssetListsProps {
  user: UserState;
  assetsListing: AssetsListingState;
}

class AsseMappingComponent extends React.Component<Props, unknown> {
  protected handleChange(mappingString: string) {
    this.props.updateAssetMappingDialogMappingString(Some(mappingString));
  }
  refresh() {
    this.props.fetchAssets(
      this.props.assetsListing.filter,
      this.props.assetsListing.page,
      this.props.assetsListing.sizePerPage,
      this.props.assetsListing.sort,
    );
  }

  componentDidMount() {
    console.log('Called');
    if (this.props.assetMappingDialog.alert.isSome()) {
      const filter = this.props.assetsListing.filter
        .map((filter: any) => {
          const site = this.props.assetMappingDialog.alert.isSome() ? this.props.assetMappingDialog.alert.some().provider.split('/')[1] : undefined;
          return {
            ...filter,
            site: [site],
          };
        })
        .orElse(Maybe.Some({ site: [] }));
      this.props.updateAssetListingFilter(filter, 0, 1000);
    }
  }

  componentDidUpdate(prevProps: Props & AssetListsProps) {
    const isFilterUpdated = !prevProps.assetsListing.filter.equals(this.props.assetsListing.filter);
    const isSortingUpdated = !prevProps.assetsListing.sort.equals(this.props.assetsListing.sort);
    const isPageSizeUpdated = !(prevProps.assetsListing.sizePerPage === this.props.assetsListing.sizePerPage);
    const isPageChanged = !(prevProps.assetsListing.page === this.props.assetsListing.page);
    // const isMappingUpdated = prevProps.assetMappingDialog.isInProgress && !this.props.assetMappingDialog.isInProgress;
    if (
      (this.props.assetMappingDialog.alert.isSome() || this.props.assetMappingDialog.asset.isSome()) &&
      (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged)
    ) {
      this.refresh();
    }
  }
  public render() {
    return (
      <Form>
        <FormGroup>
          <Typeahead
            id="asset-mapping-asset-editor"
            isLoading={this.props.assetsListing.isInProgress}
            // onSearch={(query) => {
            //   const filter = this.props.assetsListing.filter
            //     .map((filter: any) => {
            //       const site = this.props.assetMappingDialog.alert.isSome()
            //         ? this.props.assetMappingDialog.alert.some().provider.split('/')[1]
            //         : undefined;
            //       return {
            //         ...filter,
            //         name: query,
            //         site: [site],
            //       };
            //     })
            //     .orElse(Maybe.Some({ name: query }));
            //   console.log('filter =>', filter);
            //   this.props.updateAssetListingFilter(filter, this.props.assetsListing.page, this.props.assetsListing.sizePerPage);
            // }}
            onChange={(selected) => this.props.updateAssetMappingDialogAsset(Maybe.fromUndefined(selected[0]))}
            options={this.props.assetsListing.assets.getOrElse([])}
            emptyLabel="No Assets found"
            // searchText="Looking for Assets..."
            placeholder={
              this.props.assetMappingDialog.alert.isSome()
                ? this.props.sitesListing.sites
                    .flatMap((sites) =>
                      Maybe.fromUndefined(sites.find((s) => s.monitorSiteId === this.props.assetMappingDialog.alert.some().provider.split('/')[1])),
                    )
                    .map((s) => `Start typing to find '${s.name}' Asset by name`)
                    .getOrElse('Start typing to find an Asset by name')
                : 'Start typing to find an Asset by name'
            }
            selected={this.props.assetMappingDialog.asset.map((a) => [a]).getOrElse([])}
            labelKey="name"
            // isValid={this.props.assetMappingDialog.asset.isSome()}
            // isInvalid={this.props.assetMappingDialog.asset.isNone()}
            disabled={this.props.assetMappingDialog.isAssetLocked}
            // labelKey={option => `${option.login}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="mapping"
            type="text"
            onChange={(e) => this.handleChange.bind(this)(e.target.value)}
            placeholder={'Provide mapping string here'}
            // valid={!this.props.assetMappingDialog.mappingString.contains('')}
            // invalid={this.props.assetMappingDialog.mappingString.contains('')}
          />
          {this.props.assetMappingDialog.alert.isSome() ? (
            <FormText>
              The string will be used to map selected Asset to <strong>{this.props.assetMappingDialog.alert.some().source}</strong> Alert
            </FormText>
          ) : (
            ''
          )}
        </FormGroup>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      showInfoNotification,
      fetchAssets,
      updateAssetListingFilter,
      addAssetMapping,
      updateAssetMappingDialogAsset,
      updateAssetMappingDialogMappingString,
      updateAssetMappingDialogProgressState,
      closeAssetMappingDialog,
      requestAlertsMapping,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  assetsListing: state.assetsListing,
  sitesListing: state.sitesListing,
  assetMappingDialog: state.assetMappingDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(AsseMappingComponent);
