import { SeverityLevel } from '@serverfarm/nocd-commons';
import moment from 'moment';
import { Maybe } from 'monet';

import { parseDateRangeValue } from '../../../utils/dateRangeFilter';
import { AlertEventsListFilterApiEntity, AlertNotificationListFilter } from '../../data/alert-notifications';

export const alertNotificationsListingFilterSerialize = (filter: AlertNotificationListFilter): AlertEventsListFilterApiEntity => {
  const dsFilter: AlertEventsListFilterApiEntity = {
    alertId: filter.alertId,
    isInMaintenance: filter.isInMaintenance !== undefined ? (filter.isInMaintenance === 'Yes' ? 'true' : 'false') : undefined,
    source: filter.source,
    provider: filter.provider,
    severityLevel: filter.severityLevel ? (SeverityLevel[filter.severityLevel as any] as unknown as number) : undefined,
    state: filter.state,
  };
  if (filter.createdAt) {
    dsFilter.from = filter.createdAt.flatMap((createdAt) => Maybe.fromUndefined(parseDateRangeValue(createdAt)?.from?.toISOString())).orUndefined();
    dsFilter.to = filter.createdAt.flatMap((createdAt) => Maybe.fromUndefined(parseDateRangeValue(createdAt)?.to?.toISOString())).orUndefined();
  }
  return dsFilter;
};
export const alertNotificationsListingFilterDeserialize = (filter: AlertEventsListFilterApiEntity): AlertNotificationListFilter => {
  const dsFilter: AlertNotificationListFilter = {
    alertId: filter.alertId,
    createdAt: filter.from
      ? Maybe.Some({
          from: moment(filter.from).toDate(),
          to: moment(filter.to).toDate(),
        })
      : Maybe.none(),
    isInMaintenance: filter.isInMaintenance,
    state: filter.state,
    source: filter.source,
    provider: filter.provider,
  };
  return dsFilter;
};
