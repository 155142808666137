import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { Event } from '../entities';

import { EventsListingState } from './entities';

/**
 * Entities SerDes
 */

const eventSerialize = (ds: Event) => ({
  id: ds.id,
  source: ds.source,
  message: ds.message,
  created: ds.created.toISOString(),
});

const eventDeserialize = (dss: any): Event => ({
  id: dss.id,
  source: dss.source,
  message: dss.message,
  created: moment(dss.created).toDate(),
});

/**
 * Transformations
 */

export const createEventsListingTransform = (logger: Logger = logdown('redux-persist:transform:eventsListing')): Transform<EventsListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key /*, state*/) => {
      const result = {
        ...subState,
        events: subState.events.map((events) => events.map(eventSerialize)).orUndefined(),
        sort: subState.sort.orUndefined(),
        filter: subState.filter.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize events=> (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key /*, rawState*/) => {
      // convert mySet back to a Set.
      logger.log(`deserialize events=> (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        events: Maybe.fromUndefined(state.events).map((events) => events.map(eventDeserialize)),
        sort: Maybe.fromUndefined(state.sort),
        filter: Maybe.fromUndefined(state.filter),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['eventsListing'] },
  );
