import { Maybe, None } from 'monet';
import { Reducer } from 'redux';

import { ActionType, AssetMappingDeleteAction, AssetsFetchAction } from './actions';
import { ASSET_INITIAL_STATE } from './constants';
import { AssetsListingState } from './entities';

import { AssetMappingDeleteState } from '.';

export const assetsListingReducer: Reducer<AssetsListingState, AssetsFetchAction> = (state = ASSET_INITIAL_STATE.assetsListingState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_ASSETS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ASSETS:
      return {
        ...state,
        isInProgress: false,
        assets: Maybe.some(
          action.assets.map((asset, idx) => {
            return { ...asset };
          }),
        ),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.RECEIVE_EXPORTED_ASSETS:
      return {
        ...state,
        exportedAssets: Maybe.some(
          action.exportedAssets.map((asset, idx) => {
            return { ...asset };
          }),
        ),
      };
    case ActionType.RECEIVE_ASSETS_ERROR:
      return {
        ...state,
        isInProgress: false,
        assets: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.UPDATE_ASSETS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_ASSETS_LISTING_HIDDEN_COLUMNS:
      console.log('Assets', action);
      return {
        ...state,
        hiddenColumns: action.hiddenColumns,
      };
    case ActionType.UPDATE_ASSETS_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_ASSETS:
      return ASSET_INITIAL_STATE.assetsListingState;
    case ActionType.RESET_EXPORTED_ALERTS:
      return {
        ...state,
        exportedAssets: None(),
      };
    default:
      return state;
  }
};

export const assetMappingDeleteReducer: Reducer<AssetMappingDeleteState, AssetMappingDeleteAction> = (
  state = ASSET_INITIAL_STATE.assetMappingDeleteState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_ASSET_MAPPING_DELETE:
      return {
        ...state,
        assetId: action.assetId,
        mappingId: action.mappingId,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ASSET_MAPPING_DELETE_SUCCESS:
      return {
        ...state,
        assetId: Maybe.None(),
        mappingId: Maybe.None(),
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ASSET_MAPPING_DELETE_ERROR:
      return {
        ...state,
        assetId: Maybe.None(),
        mappingId: Maybe.None(),
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    default:
      return state;
  }
};
