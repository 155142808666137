import { Company } from '@serverfarm/nocd-commons';
import logdown from 'logdown';
import { Maybe, None, Some } from 'monet';
import RSA from 'react-simple-auth';
import { Dispatch } from 'redux';

import { UserRole, userRoleFromString } from '../../../auth/entities';
import { incommand as incommandProvider } from '../../../auth/providers';
import config from '../../../config';
import axios from '../../../services/axios';
import { resetEvents } from '../../events';
import { toggleNavBar } from '../../navigation';
import { showErrorNotification } from '../../notification';
import {
  receiveColleagues,
  receiveColleaguesError,
  receiveCompanies,
  receiveCompaniesError,
  receiveProfile,
  receiveProfileError,
  requestColleagues,
  requestCompanies,
  requestProfile,
  userLoggedIn,
  userLoggedOut,
  userLoginAttempt,
  userLoginAttemptComplete,
  userReset,
} from '../redux/actions';
import { UserProfile, ServicesAvailable } from '../redux/entities';

import { CompanyApiEntity, ProfileApiEntity, ServiceVersionApiEntity } from './entities';

const logger = logdown('user:actions');

export const removeUserLoginAttempt =
  () =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(userLoginAttemptComplete());
  };
/**
 * Login/logout actions
 */
export const loginUser =
  () =>
  async (dispatch: Dispatch<any> /*, getState: any*/): Promise<void> => {
    dispatch(userLoginAttempt());
    await RSA.acquireTokenAsync(incommandProvider)
      .then(() => fetchServiceVersions()(dispatch))
      .then(() => fetchProfile()(dispatch))
      .then(() => fetchCompanies()(dispatch))
      .then(() => {
        dispatch(userLoggedIn());
      })
      .catch((error: Error) => {
        showErrorNotification(error)(dispatch);
      })
      .finally(() => {
        dispatch(userLoginAttemptComplete());
      });
  };

export const logoutUser =
  () =>
  (dispatch: Dispatch<any>): void => {
    RSA.invalidateSession();
    resetData(dispatch);
    dispatch(userLoggedOut());
    toggleNavBar(false)(dispatch);
  };

/**
 * User profile fetch functionality
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchProfile = () => (dispatch: Dispatch<any>) => {
  dispatch(requestProfile()); // todo : redundant ?
  return axios({
    method: 'get',
    url: `${config.services.auth.api.url}/profile`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(
      (response) =>
        // if (response.status !== 200) {
        //   throw new Error(response.statusText);
        // }
        response.data as { profile: ProfileApiEntity; role: UserRole },
    )
    .then(({ profile, role }) => {
      dispatch(receiveProfile(userProfileFromApiEntity(profile, role)));
    })
    .catch((error) => {
      dispatch(receiveProfileError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const fetchColleagues = (siteId?: number) => (dispatch: Dispatch<any>) => {
  const params = Some([] as string[]).map((p) =>
    p.concat(
      Maybe.fromUndefined(siteId)
        .map((siteId) => [`siteId=${siteId}`])
        .getOrElse([]),
    ),
  );

  dispatch(requestColleagues());

  return axios({
    method: 'get',
    url: `${config.services.crawfish.api.url}/in-command/users/colleagues${params.map((params) => '?' + params.join('&')).getOrElse('')}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data as { colleagues: any[] })
    .then(({ colleagues }) => {
      return axios({
        method: 'get',
        url: `${config.services.crawfish.api.url}/in-command/users/companies${params.map((params) => '?' + params.join('&')).getOrElse('')}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.data as { companies: CompanyApiEntity[] })
        .then(({ companies }) => {
          colleagues.forEach((c) => {
            c.companyName = Maybe.fromUndefined(companies.find((comp) => comp.id === c.companyId))
              .map((comp) => comp.name)
              .orUndefined();
          });
          dispatch(receiveColleagues(colleagues));
        })
        .catch((error) => {
          dispatch(receiveColleaguesError(error));
          showErrorNotification(error)(dispatch);
        });
    })
    .catch((error) => {
      dispatch(receiveColleaguesError(error));
      showErrorNotification(error)(dispatch);
    });
};

/**
 * User companies fetch functionality
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchCompanies = (siteId?: number) => (dispatch: Dispatch<any>) => {
  const params = Some([] as string[]).map((p) =>
    p.concat(
      Maybe.fromUndefined(siteId)
        .map((siteId) => [`siteId=${siteId}`])
        .getOrElse([]),
    ),
  );

  dispatch(requestCompanies());

  return axios({
    method: 'get',
    url: `${config.services.crawfish.api.url}/in-command/users/companies${params.map((params) => '?' + params.join('&')).getOrElse('')}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data as { companies: CompanyApiEntity[] })
    .then(({ companies }) => {
      dispatch(receiveCompanies(companies.map(companyApiEntityToCompany)));
    })
    .catch((error) => {
      dispatch(receiveCompaniesError(error));
      showErrorNotification(error)(dispatch);
    });
};

function resetData(dispatch: Dispatch<any>) {
  dispatch(resetEvents());
  dispatch(userReset());
}

/**
 * Conversions
 */
export const userProfileFromApiEntity = (profile: ProfileApiEntity, role: UserRole): UserProfile => ({
  userId: profile.userId,
  companyId: profile.company.id,
  userName: profile.userName,
  firstName: profile.firstName,
  lastName: profile.lastName,
  fullName: `${profile.firstName} ${profile.lastName}`,
  // timeZoneRef: profile.timeZoneRef,
  role: Maybe.fromUndefined(role).map(userRoleFromString),
});

export const companyApiEntityToCompany = (company: CompanyApiEntity): Company => ({
  id: company.id,
  name: company.name,
});

/**
 * Service version fetch functionality
 */

export const makeServiceVersionKeyName = (service: ServicesAvailable): string => `${service}:version`;

export const fetchServiceVersions =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    localStorage.setItem(makeServiceVersionKeyName(ServicesAvailable.CLIENT), config.client.version);
    await fetchServiceVersion(ServicesAvailable.CRAWFISH, config.services.crawfish.api.url)(dispatch);
    await fetchServiceVersion(ServicesAvailable.WHITE_ANT, config.services.auth.api.url)(dispatch);
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchServiceVersion = (service: ServicesAvailable, url: string) => async (dispatch: Dispatch<any>) => {
  logger.log(`Getting service version: ${url}`);
  return axios({
    method: 'get',
    url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data as ServiceVersionApiEntity)
    .then((res) => {
      logger.log(`VERSION [${service}]:${res.version}`);
      localStorage.setItem(makeServiceVersionKeyName(service), res.version);
    })
    .catch((error) => {
      showErrorNotification(error)(dispatch);
    });
};
