import { Maybe } from 'monet';

import { DIALOG_IN_PROGRESS_INITIAL_STATE } from '../../../redux/constants';

import { AssetMappingDialogState } from './entities';

const assetMappingDialogState: AssetMappingDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  alert: Maybe.None(),
  asset: Maybe.None(),
  isAssetLocked: false,
  mappingString: Maybe.None(),
};

export const ASSET_MAPPING_INITIAL_STATE = {
  assetMappingDialogState,
};
