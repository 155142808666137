/* eslint-disable id-blacklist */

import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { bindActionCreators } from 'redux';

import config from '../../config';
import { State } from '../../reducers';

class IFrameDelayedRender extends React.Component<any, { display: boolean }> {
  public state = { display: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ display: true });
    }, 1000);
  }

  public render() {
    return (
      this.state.display && (
        <iframe title="user guide" src={config.services.dd.widgets.dashboard.url} width="100%" height="100%" frameBorder={0}></iframe>
      )
    );
  }
}

class Component extends React.Component<Props, unknown> {
  public render() {
    return (
      <div className="content" style={{ height: 500 }}>
        <IFrameDelayedRender />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: State) => ({
  user: state.user,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, unknown>(mapStateToProps, mapDispatchToProps)(Component);
