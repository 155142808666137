// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import { Maybe } from 'monet';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Badge } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import AlertNotificationsList from './AlertNotificationsList';
import {
  fetchAlertNotifications,
  updateAlertNotificationListingFilter,
  updateAlertNotificationListingSort,
  closeAlertNotificationsList,
  closeAlertNotificationComments,
} from './data/alert-notifications';
import { setSelectedAlert } from './data/alerts';
import NotificationComments from './NotificationComments';

const logger = logdown('component:RenderedReportHistory');

class Component extends React.Component<Props, unknown> {
  reset() {
    this.props.updateAlertNotificationListingFilter(Maybe.none(), 1, this.props.alertsListing.sizePerPage);
    this.props.updateAlertNotificationListingSort(Maybe.none());
    this.props.fetchAlertNotifications(
      this.props.alertNotificationsListing.alert.some(),
      Maybe.none(),
      Maybe.none(),
      1,
      this.props.alertNotificationsListing.sizePerPage,
    );
  }
  public render() {
    logger.log(`Alert ${this.props.alertNotificationsListing.alert.isSome()}`);
    return (
      <Modal
        id="alert-notifications-modal"
        data-testid="alert-notifications-modal"
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.alertNotificationsListing.alert.isSome()}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="alert-notifications-modal-header">
          <EllipsisText
            className="alert-notifications-header"
            text={`${this.props.alertNotificationsListing.alert.map((a: any) => a.source).getOrElse('N/A')} Alert`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          {this.props.alertNotificationsListing.notificationComment.isSome() ? (
            <NotificationComments {...this.props} />
          ) : (
            <div>
              <Button
                id="alert-notifications-list-reset-button"
                color="secondary"
                size="sm"
                disabled={this.props.alertNotificationsListing.isInProgress}
                hidden={this.props.alertNotificationsListing.notificationsNotShown === 0}
                onClick={this.reset.bind(this)}
                title="Reset to see the latest Notifications"
              >
                Reset{' '}
                <Badge className="ml-1" color="danger">
                  {this.props.alertNotificationsListing.notificationsNotShown}
                </Badge>
              </Button>
              <AlertNotificationsList {...this.props} />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="alert-notifications-cancel-button"
            data-testid="alert-notifications-modal-cancel-button"
            color="secondary"
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected cancel(e: any) {
    e.preventDefault();
    if (this.props.alertNotificationsListing.notificationComment.isSome()) {
      return this.props.closeAlertNotificationComments();
    }
    this.props.setSelectedAlert('');
    this.props.closeAlertNotificationsList();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAlertNotifications,
      updateAlertNotificationListingFilter,
      updateAlertNotificationListingSort,
      closeAlertNotificationsList,
      closeAlertNotificationComments,
      setSelectedAlert,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  alertsListing: state.alertsListing,
  companiesListing: state.companiesListing,
  alertNotificationsListing: state.alertNotificationsListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
