/* eslint-disable @typescript-eslint/no-unused-vars */
import { Maybe, Some } from 'monet';
import { Reducer } from 'redux';

import { AssetMappingDialogActionType, AssetMappingDialogAction } from './actions';
import { ASSET_MAPPING_INITIAL_STATE } from './constants';
import { AssetMappingDialogState } from './entities';

export const assetMappingDialogReducer: Reducer<AssetMappingDialogState, AssetMappingDialogAction> = (
  state = ASSET_MAPPING_INITIAL_STATE.assetMappingDialogState,
  action,
) => {
  switch (action.type) {
    case AssetMappingDialogActionType.OPEN_ASSET_MAPPING_DIALOG:
      return {
        ...state,
        isOpen: true,
        alert: action.alert,
        asset: action.asset,
        isAssetLocked: action.asset.isSome(),
      };
    case AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_ASSET:
      return {
        ...state,
        asset: action.asset,
      };
    case AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_MAPPING_STRING:
      return {
        ...state,
        mappingString: action.mappingString,
      };
    case AssetMappingDialogActionType.UPDATE_ASSET_MAPPING_DIALOG_PROGRESS_STATE:
      return {
        ...state,
        progressState: action.progressState,
      };
    case AssetMappingDialogActionType.CLOSE_ASSET_MAPPING_DIALOG:
      return {
        ...ASSET_MAPPING_INITIAL_STATE.assetMappingDialogState,
      };
    default:
      return state;
  }
};
