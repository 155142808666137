import { Maybe, None } from 'monet';

import { EventsListFilter } from '../entities';

import { EventsListFilterApiEntity } from './entities';

export const eventListingFilterSerialize = (filter: EventsListFilter = { createdAt: None() }): EventsListFilterApiEntity => {
  const dsFilter: EventsListFilterApiEntity = {
    name: filter.source,
  };
  if (filter.createdAt) {
    dsFilter.from = filter.createdAt.flatMap((createdAt) => Maybe.fromUndefined(createdAt?.from?.toISOString())).orUndefined();
    dsFilter.to = filter.createdAt.flatMap((createdAt) => Maybe.fromUndefined(createdAt?.to?.toISOString())).orUndefined();
  }
  return dsFilter;
};
