import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { showErrorNotification } from '../../../notification';
import {
  notificationGroupsReceive,
  notificationGroupsReceiveError,
  notificationGroupsRequest,
  notificationGroupsReset,
} from '../../redux/notification-groups/actions';

import { NotificationGroupApiEntity, notificationGroupDeserialize, NotificationGroupsQueryResponse } from './entities';

export const fetchStakeholderNotificationGroups =
  () =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(notificationGroupsRequest());
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/in-command/users/groups`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {},
    })
      .then((result) => result.data as NotificationGroupsQueryResponse)
      .then((notificationGroupsData) => {
        dispatch(
          notificationGroupsReceive(
            notificationGroupsData.userGroups.map((notificationGroup: NotificationGroupApiEntity) => notificationGroupDeserialize(notificationGroup)),
          ),
        );
      })
      .catch((error) => {
        dispatch(notificationGroupsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const resetSite =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationGroupsReset());
  };
