import moment from 'moment';
import { Maybe, Some } from 'monet';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchSites } from '../sites';
import { DATE_TIME_FORMAT_DEFAULT } from '../utils';

import { addWorkLogEntry, fetchAlertWorkLog } from './data/alert-worklog';
import { sendStakeholderNotification, updateStakeholderNotification } from './data/alerts';
import { fetchStakeholderNotificationGroups } from './data/notification-groups';
import { NotificationGroup } from './entities';
import { alertWorkLogEdit } from './redux/alert-worklog/actions';

const messagesEndRef = React.createRef<HTMLDivElement>();
const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};

const AlertStakeholderNotification = (props: Props /*alert: Maybe<Alert>, workLog: WorkLog[], sites: Maybe<Site[]>*/) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props.sendStakeholderNotification();
      }}
    >
      <FormGroup>
        <Label>Notification Group</Label>
        <Typeahead<NotificationGroup>
          multiple={false}
          data-testid="stakeholder-notification-groups"
          id="stakeholder-notification-groups"
          onChange={(selected) => {
            // const filter = props.alertsListing.filter.map((filter: any) => ({
            //   ...filter,
            //   severityLevel: selected[0],
            // }));
            // props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
            props.updateStakeholderNotification(
              props.stakeHolderNotificationDialog.alert,
              Maybe.fromUndefined(selected[0]),
              props.stakeHolderNotificationDialog.subject,
              props.stakeHolderNotificationDialog.content,
            );
          }}
          options={props.notificationGroupsListing.notificationGroups.getOrElse([])}
          placeholder="Choose Notification Group"
          selected={
            props.stakeHolderNotificationDialog.notificationGroup.isSome()
              ? [props.stakeHolderNotificationDialog.notificationGroup.orUndefined() as NotificationGroup]
              : []
          }
          labelKey="name"

          // disabled={props.alertsListing.isInProgress}
        />
      </FormGroup>

      <FormGroup>
        <Label>Subject</Label>
        <Input
          placeholder="Provide Stakeholder Notification email subject"
          data-testid="subject-input"
          id="subject-input"
          value={props.stakeHolderNotificationDialog.subject.getOrElse('')}
          onChange={(e) =>
            props.updateStakeholderNotification(
              props.stakeHolderNotificationDialog.alert,
              props.stakeHolderNotificationDialog.notificationGroup,
              Some(e.target.value),
              props.stakeHolderNotificationDialog.content,
            )
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>Content</Label>
        <Input
          type="textarea"
          placeholder="Provide Stakeholder Notification email text"
          data-testid="content-input"
          id="content-input"
          value={props.stakeHolderNotificationDialog.content.getOrElse('')}
          onChange={(e) =>
            props.updateStakeholderNotification(
              props.stakeHolderNotificationDialog.alert,
              props.stakeHolderNotificationDialog.notificationGroup,
              props.stakeHolderNotificationDialog.subject,
              Some(e.target.value),
            )
          }
        />
      </FormGroup>
    </Form>
  );
};

// const CommentArea = (props: Props) => {
//   return (
//     <div>
//       <Form
//         onSubmit={(e) => {
//           e.preventDefault();
//           props.alertWorkLogAdding.workLog.forEach((workLog) => {
//             props.addWorkLogEntry(props.alertWorkLogAdding.alert.map((a) => a.id).getOrElse(''), workLog);
//           });
//           scrollToBottom();
//         }}
//       >
//         <FormGroup>
//           <Label for="comment">Add to Work Log</Label>
//           <Input
//             data-testid="work-log-editor-name-input"
//             id="work-log-editor-name-input"
//             type="textarea"
//             value={props.alertWorkLogAdding.workLog.getOrElse('')}
//             onChange={(e) => props.alertWorkLogEdit(props.alertWorkLogListing.alert, Some(e.target.value))}
//           />
//         </FormGroup>
//         <Button color="primary" id="comment-btn" disabled={props.alertWorkLogAdding.isInProgress}>
//           Add
//         </Button>
//       </Form>
//     </div>
//   );
// };

class WorkLogComponent extends React.Component<Props, unknown> {
  refresh() {
    // // const filter = this.props.alertsListing.filter.orElse(Some({ updated: None<DateRange>() }));
    // this.props.fetchSites();
    this.props.fetchStakeholderNotificationGroups();
    this.props.updateStakeholderNotification(
      this.props.stakeHolderNotificationDialog.alert,
      this.props.stakeHolderNotificationDialog.notificationGroup,
      this.props.stakeHolderNotificationDialog.alert.map((a) => a.provider),
      Some(
        `${this.props.stakeHolderNotificationDialog.alert
          .map((a) => moment(a.updatedAt).format(DATE_TIME_FORMAT_DEFAULT))
          .getOrElse('')}\n${this.props.stakeHolderNotificationDialog.alert.map((a) => a.source).getOrElse('')}`,
      ),
    );
    // this.props.fetchAlertWorkLog(
    //   this.props.alertWorkLogListing.alert.map((a) => a.id).getOrElse(''),
    //   this.props.alertWorkLogListing.filter,
    //   this.props.alertWorkLogListing.sort,
    //   this.props.alertWorkLogListing.page,
    //   this.props.alertWorkLogListing.sizePerPage,
    // );
  }

  // componentDidUpdate(prevProps: Props) {
  //   if (!prevProps.alertWorkLogListing.workLogs.equals(this.props.alertWorkLogListing.workLogs)) {
  //     scrollToBottom();
  //   }
  // }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    // console.log('test test test');
    // const isFilterUpdated = !prevProps.alertWorkLogListing.filter.equals(this.props.alertWorkLogListing.filter);
    // const isSortingUpdated = !prevProps.alertWorkLogListing.sort.equals(this.props.alertWorkLogListing.sort);
    // const isPageSizeUpdated = !(prevProps.alertWorkLogListing.sizePerPage === this.props.alertWorkLogListing.sizePerPage);
    // const isPageChanged = !(prevProps.alertWorkLogListing.page === this.props.alertWorkLogListing.page);
    // const workLogWasAdded = !this.props.alertWorkLogListing.isInProgress && prevProps.alertWorkLogAdding.isInProgress;
    // if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged || workLogWasAdded) {
    //   this.refresh();
    // }
  }

  public render() {
    // const pageCount = Math.floor(this.props.alertNotificationsListing.total / this.props.alertNotificationsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <AlertStakeholderNotification {...this.props} />
        {/* {this.props.alertWorkLogListing.isInProgress ? (
          <Spinner size="sm" />
        ) : (
          this.props.alertWorkLogListing.workLogs
            .map((workLogs) => {
              return AlertStakeholderNotification(this.props.alertWorkLogListing.alert, workLogs, this.props.sitesListing.sites);
            })
            .orUndefined()
        )}
        <CommentArea {...this.props} /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      alertWorkLogEdit,
      addWorkLogEntry,
      fetchSites,
      fetchAlertWorkLog,
      // commentNotification,
      // editNotificationComment,
      fetchStakeholderNotificationGroups,
      sendStakeholderNotification,
      updateStakeholderNotification,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  notificationGroupsListing: state.notificationGroupsListing,
  stakeHolderNotificationDialog: state.stakeHolderNotificationDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(WorkLogComponent);
