import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';

import { doesTargetExist } from '../../services/utilities';

export type onTextFilterChange = (value?: string) => void;
export type onTextFilterFocus = () => void;
export type onTextFilterReset = () => void;

export interface TextFilterProps {
  id?: string;
  placeholder: string;
  isSearching: boolean;
  onChange: onTextFilterChange;
  debounce?: number;
  minLength?: number;
  initialState?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TextFilter = (props: TextFilterProps) => {
  const [searchTerm, setSearchTerm] = useState(props.initialState !== undefined ? props.initialState : '');
  const [isFocused, setIsFocused] = useState(false);

  const handleClickResetButton = (e: any) => {
    if (e && e.target && e.target.className) {
      const className = e.target.className;
      if (className && typeof className == 'string' && className.includes('reset-filter')) {
        setSearchTerm('');
      }
    }
  };

  document.addEventListener('click', handleClickResetButton, false);
  console.log('test props.initialState=>', props);
  // console.log('test searchTerm=>', searchTerm);

  console.log('render TextFilter props.initialState =>', props.initialState);

  return (
    <span>
      <InputGroup>
        <Input
          id={props.id}
          className="text-filter"
          type="text"
          onKeyUp={(e) => {
            // @ts-ignore
            const value = e.target.value;
            if (e.code === 'Enter') {
              // Invoke onChange after user hit Enter/Return button
              setIsFocused(false);
              props.onChange(value);
            } else if (value === '' || value === undefined) {
              // Invoke onChange after value cleanup
              setIsFocused(false);
              props.onChange(undefined);
            }
          }}
          placeholder={props.placeholder}
          value={searchTerm}
          disabled={props.isSearching}
          onChange={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            console.log('Click outside');
            setIsFocused(false);
          }}
        />{' '}
        <InputGroupAddon addonType="append">
          <InputGroupText
            className="text-filter"
            style={{
              cursor: 'pointer',
              background: isFocused ? 'white' : props.isSearching ? '#e3e3e3' : '',
              border: isFocused ? '1px solid #9c9c9d' : '',
              borderLeft: isFocused ? '0' : '',
            }}
          >
            {searchTerm !== '' && (
              <>
                <Icon.Search
                  id={`${props.id}-search`}
                  style={{ marginRight: '6px' }}
                  onClick={() => {
                    props.onChange(searchTerm);
                  }}
                />
                <Icon.XLg
                  id={`${props.id}-clear`}
                  onClick={() => {
                    setSearchTerm('');
                    props.onChange('');
                  }}
                />
              </>
            )}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {/* @ts-ignore */}
      {props.id && doesTargetExist(props.id) ? <UncontrolledTooltip target={props.id}>Hit Enter after typing</UncontrolledTooltip> : ''}
    </span>
  );
};
