import { InCommandRequestPriorityLevels } from '@serverfarm/nocd-commons';
import moment from 'moment';
import { Maybe, Some } from 'monet';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Form, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { fetchSites } from '../sites';
import { fetchColleagues, fetchCompanies } from '../user';
import { DATE_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from '../utils';

import { addWorkLogEntry, fetchAlertWorkLog } from './data/alert-worklog';
import { sendNocInCommandRequest, updateNocInCommandRequest } from './data/alerts';
import { fetchStakeholderNotificationGroups } from './data/notification-groups';
import { NotificationGroup } from './entities';
import { alertWorkLogEdit } from './redux/alert-worklog/actions';

const messagesEndRef = React.createRef<HTMLDivElement>();
const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};

const AlertNocInCommandRequest = (props: Props /*alert: Maybe<Alert>, workLog: WorkLog[], sites: Maybe<Site[]>*/) => {
  const timeZoneRef = props.sitesListing.sites
    .flatMap((sites) =>
      Maybe.fromUndefined(
        sites.find((s) => props.nocInCommandRequestDialog.alert.map((a) => a.provider.indexOf(`/${s.monitorSiteId}`) > -1).getOrElse(false)),
      ),
    )
    .map((s) => s.timeZoneRef)
    .orUndefined();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props.sendNocInCommandRequest();
      }}
    >
      <FormGroup>
        <Label>Assignee</Label>
        <Typeahead<any>
          multiple={false}
          data-testid="assignee"
          id="assignee"
          onChange={(selected) => {
            // const filter = props.alertsListing.filter.map((filter: any) => ({
            //   ...filter,
            //   severityLevel: selected[0],
            // }));
            // props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              Maybe.fromUndefined(selected[0]),
              props.nocInCommandRequestDialog.alertDate,
              props.nocInCommandRequestDialog.acknowledgedDate,
              props.nocInCommandRequestDialog.notificationGroup,
              props.nocInCommandRequestDialog.requestPriority,
              props.nocInCommandRequestDialog.company,
              props.nocInCommandRequestDialog.notes,
            );
          }}
          options={props.colleaguesListing.colleagues
            .map((colleagues) =>
              colleagues.sort((a: any, b: any) =>
                `${a.companyName} - ${a.fullName}` > `${b.companyName} - ${b.fullName}`
                  ? 1
                  : `${a.companyName} - ${a.fullName}` < `${b.companyName} - ${b.fullName}`
                  ? -1
                  : 0,
              ),
            )
            .getOrElse([])}
          placeholder="Select a person to assign Request to"
          selected={props.nocInCommandRequestDialog.assignee.isSome() ? [props.nocInCommandRequestDialog.assignee.orUndefined()] : []}
          labelKey={(item) => `${item.companyName} - ${item.fullName}`}
          disabled={props.colleaguesListing.isInProgress}
        />
      </FormGroup>

      <FormGroup id="alert-date">
        <Label>Alert Date</Label>
        <Datetime
          initialValue={props.nocInCommandRequestDialog.alert.map((a) => a.updatedAt).getOrElse(new Date())}
          displayTimeZone={timeZoneRef}
          dateFormat={DATE_FORMAT_DEFAULT}
          timeFormat={TIME_FORMAT_DEFAULT}
          onChange={(date) => {
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              Maybe.fromUndefined(moment(date).toDate()),
              props.nocInCommandRequestDialog.acknowledgedDate,
              props.nocInCommandRequestDialog.notificationGroup,
              props.nocInCommandRequestDialog.requestPriority,
              props.nocInCommandRequestDialog.company,
              props.nocInCommandRequestDialog.notes,
            );
          }}
        />
      </FormGroup>

      <FormGroup id="acknowledge-date">
        <Label>Acknowledge Date</Label>
        <Datetime
          initialValue={props.nocInCommandRequestDialog.alert
            .flatMap((a) => Maybe.fromUndefined(a.lastNotification.acknowledged ? moment(a.lastNotification.acknowledged.createdAt) : undefined))
            .orUndefined()}
          displayTimeZone={timeZoneRef}
          dateFormat={DATE_FORMAT_DEFAULT}
          timeFormat={TIME_FORMAT_DEFAULT}
          onChange={(date) => {
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              props.nocInCommandRequestDialog.alertDate,
              Maybe.fromUndefined(moment(date).toDate()),
              props.nocInCommandRequestDialog.notificationGroup,
              props.nocInCommandRequestDialog.requestPriority,
              props.nocInCommandRequestDialog.company,
              props.nocInCommandRequestDialog.notes,
            );
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Alert Severity</Label>
        <Input value={props.nocInCommandRequestDialog.alert.map((a) => a.severityLevel.toString()).getOrElse('')} plaintext />
      </FormGroup>

      <FormGroup>
        <Label>Notification Group</Label>
        <Typeahead<NotificationGroup>
          multiple={false}
          data-testid="stakeholder-notification-groups"
          id="stakeholder-notification-groups"
          onChange={(selected) => {
            // const filter = props.alertsListing.filter.map((filter: any) => ({
            //   ...filter,
            //   severityLevel: selected[0],
            // }));
            // props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              props.nocInCommandRequestDialog.alertDate,
              props.nocInCommandRequestDialog.acknowledgedDate,
              Maybe.fromUndefined(selected[0]),
              props.nocInCommandRequestDialog.requestPriority,
              props.nocInCommandRequestDialog.company,
              props.nocInCommandRequestDialog.notes,
            );
          }}
          options={props.notificationGroupsListing.notificationGroups.getOrElse([])}
          placeholder="Choose Notification Group"
          selected={
            props.nocInCommandRequestDialog.notificationGroup.isSome()
              ? [props.nocInCommandRequestDialog.notificationGroup.orUndefined() as NotificationGroup]
              : []
          }
          labelKey="name"

          // disabled={props.alertsListing.isInProgress}
        />
      </FormGroup>

      <FormGroup>
        <Label>Request Priority</Label>
        <Typeahead<any>
          multiple={false}
          data-testid="request-priority"
          id="request-priority"
          onChange={(selected) => {
            // const filter = props.alertsListing.filter.map((filter: any) => ({
            //   ...filter,
            //   severityLevel: selected[0],
            // }));
            // props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              props.nocInCommandRequestDialog.alertDate,
              props.nocInCommandRequestDialog.acknowledgedDate,
              props.nocInCommandRequestDialog.notificationGroup,
              Maybe.fromUndefined(selected[0]),
              props.nocInCommandRequestDialog.company,
              props.nocInCommandRequestDialog.notes,
            );
          }}
          options={InCommandRequestPriorityLevels}
          placeholder="Select InCommand Request Priority Level"
          selected={props.nocInCommandRequestDialog.requestPriority.isSome() ? [props.nocInCommandRequestDialog.requestPriority.orUndefined()] : []}
          labelKey="fullName"
          disabled={props.colleaguesListing.isInProgress}
        />
      </FormGroup>

      <FormGroup>
        <Label>Company</Label>
        <Typeahead<any>
          multiple={false}
          data-testid="company"
          id="company"
          onChange={(selected) => {
            // const filter = props.alertsListing.filter.map((filter: any) => ({
            //   ...filter,
            //   severityLevel: selected[0],
            // }));
            // props.updateAlertListingFilter(filter, props.alertsListing.page, props.alertsListing.sizePerPage);
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              props.nocInCommandRequestDialog.alertDate,
              props.nocInCommandRequestDialog.acknowledgedDate,
              props.nocInCommandRequestDialog.notificationGroup,
              props.nocInCommandRequestDialog.requestPriority,
              Maybe.fromUndefined(selected[0]),
              props.nocInCommandRequestDialog.notes,
            );
          }}
          options={props.companiesListing.companies.getOrElse([])}
          placeholder="Choose a Company Request will be created for"
          selected={props.nocInCommandRequestDialog.company.isSome() ? [props.nocInCommandRequestDialog.company.orUndefined()] : []}
          labelKey="name"
          disabled={props.companiesListing.isInProgress}
        />
      </FormGroup>

      <FormGroup>
        <Label>Notes</Label>
        <Input
          type="textarea"
          placeholder="Put your comments/notes here"
          data-testid="content-input"
          id="content-input"
          // value={props.nocInCommandRequestSending.notes.getOrElse('')}
          value={props.nocInCommandRequestDialog.notes.getOrElse('')}
          onChange={(e) =>
            props.updateNocInCommandRequest(
              props.nocInCommandRequestDialog.alert,
              props.nocInCommandRequestDialog.assignee,
              props.nocInCommandRequestDialog.alertDate,
              props.nocInCommandRequestDialog.acknowledgedDate,
              props.nocInCommandRequestDialog.notificationGroup,
              props.nocInCommandRequestDialog.requestPriority,
              props.nocInCommandRequestDialog.company,
              Some(e.target.value),
            )
          }
        />
      </FormGroup>

      {doesTargetExist(`alert-date`) && <UncontrolledTooltip target={`alert-date`}>Site local {timeZoneRef} time</UncontrolledTooltip>}
      {doesTargetExist(`acknowledge-date`) && <UncontrolledTooltip target={`acknowledge-date`}>Site local {timeZoneRef} time</UncontrolledTooltip>}
    </Form>
  );
};

// const CommentArea = (props: Props) => {
//   return (
//     <div>
//       <Form
//         onSubmit={(e) => {
//           e.preventDefault();
//           props.alertWorkLogAdding.workLog.forEach((workLog) => {
//             props.addWorkLogEntry(props.alertWorkLogAdding.alert.map((a) => a.id).getOrElse(''), workLog);
//           });
//           scrollToBottom();
//         }}
//       >
//         <FormGroup>
//           <Label for="comment">Add to Work Log</Label>
//           <Input
//             data-testid="work-log-editor-name-input"
//             id="work-log-editor-name-input"
//             type="textarea"
//             value={props.alertWorkLogAdding.workLog.getOrElse('')}
//             onChange={(e) => props.alertWorkLogEdit(props.alertWorkLogListing.alert, Some(e.target.value))}
//           />
//         </FormGroup>
//         <Button color="primary" id="comment-btn" disabled={props.alertWorkLogAdding.isInProgress}>
//           Add
//         </Button>
//       </Form>
//     </div>
//   );
// };

class WorkLogComponent extends React.Component<Props, unknown> {
  refresh() {
    this.props.fetchSites();

    this.props.fetchStakeholderNotificationGroups();

    this.props.updateNocInCommandRequest(
      this.props.nocInCommandRequestDialog.alert,
      this.props.nocInCommandRequestDialog.assignee,
      this.props.nocInCommandRequestDialog.alert.map((a) => a.updatedAt),
      this.props.nocInCommandRequestDialog.alert.flatMap((a) => Maybe.fromUndefined(a.lastNotification.acknowledged)).map((a) => a.createdAt),
      this.props.nocInCommandRequestDialog.notificationGroup,
      this.props.nocInCommandRequestDialog.requestPriority,
      this.props.nocInCommandRequestDialog.company,
      this.props.nocInCommandRequestDialog.notes,
    );
    // this.props.fetchAlertWorkLog(
    //   this.props.alertWorkLogListing.alert.map((a) => a.id).getOrElse(''),
    //   this.props.alertWorkLogListing.filter,
    //   this.props.alertWorkLogListing.sort,
    //   this.props.alertWorkLogListing.page,
    //   this.props.alertWorkLogListing.sizePerPage,
    // );
  }

  // componentDidUpdate(prevProps: Props) {
  //   if (!prevProps.alertWorkLogListing.workLogs.equals(this.props.alertWorkLogListing.workLogs)) {
  //     scrollToBottom();
  //   }
  // }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    // const isFilterUpdated = !prevProps.alertWorkLogListing.filter.equals(this.props.alertWorkLogListing.filter);
    // const isSortingUpdated = !prevProps.alertWorkLogListing.sort.equals(this.props.alertWorkLogListing.sort);
    // const isPageSizeUpdated = !(prevProps.alertWorkLogListing.sizePerPage === this.props.alertWorkLogListing.sizePerPage);
    // const isPageChanged = !(prevProps.alertWorkLogListing.page === this.props.alertWorkLogListing.page);
    // const workLogWasAdded = !this.props.alertWorkLogListing.isInProgress && prevProps.alertWorkLogAdding.isInProgress;
    // if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged || workLogWasAdded) {
    //   this.refresh();
    // }
    const isSitesUpdated = prevProps.sitesListing.isInProgress && !this.props.sitesListing.isInProgress;
    if (isSitesUpdated) {
      const siteId = this.props.sitesListing.sites
        .flatMap((sites) =>
          Maybe.fromUndefined(
            sites.find((s) => this.props.nocInCommandRequestDialog.alert.map((a) => a.provider.indexOf(`/${s.monitorSiteId}`) === 0)),
          ),
        )
        .map((s) => s.id)
        .orUndefined();
      this.props.fetchCompanies(siteId);
      this.props.fetchColleagues(siteId);
    }
  }

  public render() {
    // const pageCount = Math.floor(this.props.alertNotificationsListing.total / this.props.alertNotificationsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <AlertNocInCommandRequest {...this.props} />
        {/* {this.props.alertWorkLogListing.isInProgress ? (
          <Spinner size="sm" />
        ) : (
          this.props.alertWorkLogListing.workLogs
            .map((workLogs) => {
              return AlertNocInCommandRequest(this.props.alertWorkLogListing.alert, workLogs, this.props.sitesListing.sites);
            })
            .orUndefined()
        )}
        <CommentArea {...this.props} /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchCompanies,
      fetchColleagues,
      alertWorkLogEdit,
      addWorkLogEntry,
      fetchSites,
      fetchAlertWorkLog,
      // commentNotification,
      // editNotificationComment,
      fetchStakeholderNotificationGroups,
      sendNocInCommandRequest,
      updateNocInCommandRequest,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  companiesListing: state.companiesListing,
  colleaguesListing: state.colleaguesListing,
  notificationGroupsListing: state.notificationGroupsListing,
  nocInCommandRequestDialog: state.nocInCommandRequestDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(WorkLogComponent);
