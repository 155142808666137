// @ts-ignore: No declarations available
import logdown from 'logdown';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Input, FormText, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { requestAlertsMapping, setSelectedAlert } from '../alerts';
import { showInfoNotification } from '../notification';
import { UserState } from '../user/redux/entities';

import AssetMapping from './AssetMapping';
import {
  addAssetMapping,
  closeAssetMappingDialog,
  fetchAssets,
  updateAssetListingFilter,
  updateAssetMappingDialogAsset,
  updateAssetMappingDialogMappingString,
  updateAssetMappingDialogProgressState,
} from './data/actions';
import { AssetsListingState } from './redux/entities';

const logger = logdown('component:RenderedReportHistory');

interface AssetListsProps {
  user: UserState;
  assetsListing: AssetsListingState;
}

class Component extends React.Component<Props, unknown> {
  public render() {
    if (this.props.assetMappingDialog.asset.isSome()) {
      logger.log(`Asset ${this.props.assetMappingDialog.asset.some()}`);
    }
    return (
      <Modal
        id="asset-mapping-modal"
        data-testid="asset-mapping-modal"
        isOpen={this.props.assetMappingDialog.isOpen}
        toggle={this.handleCancel.bind(this)}
      >
        <ModalHeader data-testid="asset-mapping-modal-header">
          <EllipsisText
            className="asset-mapping-header"
            text={`${this.props.assetMappingDialog.asset.map((a) => a.name).getOrElse('')} Asset Mapping`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <AssetMapping {...this.props} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="date-range-filter-save"
            color="primary"
            onClick={this.handleSave.bind(this)}
            disabled={this.props.assetMappingDialog.progressState.exists((p) => p.isInProgress)}
          >
            <Spinner size="sm" hidden={!this.props.assetMappingDialog.progressState.exists((p) => p.isInProgress)} /> Add
          </Button>
          <Button
            className="asset-mapping-cancel-button"
            data-testid="asset-mapping-modal-cancel-button"
            color="secondary"
            onClick={this.handleCancel.bind(this)}
            disabled={this.props.assetMappingDialog.progressState.exists((p) => p.isInProgress)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected handleCancel(e: any) {
    e.preventDefault();
    this.props.setSelectedAlert('');
    this.props.closeAssetMappingDialog();
  }

  protected handleSave() {
    if (this.props.assetMappingDialog.asset.isSome() && this.props.assetMappingDialog.mappingString.isSome()) {
      this.props.addAssetMapping(this.props.assetMappingDialog.asset.some().id, this.props.assetMappingDialog.mappingString.some());
      if (this.props.assetMappingDialog.alert.isSome()) {
        this.props.requestAlertsMapping(this.props.assetMappingDialog.alert.some().id);
      }
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      showInfoNotification,
      fetchAssets,
      updateAssetListingFilter,
      addAssetMapping,
      updateAssetMappingDialogAsset,
      updateAssetMappingDialogMappingString,
      updateAssetMappingDialogProgressState,
      closeAssetMappingDialog,
      requestAlertsMapping,
      setSelectedAlert,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  assetsListing: state.assetsListing,
  sitesListing: state.sitesListing,
  assetMappingDialog: state.assetMappingDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
