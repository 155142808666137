import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import { dismissNotification } from './data/actions';
import Notification from './Notification';

class Component extends React.Component<Props, unknown> {
  public render() {
    return (
      <div className="notifications">
        {this.props.notifications.map((notification) => (
          <Notification key={notification.id} {...notification} onCloseClick={() => this.props.dismissNotification(notification.id)} />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      dismissNotification,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  notifications: state.notifications,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
