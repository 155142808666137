import { Maybe } from 'monet';

import { ColleaguesListingState, CompaniesListingState, UserProfile, UserState } from './entities';

const userProfile: UserProfile = {
  role: Maybe.None(),
};

const companiesListingState: CompaniesListingState = { isInProgress: false, companies: Maybe.None(), error: Maybe.None() };

const colleaguesListingState: ColleaguesListingState = { isInProgress: false, colleagues: Maybe.None(), error: Maybe.None() };

const userState: UserState = {
  isLoggingIn: false,
  isLoggedIn: false,
  profile: userProfile,
};

export const INITIAL_USER_STATE = {
  userProfile,
  companiesListingState,
  colleaguesListingState,
  userState,
};
