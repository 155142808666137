// eslint-disable @typescript-eslint/no-unused-vars
import logdown from 'logdown';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default is localStorage
import thunkMiddleware from 'redux-thunk';

import { createAlertsListingTransform } from './components/alerts';
import { alertNotificationsListingTransform } from './components/alerts/redux/alert-notifications/store';
import { createEventsListingTransform } from './components/events';
import {
  createMonitoringSystemDeletingTransform,
  createMonitoringSystemEditingTransform,
  createMonitoringSystemSavingTransform,
  createMonitoringSystemsListingTransform,
} from './components/monitoring-systems/redux/monitoring-systems/store';
import { createRawNotificationsListingTransform } from './components/notifications-status';
import { createUserTransform /*, companiesListingTransform*/ } from './components/user';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    createUserTransform(),
    createEventsListingTransform(),
    createAlertsListingTransform(),
    alertNotificationsListingTransform(),
    createRawNotificationsListingTransform(),
    createMonitoringSystemsListingTransform(),
    createMonitoringSystemDeletingTransform(),
    createMonitoringSystemEditingTransform(),
    createMonitoringSystemSavingTransform(),
  ],
  blacklist: [
    'eventsListing',
    'notifications',
    'routes' /*'alertsListing', */,
    'assetMaintenanceDialog',
    'assetMappingDialog',
    'assetsListing',
    'sitesListing',
    'sitesGroupsListing',
    'alertNotificationsListing',
    'alertWorkLogListing',
    'alertWorkLogAdding',
    'alertSourceInformationDialog',
    'messageInformationDialog',
    'stakeHolderNotificationDialog',
    'notificationGroupsListing',
    'colleaguesListing',
    'companiesListing',
    'nocInCommandRequestDialog',
    'alertsTrackingTotalCount',
    'assetsInMaintenanceDialog',
    'monitoringSystemIncidentInformationDialog',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = createLogger({
  logger: logdown('redux', { markdown: false }),
});

// @ts-ignore
export const createReduxStore = () => createStore(persistedReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));

const store = createReduxStore();

export default store;
