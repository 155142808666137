import logdown, { Logger } from 'logdown';
import { Maybe, None } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../config';
import axios from '../../../services/axios';
import { showErrorNotification } from '../../notification';
import { EventsListSort, EventsListFilter } from '../entities';
import { eventListingFilterUpdate, eventsReceive, eventsReceiveError, eventsRequest, eventListingSortUpdate, eventsReset } from '../redux/actions';

import { eventDeserialize, EventsListQueryResponse } from './entities';
import { eventListingFilterSerialize } from './serdes';

export const fetchEvents =
  (
    filter: Maybe<EventsListFilter>,
    page: number,
    sizePerPage: number,
    sort: Maybe<EventsListSort>,
    logger: Logger = logdown('events:data:actions:fetchEvents'),
  ) =>
  (dispatch: Dispatch<any> /*, getState: () => State*/): void => {
    logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(eventsRequest(filter, sizePerPage, sort));
    const serializedFilter = eventListingFilterSerialize(filter.getOrElse({ createdAt: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/events/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from: serializedFilter.from ? serializedFilter.from : new Date(0).toISOString(),
        to: serializedFilter.to,
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
      },
    })
      .then((result) => result.data as EventsListQueryResponse)
      .then((eventsData) => {
        dispatch(
          eventsReceive(
            eventsData.events.map((event) => eventDeserialize(event)),
            page,
            eventsData.total,
          ),
        );
      })
      .catch((error) => {
        dispatch(eventsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const updateEventListingFilter =
  (filter: Maybe<EventsListFilter>, page: number, sizePerPage: number, logger: Logger = logdown('events:data:actions:updateEventListingFilter')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(eventListingFilterUpdate(filter, page, sizePerPage));
  };

export const updateEventListingSort =
  (sort: Maybe<EventsListSort>, logger: Logger = logdown('events:data:actions:updateEventListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
    dispatch(eventListingSortUpdate(sort));
  };

export const resetEvents =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(eventsReset());
  };
