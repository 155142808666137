import { Maybe } from 'monet';

import { DIALOG_IN_PROGRESS_INITIAL_STATE } from '../../../redux/constants';

import { AssetMaintenanceDialogState } from './entities';

const assetMaintenanceDialogState: AssetMaintenanceDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  asset: Maybe.None(),
  alert: Maybe.None(),
  acknowledgeAlert: Maybe.None(),
  startDate: Maybe.None(),
  endDate: Maybe.None(),
};

export const ASSET_MAINTENANCE_INITIAL_STATE = {
  assetMaintenanceDialogState,
};
