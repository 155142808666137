/* eslint-disable @typescript-eslint/no-unused-vars */
import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { CompaniesListingState, UserState } from './entities';

export const createUserTransform = (logger: Logger = logdown('redux-persist:transform:user')): Transform<UserState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        profile: {
          ...subState.profile,
          role: subState.profile.role.orUndefined(),
        },
      };
      // logger.log(`serialize (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        profile: {
          ...state.profile,
          role: Maybe.fromUndefined(state.profile.role),
        },
      };
    },
    { whitelist: ['user'] },
  );

export const companiesListingTransform = (
  logger: Logger = logdown('redux-persist:transform:companiesListing'),
): Transform<CompaniesListingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        companies: subState.companies.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        companies: Maybe.fromUndefined(state.companies),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['companiesListing'] },
  );
