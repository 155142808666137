// import logdown, { Logger } from 'logdown';
import { Dispatch } from 'redux';
import * as uuid from 'uuid';

import { logoutUser } from '../../user';
import { IconType } from '../Notification';
import { notificationDismiss, notificationShow } from '../redux/actions';

const DISMISS_TIMEOUT_DEFAULT = 5000;

export const makeNotificationId = (): string => uuid.v4();

/**
 * Notification related functionality
 */
export const showNotification =
  (text: string, icon?: IconType, title?: string, dismissTimeout: number | null = DISMISS_TIMEOUT_DEFAULT, id: string = makeNotificationId()) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationShow(id, text, icon, title));
    if (dismissTimeout !== null) {
      void new Promise((resolve) => setTimeout(resolve, dismissTimeout)).then(() => dispatch(notificationDismiss(id)));
    }
  };

export const dismissNotification =
  (id: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationDismiss(id));
  };

export const showInfoNotification =
  (text: string, title?: string, dismissTimeout: number | null = DISMISS_TIMEOUT_DEFAULT, id: string = makeNotificationId()) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationShow(id, text, IconType.INFO, title));
    if (dismissTimeout !== null) {
      void new Promise((resolve) => setTimeout(resolve, dismissTimeout)).then(() => dispatch(notificationDismiss(id)));
    }
  };

export const showWarningNotification =
  (text: string, title?: string, dismissTimeout: number | null = DISMISS_TIMEOUT_DEFAULT, id: string = makeNotificationId()) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(notificationShow(id, text, IconType.WARNING, title));
    if (dismissTimeout !== null) {
      void new Promise((resolve) => setTimeout(resolve, dismissTimeout)).then(() => dispatch(notificationDismiss(id)));
    }
  };

export const showErrorNotification =
  (
    error: string | (Error & { response?: any }),
    dismissTimeout: number | null = DISMISS_TIMEOUT_DEFAULT,
    id: string = makeNotificationId(),
    // logger: Logger = logdown('errorNotification'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    if (typeof error !== 'string') {
      if (error && error.response && error.response.status === 401) {
        const authErrorMessage = 'Your Access Token has been either expired or invalidated by server. Please login again';
        showNotification(authErrorMessage, IconType.DANGER, 'Error', dismissTimeout, id)(dispatch);
        logoutUser()(dispatch);
      } else {
        const errorMessage = error.response ? error.response.data.msg : error.message;
        showNotification(errorMessage, IconType.DANGER, 'Error', dismissTimeout, id)(dispatch);
      }
    } else {
      showNotification(error, IconType.DANGER, 'Error', dismissTimeout, id)(dispatch);
    }
  };
