import { Maybe } from 'monet';
import { Reducer } from 'redux';
// import logger from 'redux-logger';

import { ALERT_INITIAL_STATE } from '../constants';
import { AlertNotificationsListingState } from '../entities';

import { AlertNotificationsFetchAction, AlertNotificationListActionType } from './actions';

export const alertNotificationsListingReducer: Reducer<AlertNotificationsListingState, AlertNotificationsFetchAction> = (
  state = ALERT_INITIAL_STATE.alertNotificationsListingState,
  action,
) => {
  switch (action.type) {
    case AlertNotificationListActionType.OPEN_ALERT_NOTIFICATIONS_LIST:
      return {
        ...state,
        isInProgress: false,
        alert: action.alert,
        notification: Maybe.None(),
        notifications: Maybe.None(),
        notificationComment: Maybe.None(),
        total: 0,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case AlertNotificationListActionType.CLOSE_ALERT_NOTIFICATIONS_LIST:
      return {
        ...state,
        isInProgress: false,
        alert: Maybe.None(),
        notification: Maybe.None(),
        notifications: Maybe.None(),
        notificationComment: Maybe.None(),
        total: 0,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case AlertNotificationListActionType.OPEN_ALERT_NOTIFICATION_COMMENTS_LIST:
      return {
        ...state,
        isInProgress: false,
        notificationComment: action.notificationComment,
        total: 0,
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.CLOSE_ALERT_NOTIFICATION_COMMENTS_LIST:
      return {
        ...state,
        isInProgress: false,
        notificationComment: Maybe.None(),
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case AlertNotificationListActionType.REQUEST_ALERT_NOTIFICATIONS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.RECEIVE_ALERT_NOTIFICATIONS:
      return {
        ...state,
        isInProgress: false,
        notifications: action.notifications.length
          ? Maybe.Some(
              action.notifications.map((n) => ({
                ...n,
                isNew: true,
              })),
            )
          : Maybe.None(),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case AlertNotificationListActionType.RECEIVE_ALERT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isInProgress: false,
        notifications: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case AlertNotificationListActionType.UPDATE_ALERT_NOTIFICATIONS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case AlertNotificationListActionType.UPDATE_ALERT_NOTIFICATIONS_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case AlertNotificationListActionType.EDIT_NOTIFICATION_COMMENT:
      return {
        ...state,
        commentEditing: Maybe.Some(action.commentEditing),
      };
    case AlertNotificationListActionType.REQUEST_NOTIFICATION_COMMENT:
      return {
        ...state,
        isInProgress: true,
        notificationComment: action.notificationComment,
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_COMMENT:
      return {
        ...state,
        isInProgress: false,
        notificationComment: state.notificationComment.map((n) => {
          return {
            ...n,
            comments: [
              ...(n.comments ? n.comments : []),
              {
                comment: state.commentEditing.getOrElse(''),
                companyId: 1,
                createdAt: new Date(),
                id: 'id-1',
                userName: action.userName as string,
                new: true,
              },
            ],
          };
        }),
        commentEditing: Maybe.None(),
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_COMMENT_ERROR:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };

    case AlertNotificationListActionType.REQUEST_NOTIFICATION_ACK:
      return {
        ...state,
        isInProgress: true,
        notification: action.notification,
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_ACK:
      return {
        ...state,
        isInProgress: false,
        notification: state.notification.map((n) => {
          return {
            ...n,
            acknowledged: n.acknowledged,
          };
        }),
        commentEditing: Maybe.None(),
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_ACK_ERROR:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    case AlertNotificationListActionType.TOGGLE_NOTIFICATION_SELECTED:
      return {
        ...state,
        isInProgress: false,
        notifications: Maybe.some(
          state.notifications
            .map((ns) => {
              const nIdx = ns.findIndex((n) => n.id === action.notificationId);
              ns[nIdx].selected = !ns[nIdx].selected;
              return ns;
            })
            .some(),
        ),
        commentEditing: Maybe.None(),
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.TOGGLE_NOTIFICATION_SELECTED_ALL:
      return {
        ...state,
        isInProgress: false,
        notifications: Maybe.some(
          state.notifications
            .map((ns) => {
              ns.forEach((n) => {
                n.selected = n.acknowledged ? false : action.selected;
              });
              return ns;
            })
            .some(),
        ),
        commentEditing: Maybe.None(),
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.REQUEST_NOTIFICATION_BULK_ACK:
      return {
        ...state,
        isInProgress: true,
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_BULK_ACK:
      return {
        ...state,
        isInProgress: false,
        notifications: Maybe.some(
          state.notifications
            .map((ns) => {
              ns.filter((n) => action.notificationIds.includes(n.id)).forEach((n) => {
                // n.acknowledged = [
                //   ...(n.acknowledged ? n.acknowledged : []),
                //   {
                //     userId: 1,
                //     userName: 'N/A',
                //     companyId: 1, // todo: replace it with user companyId
                //     created: new Date(),
                //   },
                // ];
                n.selected = false;
              });
              return ns;
            })
            .some(),
        ),
        commentEditing: Maybe.None(),
        error: Maybe.None(),
      };
    case AlertNotificationListActionType.RECEIVE_NOTIFICATION_BULK_ACK_ERROR:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };

    case AlertNotificationListActionType.ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION:
      let { notificationsNotShown } = state;
      const notifications = Maybe.some(
        state.notifications
          .map((notifications) => {
            const newNot = action.notification;
            console.log('ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION', newNot);
            const filter = state.filter.orUndefined();
            let toInsert = true;
            if (
              filter &&
              (filter.createdAt.isSome() ||
                filter.isInMaintenance !== undefined ||
                filter.provider ||
                filter.severityLevel !== undefined ||
                filter.source ||
                filter.state)
            ) {
              toInsert = false;
            }
            const sort = state.sort.orUndefined();
            if (sort && sort.field !== 'created' && sort.order !== 'desc') {
              toInsert = false;
            }
            console.log('ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION', toInsert, filter, sort);
            if (toInsert) {
              notifications.unshift({
                ...newNot,
                state: newNot.state,
                selected: false,
                isNew: true,
              });
              if (notifications.length > state.sizePerPage) {
                notifications.pop();
              }
            } else {
              notificationsNotShown++;
            }
            console.log('ALERT_NOTIFICATION_RECEIVE_NEW_NOTIFICATION', notifications);
            return notifications;
          })
          .some(),
      );
      return {
        ...state,
        notifications,
        notificationsNotShown,
      };
    case AlertNotificationListActionType.RESET_NOTIFICATION_GLOW:
      return {
        ...state,
        alerts: Maybe.some(
          state.notifications
            .map((notifications) => {
              const not = action.notification;
              const alert = notifications.find((a) => a.source === not.source);
              if (alert) {
                const alertIdx = notifications.indexOf(alert);
                alert.isNew = false;
                notifications.splice(alertIdx, 1, alert);
              }
              return notifications;
            })
            .some(),
        ),
      };
    case AlertNotificationListActionType.RESET_ALERT_NOTIFICATIONS:
      return ALERT_INITIAL_STATE.alertNotificationsListingState;
    case AlertNotificationListActionType.RESET_ALERT_FILTER_NOTIFICATIONS:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.None(),
        filter: Maybe.None(),
        sort: Maybe.None(),
        page: 0,
        total: 0,
        sizePerPage: 10,
      };
    //   return ALERT_INITIAL_STATE.alertNotificationsFiltersState;
    default:
      return state;
  }
};
