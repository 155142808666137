// import logdown from 'logdown';
import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import { fetchEvents, updateEventListingFilter, updateEventListingSort, resetEvents } from './data/actions';
import EventsList from './EventsList';

// const logger = logdown('component:Events');

class Component extends React.Component<Props, unknown> {
  componentWillUnmount() {
    this.props.resetEventsState();
  }

  public render() {
    return (
      <div className="content">
        <EventsList {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchEvents,
      updateEventListingFilter,
      updateEventListingSort,
      resetEventsState: resetEvents,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  eventsListing: state.eventsListing,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
