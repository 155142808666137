// import { AssetState } from '@serverfarm/nocd-commons';
// import moment from 'moment';
// import logger from 'redux-logger';

import { Asset } from '../entities';

// import { Asset } from '../../entities';
// import { NotificationApiEntity } from '../asset-notifications';

export interface AssetsListFilterApiEntity {
  name?: string[];
}

// export interface AssetAssetApiEntity {
//   id: number;
//   name: string;
// }

export interface AssetApiEntity {
  id: number;
  name: string;
  maintenance: {
    id: number;
    start: string;
    end: string;
  }[];
  mappings: {
    id: number;
    mapping: string;
  }[];
  siteName: string;
  monitorSiteId: string;
  suite: string;
}

export interface AssetsListQueryResponse {
  assets: AssetApiEntity[];
  page: number;
  perPage: number;
  total: number;
}

export const assetDeserialize = (assetApiEntity: AssetApiEntity): Asset => {
  return {
    id: assetApiEntity.id,
    name: assetApiEntity.name,
    maintenance: assetApiEntity.maintenance?.map((m) => ({
      id: m.id,
      start: new Date(m.start),
      end: new Date(m.end),
    })),
    mappings: assetApiEntity.mappings?.map((m) => ({
      id: m.id,
      mapping: m.mapping,
    })),
    siteName: assetApiEntity.siteName,
    monitorSiteId: assetApiEntity.monitorSiteId,
    suite: assetApiEntity.suite,
  };
};
