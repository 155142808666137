import moment from 'moment';
import { Maybe, None, Some } from 'monet';
import { Reducer } from 'redux';

import { MonitoringSystemsDialogState } from '../../../monitoring-systems';
import { MONITORING_SYSTEMS_INITIAL_STATE } from '../../../monitoring-systems/redux/constants';
import { ALERT_INITIAL_STATE, ASSETS_IN_MAINTENANCE_INITIAL_STATE } from '../constants';
import {
  AlertSourceInformationDialogState,
  AlertsListingState,
  AlertsTrackingTotalCountState,
  AssetsInMaintenanceDialogState,
  NocInCommandRequestDialogState,
  StakeholderNotificationDialogState,
} from '../entities';

import { ActionType, AlertsFetchAction } from './actions';

export const alertsListingReducer: Reducer<AlertsListingState, AlertsFetchAction> = (state = ALERT_INITIAL_STATE.alertsListingState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_ALERTS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ALERTS:
      return {
        ...state,
        isInProgress: false,
        alerts: Maybe.some(
          action.alerts.map((alert, idx) => {
            const currentAlerts = state.alerts.orJust([]);
            const exists = currentAlerts.some((a) => {
              const found =
                a.id === alert.id &&
                a.isInMaintenance == alert.isInMaintenance &&
                a.notificationsCount == alert.notificationsCount &&
                a.severityLevel == alert.severityLevel &&
                a.source === alert.source &&
                a.state === alert.state &&
                // moment(a.timestamp).diff(moment(alert.timestamp), 's') === 0 &&
                moment(a.updatedAt).diff(moment(alert.updatedAt), 's') === 0;
              return found;
            });
            // console.log('CURRENT', exists, currentAlerts, alert);
            return { ...alert, isNew: !exists };
          }),
        ),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.RECEIVE_EXPORTED_ALERTS:
      return {
        ...state,
        exportedAlerts: Maybe.some(
          action.exportedAlerts.map((alert, idx) => {
            const currentAlerts = state.alerts.orJust([]);
            const exists = currentAlerts.some((a) => {
              const found =
                a.id === alert.id &&
                a.isInMaintenance == alert.isInMaintenance &&
                a.notificationsCount == alert.notificationsCount &&
                a.severityLevel == alert.severityLevel &&
                a.source === alert.source &&
                a.state === alert.state &&
                // moment(a.timestamp).diff(moment(alert.timestamp), 's') === 0 &&
                moment(a.updatedAt).diff(moment(alert.updatedAt), 's') === 0;
              if (idx === 0) console.log('COMPARE', found, a, alert);
              return found;
            });
            // console.log('CURRENT', exists, currentAlerts, alert);
            return { ...alert, isNew: !exists };
          }),
        ),
      };
    case ActionType.RECEIVE_ALERTS_ERROR:
      return {
        ...state,
        isInProgress: false,
        alerts: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case ActionType.UPDATE_ALERTS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_ALERTS_LISTING_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.hiddenColumns,
      };
    case ActionType.UPDATE_ALERTS_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.ALERT_SELECT:
      return {
        ...state,
        selectedAlert: action.selectedAlert,
      };
    case ActionType.RECEIVE_NEW_NOTIFICATION:
      let { notificationsNotShown } = state;
      const alerts = Maybe.some(
        state.alerts
          .map((alerts) => {
            const newAlert = action.notification.some();
            const alert = alerts.find((a) => a.id === newAlert.alertId);
            if (alert) {
              const alertIdx = alerts.indexOf(alert);
              alerts.splice(alertIdx, 1);
              alerts.unshift({
                ...newAlert,
                state: newAlert.state,
                updatedAt: newAlert.createdAt,
                id: newAlert.alertId,
                notificationsCount: alert.notificationsCount + 1,
                commentsCount: alert.commentsCount,
                alertWorkLogs: alert.alertWorkLogs,
                alertWorkLogsCount: alert.alertWorkLogsCount,
                alertWorkLogsCountByType: alert.alertWorkLogsCountByType,
                lastNotification: alert.lastNotification,
                isNew: true,
                isPinned: false,
                assets: [], // todo : add alert assets insertion
              });
            } else {
              const filter = state.filter.orUndefined();
              let toInsert = true;
              if (filter) {
                toInsert = false;
              }
              const sort = state.sort.orUndefined();
              if (sort && sort.field !== 'updated' && sort.order !== 'desc') {
                toInsert = false;
              }
              if (toInsert) {
                alerts.unshift({
                  ...newAlert,
                  state: newAlert.state,
                  updatedAt: newAlert.createdAt,
                  id: newAlert.alertId,
                  notificationsCount: 0, //newAlert.notificationsCount + 1,
                  commentsCount: 0,
                  alertWorkLogs: [],
                  alertWorkLogsCount: 0,
                  alertWorkLogsCountByType: {},
                  lastNotification: newAlert,
                  isNew: true,
                  isPinned: true,
                  assets: [], // todo : add alert assets insertion
                });
                if (alerts.length > state.sizePerPage) {
                  alerts.pop();
                }
              } else {
                notificationsNotShown++;
              }
            }
            return alerts;
          })
          .some(),
      );
      return {
        ...state,
        alerts,
        notificationsNotShown,
      };
    case ActionType.RESET_ALERT_GLOW:
      return {
        ...state,
        alerts: Maybe.some(
          state.alerts
            .map((alerts) => {
              const newAlert = action.notification.some();
              const alert = alerts.find((a) => a.source === newAlert.source);
              if (alert) {
                const alertIdx = alerts.indexOf(alert);
                alert.isNew = false;
                alerts.splice(alertIdx, 1, alert);
              }
              return alerts;
            })
            .some(),
        ),
      };
    case ActionType.RESET_ALL_ALERT_GLOW:
      return {
        ...state,
        alerts: Maybe.fromUndefined(
          state.alerts
            .map((alerts) =>
              alerts.map((a) => ({
                ...a,
                isNew: false,
              })),
            )
            .orUndefined(),
        ),
      };
    case ActionType.RESET_ALERTS:
      return ALERT_INITIAL_STATE.alertsListingState;
    case ActionType.RESET_EXPORTED_ALERTS:
      return {
        ...state,
        exportedAlerts: None(),
      };
    default:
      return state;
  }
};

export const alertSourceInformationDialogReducer: Reducer<AlertSourceInformationDialogState, AlertsFetchAction> = (
  state = ALERT_INITIAL_STATE.alertSourceInformationDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_ALERT_SOURCE_INFORMATION:
      return {
        ...state,
        isOpen: true,
        alert: action.alert,
      };

    case ActionType.CLOSE_ALERT_SOURCE_INFORMATION:
      return {
        ...ALERT_INITIAL_STATE.alertSourceInformationDialogState,
      };
    default:
      return state;
  }
};

export const assetsInMaintenanceDialogReducer: Reducer<AssetsInMaintenanceDialogState, AlertsFetchAction> = (
  state = ASSETS_IN_MAINTENANCE_INITIAL_STATE.assetsInMaintenanceDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_ASSETS_IN_MAINTENANCE:
      return {
        ...state,
        isOpen: true,
      };

    case ActionType.CLOSE_ASSETS_IN_MAINTENANCE:
      return {
        ...ASSETS_IN_MAINTENANCE_INITIAL_STATE.assetsInMaintenanceDialogState,
      };
    default:
      return state;
  }
};

export const monitoringSystemsDialogReducer: Reducer<MonitoringSystemsDialogState, AlertsFetchAction> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_MONITORING_SYSTEMS:
      return {
        ...state,
        isOpen: true,
      };

    case ActionType.CLOSE_MONITORING_SYSTEMS:
      return {
        ...MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsDialogState,
      };
    default:
      return state;
  }
};

export const stakeHolderNotificationDialogReducer: Reducer<StakeholderNotificationDialogState, AlertsFetchAction> = (
  state = ALERT_INITIAL_STATE.stakeholderNotificationDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_SEND_STAKEHOLDER_NOTIFICATION:
      return {
        ...state,
        isOpen: true,
        alert: action.alert,
      };
    case ActionType.UPDATE_STAKEHOLDER_NOTIFICATION:
      return {
        ...state,
        alert: action.alert,
        notificationGroup: action.notificationGroup,
        subject: action.subject,
        content: action.content,
      };

    case ActionType.UPDATE_SEND_STAKEHOLDER_NOTIFICATION_PROGRESS_STATE:
      return {
        ...state,
        progressState: action.stakeholderNotificationProgressState,
      };
    case ActionType.CLOSE_SEND_STAKEHOLDER_NOTIFICATION:
      return {
        ...ALERT_INITIAL_STATE.stakeholderNotificationDialogState,
      };
    default:
      return state;
  }
};

export const nocInCommandRequestDialogReducer: Reducer<NocInCommandRequestDialogState, AlertsFetchAction> = (
  state = ALERT_INITIAL_STATE.nocInCommandRequestDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_SEND_NOCD_IN_COMMAND_REQUEST:
      return {
        ...state,
        isOpen: true,
        alert: action.alert,
      };
    case ActionType.REQUEST_SEND_NOCD_IN_COMMAND_PROGRESS_STATE:
      return {
        ...state,
        progressState: action.nocInCommandRequestProgressState,
      };
    case ActionType.UPDATE_NOCD_IN_COMMAND_REQUEST:
      return {
        ...state,
        alert: action.alert,
        assignee: action.assignee,
        alertDate: action.alertDate,
        acknowledgedDate: action.acknowledgedDate,
        notificationGroup: action.notificationGroup,
        requestPriority: action.requestPriority,
        company: action.company,
        notes: action.notes,
      };
    case ActionType.CLOSE_SEND_NOCD_IN_COMMAND_REQUEST:
      return {
        ...ALERT_INITIAL_STATE.nocInCommandRequestDialogState,
      };
    default:
      return state;
  }
};

export const alertsTrackingTotalCountReducer: Reducer<AlertsTrackingTotalCountState, AlertsFetchAction> = (
  state = ALERT_INITIAL_STATE.alertsTrackingTotalCountState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_ALERTS_TRACKING_TOTAL_COUNT:
      return {
        ...state,
        total: None(),
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ALERTS_TRACKING_TOTAL_COUNT:
      return {
        ...state,
        total: action.totalTrackedAlerts,
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_ALERTS_TRACKING_TOTAL_COUNT_ERROR:
      return {
        ...state,
        total: None(),
        isInProgress: false,
        error: Some(action.error || 'error'),
      };
    default:
      return state;
  }
};
