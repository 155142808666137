// @ts-ignore: No declarations available
import { props } from 'bluebird';
import logdown from 'logdown';
import moment from 'moment';
// @ts-ignore: No declarations available
import { Maybe } from 'monet';
import React from 'react';
import Datetime from 'react-datetime';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, FormText, Spinner, Label, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { doesTargetExist } from '../../services/utilities';
import { ackAndPutInMaintByAst, ackNotification } from '../alerts/data/alert-notifications/actions';
import { DATE_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from '../utils';

import { closeAssetMaintenanceDialog, scheduleAssetMaintenance, updateAssetMaintenance, updateAssetMaintenanceDialogDateRange } from './data/actions';

const logger = logdown('component:AssetMaintenanceDialog');

class Component extends React.Component<Props, unknown> {
  protected handleSave() {
    if (
      this.props.assetMaintenanceDialog.asset.isSome() &&
      this.props.assetMaintenanceDialog.startDate.isSome() &&
      this.props.assetMaintenanceDialog.endDate.isSome()
    ) {
      if (
        this.props.assetMaintenanceDialog.asset
          .flatMap((asset) => Maybe.fromUndefined(asset.maintenance))
          .exists((maintenance) => maintenance && maintenance.length > 0)
      ) {
        this.props.updateAssetMaintenance(
          this.props.assetMaintenanceDialog.asset.some().id,
          this.props.assetMaintenanceDialog.asset.some().maintenance[0].id,
          this.props.assetMaintenanceDialog.endDate.some(),
        );
      } else {
        this.props.scheduleAssetMaintenance(
          this.props.assetMaintenanceDialog.asset.some().id,
          this.props.assetMaintenanceDialog.startDate.some(),
          this.props.assetMaintenanceDialog.endDate.some(),
        );
        if (this.props.assetMaintenanceDialog.alert.isSome() && this.props.assetMaintenanceDialog.acknowledgeAlert.isSome()) {
          //this.props.ackNotification(this.props.assetMaintenanceDialog.alert.map((a) => ({ ...a.lastNotification, selected: false })).some(), true);
          this.props.ackAndPutInMaintByAst(this.props.assetMaintenanceDialog.asset.some().id);
        }
      }
    }
  }

  protected handleCancel(e: any) {
    e.preventDefault();
    this.props.closeAssetMaintenanceDialog();
  }

  protected handleStopMaintenance(e: any) {
    e.preventDefault();
    this.props.closeAssetMaintenanceDialog();
  }

  protected isValid() {
    return (
      this.props.assetMaintenanceDialog.startDate.map((startDate) => moment().isSameOrAfter(startDate)).getOrElse(false) &&
      this.props.assetMaintenanceDialog.endDate.map((endDate) => moment().isSameOrBefore(endDate)).getOrElse(false)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const isOpened = prevProps.assetMaintenanceDialog.asset.isNone() && this.props.assetMaintenanceDialog.asset.isSome();
    if (isOpened) {
      if (this.props.assetMaintenanceDialog.asset.flatMap((asset) => Maybe.fromUndefined(asset.maintenance)).exists((m) => !!m.length)) {
        const maintenance = this.props.assetMaintenanceDialog.asset.flatMap((asset) => Maybe.fromUndefined(asset.maintenance));

        maintenance.forEach((maintenance) => {
          maintenance.forEach((m) => {
            this.props.updateAssetMaintenanceDialogDateRange(m.start, moment().add(1, 'minute').toDate());
          });
        });
      } else {
        this.props.updateAssetMaintenanceDialogDateRange(moment().toDate(), moment().add(1, 'hour').toDate());
      }
    }
  }

  public render() {
    const timeZoneRef = this.props.sitesListing.sites
      .flatMap((sites) =>
        Maybe.fromUndefined(
          sites.find((s) => {
            if (this.props.assetMaintenanceDialog.alert.isSome()) {
              return this.props.assetMaintenanceDialog.alert.map((a) => a.provider.indexOf(`/${s.monitorSiteId}`) === 0);
            } else {
              return this.props.assetMaintenanceDialog.asset.map((a) => a.monitorSiteId === s.monitorSiteId).getOrElse(false);
            }
          }),
        ),
      )
      .map((s) => s.timeZoneRef)
      .orUndefined();
    const isAlertAcknowledge = this.props.assetMaintenanceDialog.alert.isSome() && this.props.assetMaintenanceDialog.acknowledgeAlert.contains(true);
    const isMaintenanceEdit =
      !isAlertAcknowledge &&
      this.props.assetMaintenanceDialog.asset.map((asset) => asset.maintenance).exists((maintenance) => maintenance && maintenance.length > 0);
    return (
      <Modal
        id="alert-notifications-modal"
        data-testid="alert-notifications-modal"
        style={{ maxWidth: '600px', width: '80%' }}
        isOpen={this.props.assetMaintenanceDialog.isOpen}
        toggle={this.handleCancel.bind(this)}
      >
        <ModalHeader data-testid="alert-notifications-modal-header">
          <EllipsisText
            className="alert-notifications-header"
            text={`Asset Maintenance: ${this.props.assetMaintenanceDialog.asset.map((a) => a.name).getOrElse('N/A')}`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup id="maintenance-start-date" hidden={isMaintenanceEdit}>
              <Label>Start</Label>
              <Datetime
                closeOnSelect
                initialValue={this.props.assetMaintenanceDialog.startDate.getOrElse(new Date())}
                displayTimeZone={timeZoneRef}
                dateFormat={DATE_FORMAT_DEFAULT}
                timeFormat={TIME_FORMAT_DEFAULT}
                onChange={(date) => {
                  this.props.updateAssetMaintenanceDialogDateRange(
                    moment(date).toDate(),
                    this.props.assetMaintenanceDialog.endDate.getOrElse(new Date()),
                  );
                }}
              />
              <FormText
                color="danger"
                hidden={this.props.assetMaintenanceDialog.startDate.map((startDate) => moment().isSameOrAfter(startDate)).getOrElse(false)}
              >
                Start date must be before now!
              </FormText>
            </FormGroup>

            <FormGroup id="maintenance-end-date">
              <Label>End</Label>
              <Datetime
                closeOnSelect
                initialValue={this.props.assetMaintenanceDialog.endDate.getOrElse(new Date())}
                displayTimeZone={timeZoneRef}
                dateFormat={DATE_FORMAT_DEFAULT}
                timeFormat={TIME_FORMAT_DEFAULT}
                onChange={(date) => {
                  this.props.updateAssetMaintenanceDialogDateRange(
                    this.props.assetMaintenanceDialog.startDate.getOrElse(new Date()),
                    moment(date).toDate(),
                  );
                }}
              />
              <FormText
                color="danger"
                hidden={this.props.assetMaintenanceDialog.endDate.map((endDate) => moment().isSameOrBefore(endDate)).getOrElse(false)}
              >
                End date must be after now!
              </FormText>
            </FormGroup>
          </Form>

          {doesTargetExist(`maintenance-start-date`) && (
            <UncontrolledTooltip target={`maintenance-start-date`}>Site local {timeZoneRef} time</UncontrolledTooltip>
          )}
          {doesTargetExist(`maintenance-end-date`) && (
            <UncontrolledTooltip target={`maintenance-end-date`}>Site local {timeZoneRef} time</UncontrolledTooltip>
          )}
        </ModalBody>
        <ModalFooter>
          <FormText color="warning" hidden={!isAlertAcknowledge}>
            Scheduling for maintenance period will also acknowledge the Alert.{' '}
            <strong>{this.props.assetMaintenanceDialog.alert.map((a) => a.source).getOrElse('')}</strong>
          </FormText>

          <Button
            className="alert-notifications-warning-button"
            data-testid="alert-notifications-warning-button"
            color="warning"
            onClick={(date) => {
              this.props.updateAssetMaintenance(
                this.props.assetMaintenanceDialog.asset.some().id,
                this.props.assetMaintenanceDialog.asset.some().maintenance[0].id,
                moment(new Date()).toDate(),
              );
            }}
            disabled={this.props.assetMaintenanceDialog.progressState.exists((p) => p.isInProgress)}
          >
            Stop Maintenance
          </Button>

          <Button
            className="date-range-filter-save"
            color="primary"
            onClick={this.handleSave.bind(this)}
            disabled={this.props.assetMaintenanceDialog.progressState.exists((p) => p.isInProgress) || !this.isValid.bind(this)()}
          >
            <Spinner size="sm" hidden={!this.props.assetMaintenanceDialog.progressState.exists((p) => p.isInProgress)} />{' '}
            {isAlertAcknowledge ? 'Schedule & Ack' : isMaintenanceEdit ? 'Update' : ' Schedule'}
          </Button>
          <Button
            className="alert-notifications-cancel-button"
            data-testid="alert-notifications-modal-cancel-button"
            color="secondary"
            onClick={this.handleCancel.bind(this)}
            disabled={this.props.assetMaintenanceDialog.progressState.exists((p) => p.isInProgress)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      updateAssetMaintenance,
      scheduleAssetMaintenance,
      updateAssetMaintenanceDialogDateRange,
      closeAssetMaintenanceDialog,
      ackNotification,
      ackAndPutInMaintByAst,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  assetMaintenanceDialog: state.assetMaintenanceDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
