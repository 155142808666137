import { Dispatch } from 'redux';

// import { State } from '../../../reducers';
// import { eventListingFilterToEventFilter, EventsListingState, fetchEvents } from '../../events';
// import { fetchReports, ReportsListingState } from '../../reports';
// import { reportListFilterSerialize } from '../../reports/data/serdes';
// import { fetchTemplates, templateListingFilterToEventFilter, TemplatesListingState } from '../../templates';
import { navBarToggle, navBarToggleDropDown, navBarUpdateTitle, routesSetActive, sideBarToggleIsOpen } from '../redux/actions';
import { RouteName } from '../routes';

/**
 * Routes related functionality
 */
export const setActiveRoute =
  (name: RouteName) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(routesSetActive(name));
    dispatch(navBarUpdateTitle(name));
    // const state: State = getState();
    // if (name === RouteName.EVENTS) {
    //   const dsFilter = eventListingFilterToEventFilter(state.eventsListing.filter.getOrElse({}));
    //   if (!dsFilter.from && !dsFilter.to && !dsFilter.name && !dsFilter.companyId) {
    //     fetchEvents()(dispatch, getState);
    //   }
    // }
    // if (name === RouteName.TEMPLATES) {
    //   const templateFilter = templateListingFilterToEventFilter(state.templatesListing.filter);
    //   if (!templateFilter.from && !templateFilter.to && !templateFilter.name && !templateFilter.companyId) {
    //     fetchTemplates()(dispatch, getState);
    //   }
    // }
    // if (name === RouteName.REPORTS) {
    //   const filter = reportListFilterSerialize(state.reportsListing.filter.getOrElse({}));
    //   if (!filter.from && !filter.to && !filter.name && !filter.companyId && !filter.eventName && !filter.templateName) {
    //     fetchReports()(dispatch, getState);
    //   }
    // }
  };

/**
 * Navigation bar routes
 */
export const toggleSideBarIsOpen =
  (isOpen?: boolean) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(sideBarToggleIsOpen(isOpen));
  };

export const setNavBarTitle =
  (title: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(navBarUpdateTitle(title));
  };

export const toggleNavBarDropDown =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(navBarToggleDropDown());
  };

export const toggleNavBar =
  (isOpen?: boolean) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(navBarToggle(isOpen));
  };
