import { Alert, WorkLog } from '@serverfarm/nocd-commons';
import { Maybe, None, Some } from 'monet';

import { Action, ErrorAction } from '../../../redux/actions';
import { ListSort, AlertWorkLogFilter } from '../../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_WORK_LOG = 'REQUEST_WORK_LOG',
  RECEIVE_WORK_LOG = 'RECEIVE_WORK_LOG',
  RECEIVE_WORK_LOG_ERROR = 'RECEIVE_WORK_LOG_ERROR',

  OPEN_ALERT_WORK_LOG = 'OPEN_ALERT_WORK_LOG',
  CLOSE_ALERT_WORK_LOG = 'CLOSE_ALERT_WORK_LOG',

  UPDATE_ALERT_WORK_LOG_FILTER = 'UPDATE_ALERT_WORK_LOG_FILTER',
  UPDATE_ALERT_WORK_LOG_SORT = 'UPDATE_ALERT_WORK_LOG_SORT',

  EDIT_WORK_LOG = 'EDIT_WORK_LOG',
  EDIT_WORK_LOG_IS_SITE_AWARE = 'EDIT_WORK_LOG_IS_SITE_AWARE',
  REQUEST_WORK_LOG_ADD = 'REQUEST_WORK_LOG_ADD',
  RECEIVE_WORK_LOG_ADD = 'RECEIVE_WORK_LOG_ADD',
  RECEIVE_WORK_LOG_ADD_ERROR = 'RECEIVE_WORK_LOG_ADD_ERROR',

  RESET_WORK_LOG = 'RESET_WORK_LOG',
}

interface AlertWorkLogRequestAction extends Action<ActionType> {
  filter: Maybe<AlertWorkLogFilter>;
  sizePerPage: number;
  sort: Maybe<ListSort>;
}

interface AlertWorkLogReceiveAction extends Action<ActionType> {
  workLogs: WorkLog[];
  page: number;
  total: number;
}

type AlertWorkLogReceiveErrorAction = ErrorAction<ActionType>;

interface AlertWorkLogToggleAction extends Action<ActionType> {
  alert: Maybe<Alert>;
}

interface AlertWorkLogFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<AlertWorkLogFilter>;
  page: number;
  sizePerPage: number;
}

interface AlertWorkLogSortUpdateAction extends Action<ActionType> {
  sort: Maybe<ListSort>;
}

interface AlertWorkLogEditAction extends Action<ActionType> {
  alert: Maybe<Alert>;
  workLog: Maybe<string>;
}

interface AlertWorkLogIsSiteAwareEditAction extends Action<ActionType> {
  alert: Maybe<Alert>;
  isSiteAware: Maybe<boolean>;
}

interface AlertWorkLogAddRequestAction extends Action<ActionType> {
  workLog: string;
  isSiteAware?: boolean;
}

type AlertWorkLogAddReceiveAction = Action<ActionType>;

type AlertWorkLogAddReceiveErrorAction = ErrorAction<ActionType>;

export type AlertWorkLogAction = AlertWorkLogRequestAction &
  AlertWorkLogReceiveAction &
  AlertWorkLogReceiveErrorAction &
  AlertWorkLogToggleAction &
  AlertWorkLogFilterUpdateAction &
  AlertWorkLogSortUpdateAction &
  AlertWorkLogEditAction &
  AlertWorkLogIsSiteAwareEditAction &
  AlertWorkLogAddRequestAction &
  AlertWorkLogAddReceiveAction &
  AlertWorkLogAddReceiveErrorAction;

export const alertWorkLogRequest = (filter: Maybe<AlertWorkLogFilter>, sizePerPage: number, sort: Maybe<ListSort>): AlertWorkLogRequestAction => ({
  type: ActionType.REQUEST_WORK_LOG,
  filter,
  sizePerPage,
  sort,
});

export const alertWorkLogReceive = (workLogs: WorkLog[], page: number, total: number): AlertWorkLogReceiveAction => ({
  type: ActionType.RECEIVE_WORK_LOG,
  workLogs,
  page,
  total,
});

export const alertWorkLogReceiveError = (error: Error): AlertWorkLogReceiveErrorAction => ({
  type: ActionType.RECEIVE_WORK_LOG_ERROR,
  error,
});

export const alertWorkLogOpen = (alert: Alert): AlertWorkLogToggleAction => ({
  type: ActionType.OPEN_ALERT_WORK_LOG,
  alert: Some(alert),
});

export const alertWorkLogClose = (): AlertWorkLogToggleAction => ({
  type: ActionType.CLOSE_ALERT_WORK_LOG,
  alert: None(),
});

export const alertWorkLogFilterUpdate = (filter: Maybe<AlertWorkLogFilter>, page: number, sizePerPage: number): AlertWorkLogFilterUpdateAction => ({
  type: ActionType.UPDATE_ALERT_WORK_LOG_FILTER,
  filter,
  page,
  sizePerPage,
});

export const alertWorkLogSortUpdate = (sort: Maybe<ListSort>): AlertWorkLogSortUpdateAction => ({
  type: ActionType.UPDATE_ALERT_WORK_LOG_SORT,
  sort,
});

export const alertWorkLogEdit = (alert: Maybe<Alert>, workLog: Maybe<string>): AlertWorkLogEditAction => ({
  type: ActionType.EDIT_WORK_LOG,
  alert,
  workLog,
});

export const alertWorkLogIsSiteAwareEdit = (alert: Maybe<Alert>, isSiteAware: Maybe<boolean>): AlertWorkLogIsSiteAwareEditAction => ({
  type: ActionType.EDIT_WORK_LOG_IS_SITE_AWARE,
  alert,
  isSiteAware,
});

export const alertWorkLogAddRequest = (workLog: string, isSiteAware?: boolean): AlertWorkLogAddRequestAction => ({
  type: ActionType.REQUEST_WORK_LOG_ADD,
  workLog,
  isSiteAware,
});

export const alertWorkLogAddReceive = (): AlertWorkLogAddReceiveAction => ({
  type: ActionType.RECEIVE_WORK_LOG_ADD,
});

export const alertWorkLogAddReceiveError = (error: Error): AlertWorkLogAddReceiveErrorAction => ({
  type: ActionType.RECEIVE_WORK_LOG_ADD_ERROR,
  error,
});
