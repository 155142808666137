import { Maybe, Some } from 'monet';

import { Action, ErrorAction } from '../../redux/actions';
import { Asset, AssetsListFilter, AssetsListSort } from '../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_ASSETS = 'REQUEST_ASSETS',
  RECEIVE_ASSETS = 'RECEIVE_ASSETS',
  RECEIVE_EXPORTED_ASSETS = 'RECEIVE_EXPORTED_ASSETS',
  RECEIVE_ASSETS_ERROR = 'RECEIVE_ASSETS_ERROR',

  REQUEST_ASSET_MAPPING_INSERT = 'REQUEST_ASSET_MAPPING_INSERT',
  RECEIVE_ASSET_MAPPING_INSERT_SUCCESS = 'RECEIVE_ASSET_MAPPING_INSERT_SUCCESS',
  RECEIVE_ASSET_MAPPING_INSERT_ERROR = 'RECEIVE_ASSET_MAPPING_INSERT_ERROR',

  UPDATE_ASSETS_LISTING_HIDDEN_COLUMNS = 'UPDATE_ASSETS_LISTING_HIDDEN_COLUMNS',
  UPDATE_ASSETS_LISTING_FILTER = 'UPDATE_ASSETS_LISTING_FILTER',
  UPDATE_ASSETS_SORT_LIST = 'UPDATE_ASSETS_SORT_LIST',

  REQUEST_ASSET_MAPPING_DELETE = 'REQUEST_ASSET_MAPPING_DELETE',
  RECEIVE_ASSET_MAPPING_DELETE_SUCCESS = 'RECEIVE_ASSET_MAPPING_DELETE_SUCCESS',
  RECEIVE_ASSET_MAPPING_DELETE_ERROR = 'RECEIVE_ASSET_MAPPING_DELETE_ERROR',

  RESET_ASSETS = 'RESET_ASSETS',
  RESET_EXPORTED_ALERTS = 'RESET_EXPORTED_ALERTS',
}

interface AssetsRequestAction extends Action<ActionType> {
  filter: Maybe<AssetsListFilter>;
  sizePerPage: number;
  sort: Maybe<AssetsListSort>;
}

interface AssetsReceiveAction extends Action<ActionType> {
  assets: Asset[];
  page: number;
  total: number;
}

interface ExportedAssetsReceiveAction extends Action<ActionType> {
  exportedAssets: Asset[];
}

type AssetsReceiveErrorAction = ErrorAction<ActionType>;

interface AssetsMappingInsertRequestAction extends Action<ActionType> {
  mappingString: string;
}

type AssetsMappingInsertResultAction = Action<ActionType>;

type AssetsMappingInsertErrorAction = ErrorAction<ActionType>;

interface AssetListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<AssetsListFilter>;
  page: number;
  sizePerPage: number;
}

interface AssetListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<AssetsListSort>;
}

interface AssetListingHiddenColumnsUpdateAction extends Action<ActionType> {
  hiddenColumns: string[];
}

type AssetResetAction = Action<ActionType>;

interface AssetMaintenanceToggleAction extends Action<ActionType> {
  asset: Maybe<Asset>;
}

export type AssetsFetchAction = AssetsRequestAction &
  AssetsReceiveAction &
  ExportedAssetsReceiveAction &
  AssetListingHiddenColumnsUpdateAction &
  AssetsReceiveErrorAction &
  AssetsMappingInsertRequestAction &
  AssetsMappingInsertResultAction &
  AssetsMappingInsertErrorAction &
  AssetListingFilterUpdateAction &
  AssetListingSortUpdateAction &
  AssetResetAction &
  AssetMaintenanceToggleAction;

interface AssetMappingDeleteRequestAction extends Action<ActionType> {
  assetId: Maybe<number>;
  mappingId: Maybe<number>;
}

type AssetMappingDeleteSuccessAction = Action<ActionType>;

type AssetsMappingDeleteErrorAction = ErrorAction<ActionType>;

export type AssetMappingDeleteAction = AssetMappingDeleteRequestAction & AssetMappingDeleteSuccessAction & AssetsMappingDeleteErrorAction;

export const assetsRequest = (filter: Maybe<AssetsListFilter>, sizePerPage: number, sort: Maybe<AssetsListSort>): AssetsRequestAction => ({
  type: ActionType.REQUEST_ASSETS,
  filter,
  sizePerPage,
  sort,
});

export const assetsReceive = (assets: Asset[], page: number, total: number): AssetsReceiveAction => ({
  type: ActionType.RECEIVE_ASSETS,
  assets,
  page,
  total,
});

export const exportedAssetsReceive = (exportedAssets: Asset[]): ExportedAssetsReceiveAction => ({
  type: ActionType.RECEIVE_EXPORTED_ASSETS,
  exportedAssets,
});

export const assetsReceiveError = (error: Error): AssetsReceiveErrorAction => ({
  type: ActionType.RECEIVE_ASSETS_ERROR,
  error,
});

export const assetsRequestMappingInsert = (mappingString: string): AssetsMappingInsertRequestAction => ({
  type: ActionType.REQUEST_ASSET_MAPPING_INSERT,
  mappingString,
});

export const assetsReceiveMappingInsertSuccess = (): AssetsMappingInsertResultAction => ({
  type: ActionType.RECEIVE_ASSET_MAPPING_INSERT_SUCCESS,
});

export const assetsReceiveMappingInsertError = (error: Error): AssetsMappingInsertErrorAction => ({
  type: ActionType.RECEIVE_ASSET_MAPPING_INSERT_ERROR,
  error,
});

export const assetListingFilterUpdate = (filter: Maybe<AssetsListFilter>, page: number, sizePerPage: number): AssetListingFilterUpdateAction => ({
  type: ActionType.UPDATE_ASSETS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const assetListingHiddenColumnsUpdate = (hiddenColumns: string[]): AssetListingHiddenColumnsUpdateAction => ({
  type: ActionType.UPDATE_ASSETS_LISTING_HIDDEN_COLUMNS,
  hiddenColumns,
});

export const assetListingSortUpdate = (sort: Maybe<AssetsListSort>): AssetListingSortUpdateAction => ({
  type: ActionType.UPDATE_ASSETS_SORT_LIST,
  sort,
});

export const assetsReset = (): AssetResetAction => ({
  type: ActionType.RESET_ASSETS,
});

export const exportedAssetsReset = (): AssetResetAction => ({
  type: ActionType.RESET_EXPORTED_ALERTS,
});

export const assetMappingRequestDelete = (assetId: Maybe<number>, mappingId: Maybe<number>): AssetMappingDeleteRequestAction => ({
  type: ActionType.REQUEST_ASSET_MAPPING_DELETE,
  assetId,
  mappingId,
});

export const assetMappingReceiveMappingDeleteSuccess = (): AssetMappingDeleteSuccessAction => ({
  type: ActionType.RECEIVE_ASSET_MAPPING_DELETE_SUCCESS,
});

export const assetMappingReceiveMappingDeleteError = (error: Error): AssetsMappingDeleteErrorAction => ({
  type: ActionType.RECEIVE_ASSET_MAPPING_DELETE_SUCCESS,
  error,
});
