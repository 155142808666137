import { Maybe, Some } from 'monet';
import * as React from 'react';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { Button, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchSites, fetchSitesGroups } from '../sites';

import { editMonitoringSystem, deleteMonitoringSystem, fetchMonitoringSystems } from './data/monitoring-systems/actions';
import MonitoringSystemEditor from './MonitoringSystemEditor';
import MonitoringSystemIncidentInformationModal from './MonitoringSystemIncidentInformationModal';
import MonitoringSystemsList from './MonitoringSystemsList';

class Component extends React.Component<Props, unknown> {
  refresh() {
    const filter = this.props.monitoringSystemsListing.filter.map((filter: any) => ({
      ...filter,
    }));
    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchMonitoringSystems(
      filter,
      this.props.monitoringSystemsListing.page,
      this.props.monitoringSystemsListing.sizePerPage,
      this.props.monitoringSystemsListing.sort,
    );
  }

  public render() {
    return (
      <div className="content">
        <Button
          id="templates-list-create-button"
          data-testid="templates-list-create-button"
          color="primary"
          size="sm"
          disabled={this.props.monitoringSystemsListing.isInProgress}
          onClick={() => this.props.editMonitoringSystem(Maybe.None())}
        >
          Create
        </Button>
        <Button
          data-testid="rawnotifications-list-reload-button"
          id="rawnotifications-list-reload-button"
          color="secondary"
          size="sm"
          onClick={this.refresh.bind(this)}
        >
          <Spinner size="sm" hidden={!this.props.monitoringSystemsListing.isInProgress} /> Refresh
        </Button>
        <MonitoringSystemsList showTopManageHiddenCols={true} {...this.props} />
        <MonitoringSystemIncidentInformationModal {...this.props} />
        <MonitoringSystemEditor />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchSitesGroups,
      fetchSites,
      editMonitoringSystem,
      deleteMonitoringSystem,
      fetchMonitoringSystems,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  monitoringSystemsListing: state.monitoringSystemsListing,
  monitoringSystemDeleting: state.monitoringSystemDeleting,
  monitoringSystemEditing: state.monitoringSystemEditing,
  monitoringSystemSaving: state.monitoringSystemSaving,
  monitoringSystemIncidentInformationDialog: state.monitoringSystemIncidentInformationDialog,
  companiesListing: state.companiesListing,
  sitesGroupsListing: state.sitesGroupsListing,
  sitesListing: state.sitesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
