import { Action, ErrorAction } from '../../../redux/actions';
import { NotificationGroup } from '../../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_NOTIFICATION_GROUPS = 'REQUEST_NOTIFICATION_GROUPS',
  RECEIVE_NOTIFICATION_GROUPS = 'RECEIVE_NOTIFICATION_GROUPS',
  RECEIVE_NOTIFICATION_GROUPS_ERROR = 'RECEIVE_NOTIFICATION_GROUPS_ERROR',

  RESET_NOTIFICATION_GROUPS = 'RESET_NOTIFICATION_GROUPS',
}

type NotificationGroupsRequestAction = Action<ActionType>;

interface NotificationGroupsReceiveAction extends Action<ActionType> {
  notificationGroups: NotificationGroup[];
}

type NotificationGroupsReceiveErrorAction = ErrorAction<ActionType>;

type NotificationGroupsResetAction = Action<ActionType>;

export type NotificationGroupsFetchAction = NotificationGroupsRequestAction &
  NotificationGroupsReceiveAction &
  NotificationGroupsReceiveErrorAction &
  NotificationGroupsResetAction;

export const notificationGroupsRequest = (): NotificationGroupsRequestAction => ({
  type: ActionType.REQUEST_NOTIFICATION_GROUPS,
});

export const notificationGroupsReceive = (notificationGroups: NotificationGroup[]): NotificationGroupsReceiveAction => ({
  type: ActionType.RECEIVE_NOTIFICATION_GROUPS,
  notificationGroups,
});

export const notificationGroupsReceiveError = (error: Error): NotificationGroupsReceiveErrorAction => ({
  type: ActionType.RECEIVE_NOTIFICATION_GROUPS_ERROR,
  error,
});

export const notificationGroupsReset = (): NotificationGroupsResetAction => ({
  type: ActionType.RESET_NOTIFICATION_GROUPS,
});
