import axios from 'axios';
import tokenProvider from 'axios-token-interceptor';
import logdown from 'logdown';
import RSA from 'react-simple-auth';

import { incommand as incommandProvider } from '../auth/providers';
import { showErrorNotification } from '../components/notification';
import { ServicesVersionState, ServicesAvailable, makeServiceVersionKeyName } from '../components/user';
import { userLoggedOut } from '../components/user/redux/actions';
import config from '../config';

const logger = logdown('axios:interceptor');
const instance = axios.create({});

export const setupTokenProviderInterceptor = (): void => {
  // Configure the provider with the necessary options.
  const options = {
    getToken: () => {
      return new Promise<string>((resolve, reject) => {
        try {
          resolve(RSA.getAccessToken(incommandProvider, config.oauth.client.url));
        } catch (err) {
          reject(err);
        }
      });
    },
  };
  // @ts-ignore
  instance.interceptors.request.use(tokenProvider(options));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupVersionCheckInterceptor = (store: any): void => {
  instance.interceptors.request.use((requestConfig) => {
    const state = store.getState();
    const servicesVersion = state.servicesVersion as ServicesVersionState;
    let version: string | null = null;
    const isCrawfishRequest = requestConfig.url && requestConfig.url.includes(config.services.crawfish.api.url);
    const isWhiteAntRequest = requestConfig.url && requestConfig.url.includes(config.services.auth.api.url);
    logger.log(
      `Check=>${requestConfig.url} against=${config.services.crawfish.api.url} or=${config.services.auth.api.url} isCrawfish=${isCrawfishRequest} isWhiteAnt=${isWhiteAntRequest}`,
    );
    logger.log(`services versions ${JSON.stringify(servicesVersion)}`);
    if (isCrawfishRequest) {
      version = localStorage.getItem(makeServiceVersionKeyName(ServicesAvailable.CRAWFISH));
    } else if (isWhiteAntRequest) {
      version = localStorage.getItem(makeServiceVersionKeyName(ServicesAvailable.WHITE_ANT));
    }
    if (version) {
      requestConfig.headers = { 'X-Service-Version': version };
    }
    return requestConfig;
  });

  instance.interceptors.response.use(undefined, (error) => {
    if (error && error.response && [401, 403].some((code) => error.response.status === code)) {
      store.dispatch(showErrorNotification(error));
      store.dispatch(userLoggedOut());
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  });
};

export default instance;
