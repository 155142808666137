// @ts-ignore: No declarations available
import logdown from 'logdown';
// @ts-ignore: No declarations available
import { Maybe } from 'monet';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Badge, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import AlertStakeholderNotification from './AlertStakeholderNotification';
import { sendStakeholderNotification, updateStakeholderNotification, closeAlertStakeholderNotification, setSelectedAlert } from './data/alerts';

const logger = logdown('component:AlertStakeholderNotificationModal');

class Component extends React.Component<Props, unknown> {
  reset() {
    // this.props.updateAlertNotificationListingFilter(Maybe.none(), 1, this.props.alertsListing.sizePerPage);
    // this.props.updateAlertNotificationListingSort(Maybe.none());
    // this.props.fetchAlertNotifications(
    //   this.props.alertNotificationsListing.alert.some(),
    //   Maybe.none(),
    //   Maybe.none(),
    //   1,
    //   this.props.alertNotificationsListing.sizePerPage,
    // );
  }
  public render() {
    logger.log(`Alert ${this.props.stakeHolderNotificationDialog.alert.isSome()}`);
    return (
      <Modal
        id="stakeholder-notifications-modal"
        data-testid="stakeholder-notifications-modal"
        size="lg"
        // style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.stakeHolderNotificationDialog.isOpen}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="stakeholder-notifications-modal-header">
          <EllipsisText
            className="stakeholder-notifications-header"
            text={`Stakeholder Notification: ${this.props.stakeHolderNotificationDialog.alert.map((a: any) => a.source).getOrElse('N/A')}`}
            length={75}
          />
        </ModalHeader>
        <ModalBody>
          <AlertStakeholderNotification {...this.props} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="stakeholder-notifications-send-button"
            data-testid="stakeholder-notifications-modal-send-button"
            color="primary"
            onClick={this.send.bind(this)}
            disabled={this.props.stakeHolderNotificationDialog.progressState.exists((p) => p.isInProgress)}
          >
            <Spinner size="sm" hidden={!this.props.stakeHolderNotificationDialog.progressState.exists((p) => p.isInProgress)} /> Send
          </Button>
          <Button
            className="stakeholder-notifications-cancel-button"
            data-testid="stakeholder-notifications-modal-cancel-button"
            color="secondary"
            onClick={this.cancel.bind(this)}
            disabled={this.props.stakeHolderNotificationDialog.progressState.exists((p) => p.isInProgress)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected send(e: any) {
    e.preventDefault();
    // if (this.props.stakeHolderNotificationSending.workLogs.isSome()) {
    //   return this.props.closeAlertStakeholderNotification();
    // }
    this.props.sendStakeholderNotification();
  }

  protected cancel(e: any) {
    e.preventDefault();
    // if (this.props.stakeHolderNotificationSending.workLogs.isSome()) {
    //   return this.props.closeAlertStakeholderNotification();
    // }
    this.props.setSelectedAlert('');
    this.props.closeAlertStakeholderNotification();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      sendStakeholderNotification,
      updateStakeholderNotification,
      closeAlertStakeholderNotification,
      setSelectedAlert,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  notificationGroupsListing: state.notificationGroupsListing,
  stakeHolderNotificationDialog: state.stakeHolderNotificationDialog,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
