import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { NOTIFICATION_GROUPS_INITIAL_STATE } from '../constants';
import { NotificationGroupsListingState } from '../entities';

import { ActionType, NotificationGroupsFetchAction } from './actions';

export const notificationGroupsListingReducer: Reducer<NotificationGroupsListingState, NotificationGroupsFetchAction> = (
  state = NOTIFICATION_GROUPS_INITIAL_STATE.notificationGroupsListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_NOTIFICATION_GROUPS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_NOTIFICATION_GROUPS:
      return {
        ...state,
        isInProgress: false,
        notificationGroups: Maybe.Some(action.notificationGroups),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_NOTIFICATION_GROUPS_ERROR:
      return {
        ...state,
        isInProgress: false,
        notificationGroups: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.RESET_NOTIFICATION_GROUPS:
      return NOTIFICATION_GROUPS_INITIAL_STATE.notificationGroupsListingState;
    default:
      return state;
  }
};
