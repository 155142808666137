import moment from 'moment';
import { Maybe, None } from 'monet';
import { Reducer } from 'redux';

import { RAW_NOTIFICATION_INITIAL_STATE } from '../constants';
import { MessageInformationDialogState, RawNotificationsListingState } from '../entities';

import { ActionType, RawNotificationsFetchAction } from './actions';

export const rawNotificationsListingReducer: Reducer<RawNotificationsListingState, RawNotificationsFetchAction> = (
  state = RAW_NOTIFICATION_INITIAL_STATE.rawNotificationsListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_RAW_NOTIFICATIONS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_RAW_NOTIFICATIONS:
      return {
        ...state,
        isInProgress: false,
        rawNotifications: Maybe.some(
          action.rawNotifications.map((rawNotification, idx) => {
            const currentRawNotifications = state.rawNotifications.orJust([]);
            const exists = currentRawNotifications.some((a) => {
              const found =
                a.id === rawNotification.id &&
                // a.provider == rawNotification.provider &&
                // a.message == rawNotification.message &&
                // a.recipient == rawNotification.recipient &&
                // a.sender === rawNotification.sender && // moment(a.timestamp).diff(moment(alert.timestamp), 's') === 0 &&
                moment(a.updatedAt).diff(moment(rawNotification.updatedAt), 's') === 0;
              return found;
            });
            // console.log('CURRENT', exists, currentAlerts, alert);
            return { ...rawNotification, isNew: !exists };
          }),
        ),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.RECEIVE_EXPORTED_RAW_NOTIFICATIONS:
      return {
        ...state,
        exportedRawNotifications: Maybe.some(
          action.exportedRawNotifications.map((rawNotification) => {
            return { ...rawNotification };
          }),
        ),
      };
    case ActionType.RECEIVE_RAW_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isInProgress: false,
        alerts: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case ActionType.UPDATE_RAW_NOTIFICATIONS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_RAW_NOTIFICATIONS_LISTING_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.hiddenColumns,
      };
    case ActionType.RESET_ALL_RAW_NOTIFICATION_GLOW:
      return {
        ...state,
        rawNotifications: Maybe.fromUndefined(
          state.rawNotifications
            .map((alerts) =>
              alerts.map((a) => ({
                ...a,
                isNew: false,
              })),
            )
            .orUndefined(),
        ),
      };
    case ActionType.RESET_RAW_NOTIFICATIONS:
      return RAW_NOTIFICATION_INITIAL_STATE.rawNotificationsListingState;
    case ActionType.RESET_EXPORTED_RAW_NOTIFICATIONS:
      return {
        ...state,
        exportedRawNotifications: None(),
      };
    default:
      return state;
  }
};

export const messageInformationDialogReducer: Reducer<MessageInformationDialogState, RawNotificationsFetchAction> = (
  state = RAW_NOTIFICATION_INITIAL_STATE.messageInformationDialogState,
  action,
) => {
  switch (action.type) {
    case ActionType.OPEN_MESSAGE_INFORMATION:
      return {
        ...state,
        isOpen: true,
        rawNotification: action.rawNotification,
      };

    case ActionType.CLOSE_MESSAGE_INFORMATION:
      return {
        ...RAW_NOTIFICATION_INITIAL_STATE.messageInformationDialogState,
      };
    default:
      return state;
  }
};
