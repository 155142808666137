import { AlertNotification, Company } from '@serverfarm/nocd-commons';
import { Maybe, None, Some } from 'monet';

import { NotificationGroup } from '../..';
import { Action, ErrorAction, InProgressState } from '../../../redux/actions';
import { UserProfile } from '../../../user';
import { Alert, ListSort, AlertsListFilter } from '../../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_ALERTS = 'REQUEST_ALERTS',
  RECEIVE_ALERTS = 'RECEIVE_ALERTS',
  RECEIVE_EXPORTED_ALERTS = 'RECEIVE_EXPORTED_ALERTS',
  RECEIVE_ALERTS_ERROR = 'RECEIVE_ALERTS_ERROR',

  UPDATE_ALERTS_LISTING_FILTER = 'UPDATE_ALERTS_LISTING_FILTER',
  UPDATE_ALERTS_LISTING_HIDDEN_COLUMNS = 'UPDATE_ALERTS_LISTING_HIDDEN_COLUMNS',
  UPDATE_ALERTS_SORT_LIST = 'UPDATE_ALERTS_SORT_LIST',

  RECEIVE_NEW_NOTIFICATION = 'RECEIVE_NEW_NOTIFICATION',
  RESET_ALERT_GLOW = 'RESET_ALERT_GLOW',
  RESET_ALERTS = 'RESET_ALERTS',
  RESET_EXPORTED_ALERTS = 'RESET_EXPORTED_ALERTS',
  RESET_ALL_ALERT_GLOW = 'RESET_ALL_ALERT_GLOW',

  OPEN_SEND_STAKEHOLDER_NOTIFICATION = 'OPEN_SEND_STAKEHOLDER_NOTIFICATION',
  UPDATE_STAKEHOLDER_NOTIFICATION = 'UPDATE_STAKEHOLDER_NOTIFICATION',
  UPDATE_SEND_STAKEHOLDER_NOTIFICATION_PROGRESS_STATE = 'UPDATE_SEND_STAKEHOLDER_NOTIFICATION_PROGRESS_STATE',
  CLOSE_SEND_STAKEHOLDER_NOTIFICATION = 'CLOSE_SEND_STAKEHOLDER_NOTIFICATION',

  OPEN_SEND_NOCD_IN_COMMAND_REQUEST = 'OPEN_SEND_NOCD_IN_COMMAND_REQUEST',
  REQUEST_SEND_NOCD_IN_COMMAND_PROGRESS_STATE = 'REQUEST_SEND_NOCD_IN_COMMAND_PROGRESS_STATE',
  UPDATE_NOCD_IN_COMMAND_REQUEST = 'UPDATE_NOCD_IN_COMMAND_REQUEST',
  CLOSE_SEND_NOCD_IN_COMMAND_REQUEST = 'CLOSE_SEND_NOCD_IN_COMMAND_REQUEST',

  REQUEST_ALERTS_TRACKING_TOTAL_COUNT = 'REQUEST_ALERTS_TRACKING_TOTAL_COUNT',
  RECEIVE_ALERTS_TRACKING_TOTAL_COUNT = 'RECEIVE_ALERTS_TRACKING_TOTAL_COUNT',
  RECEIVE_ALERTS_TRACKING_TOTAL_COUNT_ERROR = 'RECEIVE_ALERTS_TRACKING_TOTAL_COUNT_ERROR',

  OPEN_ALERT_SOURCE_INFORMATION = 'OPEN_ALERT_SOURCE_INFORMATION',
  CLOSE_ALERT_SOURCE_INFORMATION = 'CLOSE_ALERT_SOURCE_INFORMATION',

  OPEN_ASSETS_IN_MAINTENANCE = 'OPEN_ASSETS_IN_MAINTENANCE',
  CLOSE_ASSETS_IN_MAINTENANCE = 'CLOSE_ASSETS_IN_MAINTENANCE',

  OPEN_MONITORING_SYSTEMS = 'OPEN_MONITORING_SYSTEMS',
  CLOSE_MONITORING_SYSTEMS = 'CLOSE_MONITORING_SYSTEMS',

  ALERT_SELECT = 'ALERT_SELECT',
}

interface AlertsRequestAction extends Action<ActionType> {
  filter: Maybe<AlertsListFilter>;
  sizePerPage: number;
  sort: Maybe<ListSort>;
}

type AssetsInMaintenanceToggleAction = Action<ActionType>;

interface AlertsReceiveAction extends Action<ActionType> {
  alerts: Alert[];
  page: number;
  total: number;
}
interface ExportedAlertsReceiveAction extends Action<ActionType> {
  exportedAlerts: Alert[];
}

type AlertsReceiveErrorAction = ErrorAction<ActionType>;

interface AlertListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<AlertsListFilter>;
  page: number;
  sizePerPage: number;
}

interface AlertListingHiddenColumnsUpdateAction extends Action<ActionType> {
  hiddenColumns: string[];
}

interface AlertListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<ListSort>;
}

interface AlertSelectAction extends Action<ActionType> {
  selectedAlert: string;
}

type DataSourceResetAction = Action<ActionType>;

interface AlertListingUpdateAlertsAction extends Action<ActionType> {
  notification: Maybe<AlertNotification>;
}
type AlertListingResetGlowAction = Action<ActionType>;

export const assetsInMaintenanceOpen = (): AssetsInMaintenanceToggleAction => ({
  type: ActionType.OPEN_ASSETS_IN_MAINTENANCE,
});
export const assetsInMaintenanceClose = (): AssetsInMaintenanceToggleAction => ({
  type: ActionType.CLOSE_ASSETS_IN_MAINTENANCE,
});

export const monitoringSystemsOpen = (): AssetsInMaintenanceToggleAction => ({
  type: ActionType.OPEN_MONITORING_SYSTEMS,
});
export const monitoringSystemsClose = (): AssetsInMaintenanceToggleAction => ({
  type: ActionType.CLOSE_MONITORING_SYSTEMS,
});
interface AlertStakeholderNotificationToggleAction extends Action<ActionType> {
  alert: Maybe<Alert>;
}

interface AlertSourceInformationToggleAction extends Action<ActionType> {
  alert: Maybe<Alert>;
}

interface SendStakeholderNotificationProgressStateUpdateAction extends Action<ActionType> {
  stakeholderNotificationProgressState: Maybe<InProgressState>;
}

interface StakeholderNotificationUpdateAction extends Action<ActionType> {
  alert: Maybe<Alert>;
  notificationGroup: Maybe<NotificationGroup>;
  subject: Maybe<string>;
  content: Maybe<string>;
}

interface AlertNocInCommandRequestToggleAction extends Action<ActionType> {
  alert: Maybe<Alert>;
}

interface SendNocInCommandRequestProgressStateUpdateAction extends Action<ActionType> {
  nocInCommandRequestProgressState: Maybe<InProgressState>;
}

type SendNocInCommandRequestReceiveAction = Action<ActionType>;

type SendNocInCommandRequestReceiveErrorAction = ErrorAction<ActionType>;

interface NocInCommandRequestUpdateAction extends Action<ActionType> {
  alert: Maybe<Alert>;
  assignee: Maybe<UserProfile>;
  alertDate: Maybe<Date>;
  acknowledgedDate: Maybe<Date>;
  notificationGroup: Maybe<NotificationGroup>;
  requestPriority: Maybe<string>;
  company: Maybe<Company>;
  notes: Maybe<string>;
}

interface AlertsTrackingTotalCountProgressStateUpdateAction extends Action<ActionType> {
  totalTrackedAlerts: Maybe<number>;
  alertsTrackingTotalCountUpdateProgressState: Maybe<InProgressState>;
}

type AlertResetAction = Action<ActionType>;

export type AlertsFetchAction = AlertsRequestAction &
  AlertsReceiveAction &
  ExportedAlertsReceiveAction &
  AlertsReceiveErrorAction &
  AlertListingFilterUpdateAction &
  AlertListingHiddenColumnsUpdateAction &
  DataSourceResetAction &
  AlertListingUpdateAlertsAction &
  AlertListingResetGlowAction &
  AlertStakeholderNotificationToggleAction &
  SendStakeholderNotificationProgressStateUpdateAction &
  AlertSourceInformationToggleAction &
  StakeholderNotificationUpdateAction &
  AlertNocInCommandRequestToggleAction &
  SendNocInCommandRequestProgressStateUpdateAction &
  SendNocInCommandRequestReceiveAction &
  SendNocInCommandRequestReceiveErrorAction &
  NocInCommandRequestUpdateAction &
  AlertsTrackingTotalCountProgressStateUpdateAction &
  AlertResetAction &
  AlertSelectAction;

export const alertsRequest = (filter: Maybe<AlertsListFilter>, sizePerPage: number, sort: Maybe<ListSort>): AlertsRequestAction => ({
  type: ActionType.REQUEST_ALERTS,
  filter,
  sizePerPage,
  sort,
});

export const alertsReceive = (alerts: Alert[], page: number, total: number): AlertsReceiveAction => ({
  type: ActionType.RECEIVE_ALERTS,
  alerts,
  page,
  total,
});

export const exportedAlertsReceive = (exportedAlerts: Alert[]): ExportedAlertsReceiveAction => ({
  type: ActionType.RECEIVE_EXPORTED_ALERTS,
  exportedAlerts,
});

export const alertsReceiveError = (error: Error): AlertsReceiveErrorAction => ({
  type: ActionType.RECEIVE_ALERTS_ERROR,
  error,
});

export const alertListingFilterUpdate = (filter: Maybe<AlertsListFilter>, page: number, sizePerPage: number): AlertListingFilterUpdateAction => ({
  type: ActionType.UPDATE_ALERTS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const alertListingHiddenColumnsUpdate = (hiddenColumns: string[]): AlertListingHiddenColumnsUpdateAction => ({
  type: ActionType.UPDATE_ALERTS_LISTING_HIDDEN_COLUMNS,
  hiddenColumns,
});

export const alertListingSortUpdate = (sort: Maybe<ListSort>): AlertListingSortUpdateAction => ({
  type: ActionType.UPDATE_ALERTS_SORT_LIST,
  sort,
});

export const alertsReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_ALERTS,
});

export const exportedAlertsReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_EXPORTED_ALERTS,
});

export const alertsReceiveNotification = (notification: AlertNotification): AlertListingUpdateAlertsAction => ({
  type: ActionType.RECEIVE_NEW_NOTIFICATION,
  notification: Some(notification),
});

export const alertSourceInformationOpen = (alert: Alert): AlertSourceInformationToggleAction => ({
  type: ActionType.OPEN_ALERT_SOURCE_INFORMATION,
  alert: Some(alert),
});

export const alertSourceInformationClose = (): AlertSourceInformationToggleAction => ({
  type: ActionType.CLOSE_ALERT_SOURCE_INFORMATION,
  alert: None(),
});

export const alertStakeholderNotificationOpen = (alert: Alert): AlertStakeholderNotificationToggleAction => ({
  type: ActionType.OPEN_SEND_STAKEHOLDER_NOTIFICATION,
  alert: Some(alert),
});

export const alertSelect = (selectedAlert: string): AlertSelectAction => ({
  type: ActionType.ALERT_SELECT,
  selectedAlert: selectedAlert,
});

export const stakeholderNotificationUpdate = (
  alert: Maybe<Alert>,
  notificationGroup: Maybe<NotificationGroup>,
  subject: Maybe<string>,
  content: Maybe<string>,
): StakeholderNotificationUpdateAction => ({
  type: ActionType.UPDATE_STAKEHOLDER_NOTIFICATION,
  alert,
  notificationGroup,
  subject,
  content,
});

export const sendStakeholderNotificationProgressStateUpdate = (
  progressState: Maybe<InProgressState>,
): SendStakeholderNotificationProgressStateUpdateAction => ({
  type: ActionType.UPDATE_SEND_STAKEHOLDER_NOTIFICATION_PROGRESS_STATE,
  stakeholderNotificationProgressState: progressState,
});

export const alertStakeholderNotificationClose = (): AlertStakeholderNotificationToggleAction => ({
  type: ActionType.CLOSE_SEND_STAKEHOLDER_NOTIFICATION,
  alert: None(),
});

export const alertNocInCommandRequestOpen = (alert: Alert): AlertNocInCommandRequestToggleAction => ({
  type: ActionType.OPEN_SEND_NOCD_IN_COMMAND_REQUEST,
  alert: Some(alert),
});

export const sendNocInCommandProgressStateUpdate = (progressState: Maybe<InProgressState>): SendNocInCommandRequestProgressStateUpdateAction => ({
  type: ActionType.REQUEST_SEND_NOCD_IN_COMMAND_PROGRESS_STATE,
  nocInCommandRequestProgressState: progressState,
});

export const nocInCommandRequestUpdate = (
  alert: Maybe<Alert>,
  assignee: Maybe<UserProfile>,
  alertDate: Maybe<Date>,
  acknowledgedDate: Maybe<Date>,
  notificationGroup: Maybe<NotificationGroup>,
  requestPriority: Maybe<string>,
  company: Maybe<Company>,
  notes: Maybe<string>,
): NocInCommandRequestUpdateAction => ({
  type: ActionType.UPDATE_NOCD_IN_COMMAND_REQUEST,
  alert,
  assignee,
  alertDate,
  acknowledgedDate,
  notificationGroup,
  requestPriority,
  company,
  notes,
});

export const alertNocInCommandRequestClose = (): AlertNocInCommandRequestToggleAction => ({
  type: ActionType.CLOSE_SEND_NOCD_IN_COMMAND_REQUEST,
  alert: None(),
});

export const alertResetGlow = (notification: AlertNotification): AlertListingUpdateAlertsAction => ({
  type: ActionType.RESET_ALERT_GLOW,
  notification: Some(notification),
});

export const allAlertsResetGlow = (): AlertListingResetGlowAction => ({
  type: ActionType.RESET_ALL_ALERT_GLOW,
});

export const alertsTrackingTotalCountProgressStateUpdate = (
  progressState: Maybe<InProgressState>,
  total: Maybe<number>,
): AlertsTrackingTotalCountProgressStateUpdateAction => {
  let type;
  if (progressState.exists((p) => p.isInProgress && p.error.isNone())) {
    type = ActionType.REQUEST_ALERTS_TRACKING_TOTAL_COUNT;
  } else if (progressState.exists((p) => !p.isInProgress && p.error.isNone())) {
    type = ActionType.RECEIVE_ALERTS_TRACKING_TOTAL_COUNT;
  } else if (progressState.exists((p) => !p.isInProgress && p.error.isSome())) {
    type = ActionType.RECEIVE_ALERTS_TRACKING_TOTAL_COUNT_ERROR;
  } else {
    throw new Error("[alertsTrackingTotalCountProgressStateUpdate] incorrect value for 'progressState'");
  }
  return {
    type,
    alertsTrackingTotalCountUpdateProgressState: progressState,
    totalTrackedAlerts: total,
  };
};
