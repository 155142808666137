import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

const component = ({ user }: Props) => (
  <div className="content">
    {user.isLoggedIn ? (
      <p>
        Welcome the NOC Dashboard! You are logged in as <strong>{user.profile.fullName}</strong> (<strong>@{user.profile.userName}</strong>).
      </p>
    ) : (
      <p>
        Welcome the NOC Dashboard! Please <Link to="/login">login</Link>
      </p>
    )}
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: State) => ({
  user: state.user,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, unknown>(mapStateToProps, mapDispatchToProps)(component);
