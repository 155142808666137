import { Action } from '../../redux/actions';
import { IconType } from '../Notification';

/**
 * Action types
 */

export enum ActionType {
  NOTIFICATION_SHOW = 'NOTIFICATION_SHOW',
  NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS',
}

/**
 * Action entities
 */

export interface NotificationState {
  id: string;
  icon?: IconType;
  title?: string;
  text: string;
}

/**
 * Action creators
 */
interface NotificationShowAction extends Action<ActionType> {
  id: string;
  title?: string;
  icon?: IconType;
  text: string;
}

interface NotificationDismissAction extends Action<ActionType> {
  id: string;
}

export type ActionObject = NotificationShowAction & NotificationDismissAction;

export const notificationShow = (id: string, text: string, icon?: IconType, title?: string): NotificationShowAction => ({
  type: ActionType.NOTIFICATION_SHOW,
  id,
  title,
  icon,
  text,
});

export const notificationDismiss = (id: string): NotificationDismissAction => ({
  type: ActionType.NOTIFICATION_DISMISS,
  id,
});
