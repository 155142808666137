// import { AssetNotification } from '@serverfarm/nocd-commons';
// import logdown, { Logger } from 'logdown';
import logdown, { Logger } from 'logdown';
import { Maybe, None, Some } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../config';
import { State } from '../../../reducers';
import axios from '../../../services/axios';
import { Alert, alertDeserialize, AlertsListFilter, AlertsListQueryResponse, ListSort } from '../../alerts';
import { showErrorNotification, showWarningNotification } from '../../notification';
import { Asset, AssetsListFilter, AssetsListSort } from '../entities';
import {
  assetListingFilterUpdate,
  assetsReceive,
  assetsRequest,
  assetListingHiddenColumnsUpdate,
  assetListingSortUpdate,
  assetsReset,
  assetsReceiveMappingInsertError,
  assetsReceiveMappingInsertSuccess,
  assetsRequestMappingInsert,
  assetMappingReceiveMappingDeleteError,
  assetMappingReceiveMappingDeleteSuccess,
  assetMappingRequestDelete,
  assetsReceiveError,
  exportedAssetsReceive,
} from '../redux/actions';
import {
  assetMaintenanceDialogOpen,
  assetMaintenanceDialogClose,
  assetMaintenanceDialogUpdateDateRange,
  assetMaintenanceDialogUpdateProgressState,
} from '../redux/asset-maintenance/actions';
import {
  assetMappingDialogOpen,
  assetMappingDialogClose,
  assetMappingDialogUpdateMappingString,
  assetMappingDialogUpdateMappingAsset,
  assetMappingDialogUpdateProgressState,
} from '../redux/asset-mapping/actions';

import { assetDeserialize, AssetsListQueryResponse } from './entities';
import { assetListingFilterSerialize } from './serdes';
// import { assetListingFilterSerialize } from './serdes';

export const fetchAssets =
  (
    filter: Maybe<AssetsListFilter>,
    page: number,
    sizePerPage: number,
    sort: Maybe<AssetsListSort>,
    logger: Logger = logdown('assets:data:actions:fetchAssets'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(assetsRequest(filter, sizePerPage, sort));
    // const serializedFilter = assetListingFilterSerialize(filter.getOrElse({ updated: None() }));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/in-command/assets`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        ...filter.map((f) => (f.name ? { name: f.name } : {})).getOrElse({}),
        ...filter.map((f) => (f.site ? { sites: f.site } : {})).getOrElse({}),
        ...filter.map((f) => (f.location ? { location: f.location } : {})).getOrElse({}),
        ...filter.map((f) => (f.isInMaintenance ? { isInMaintenance: f.isInMaintenance } : {})).getOrElse({}),
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
      },
    })
      .then((result) => result.data as AssetsListQueryResponse)
      .then((assetsData) => {
        dispatch(
          assetsReceive(
            assetsData.assets.map((asset) => assetDeserialize(asset)),
            page,
            assetsData.total,
          ),
        );

        // return axios({
        //   method: 'get',
        //   url: `${config.services.crawfish.api.url}/in-command/assets`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //   },
        //   params: {
        //     ...filter.map((f) => (f.name ? { name: f.name } : {})).getOrElse({}),
        //     ...filter.map((f) => (f.site ? { sites: [f.site] } : {})).getOrElse({}),
        //     ...filter.map((f) => (f.location ? { location: f.location } : {})).getOrElse({}),
        //     page: 1,
        //     limit: assetsData.total,
        //     sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
        //   },
        // })
        //   .then((result) => result.data as AssetsListQueryResponse)
        //   .then((assetsData) => {
        //     dispatch(exportedAssetsReceive(assetsData.assets.map((asset) => assetDeserialize(asset))));
        //   });
      })
      .catch((error) => {
        dispatch(assetsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const fetchExportedAssets =
  (
    filter: Maybe<AssetsListFilter>,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    // const serializedFilter = assetListingFilterSerialize(filter.getOrElse(None));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/in-command/assets`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        ...filter.map((f) => (f.name ? { name: f.name } : {})).getOrElse({}),
        ...filter.map((f) => (f.site ? { sites: [f.site] } : {})).getOrElse({}),
        ...filter.map((f) => (f.location ? { location: f.location } : {})).getOrElse({}),
        // page: page + 1,
        // limit: sizePerPage,
        page: 1,
        limit: 500,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
      },
    })
      .then((result) => result.data as AssetsListQueryResponse)
      .then((alertsData) => {
        dispatch(exportedAssetsReceive(alertsData.assets.map((asset) => assetDeserialize(asset))));
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const updateAssetListingFilter =
  (filter: Maybe<AssetsListFilter>, page: number, sizePerPage: number, logger: Logger = logdown('assets:data:actions:updateAssetListingFilter')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(assetListingFilterUpdate(filter, page, sizePerPage));
  };

export const updateAssetListingSort =
  (sort: Maybe<AssetsListSort>, logger: Logger = logdown('assets:data:actions:updateAssetListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
    dispatch(assetListingSortUpdate(sort));
  };

export const resetAssets =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetsReset());
  };

export const updateAssetListingHiddenColumns =
  (hiddenColumns: string[], logger: Logger = logdown('assets:data:actions:updateAssetListingHiddenColumns')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(assetListingHiddenColumnsUpdate(hiddenColumns));
  };

export const openAssetMaintenanceDialog =
  (asset: Asset, alert?: Alert, acknowledgeAlert?: boolean) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMaintenanceDialogOpen(asset, alert, acknowledgeAlert));
  };

export const updateAssetMaintenanceDialogDateRange =
  (startDate: Date, endDate: Date) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMaintenanceDialogUpdateDateRange(startDate, endDate));
  };

export const closeAssetMaintenanceDialog =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMaintenanceDialogClose());
  };

export const openAssetMappingDialog =
  (alert: Maybe<Alert>, asset: Maybe<Asset>) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMappingDialogOpen(alert, asset));
  };

export const updateAssetMappingDialogAsset =
  (asset: Maybe<Asset>) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMappingDialogUpdateMappingAsset(asset));
  };

export const updateAssetMappingDialogMappingString =
  (mappingString: Maybe<string>) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMappingDialogUpdateMappingString(mappingString));
  };

export const updateAssetMappingDialogProgressState =
  (
    progressState: Maybe<{
      isInProgress: boolean;
      error: Maybe<Error>;
    }>,
  ) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMappingDialogUpdateProgressState(progressState));
  };

export const closeAssetMappingDialog =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(assetMappingDialogClose());
  };

export const scheduleAssetMaintenance =
  (assetId: number, startDate: Date, endDate: Date, logger: Logger = logdown('assets:data:actions:scheduleAssetMaintenance')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    logger.log(`Scheduling asset (${assetId}) for maintenance ${startDate} <==> ${endDate}`);
    dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: true, error: None() })));
    axios
      .post(
        `${config.services.crawfish.api.url}/in-command/assets/${assetId}/maintenance`,
        {
          maintenanceStart: startDate.toISOString(),
          maintenanceEnd: endDate.toISOString(),
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: false, error: None() })));
        dispatch(assetMaintenanceDialogClose());
      })
      .catch((error) => {
        dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: false, error: Some(error) })));
        showErrorNotification(error)(dispatch);
      });
  };

export const updateAssetMaintenance =
  (assetId: number, maintenanceId: number, endDate: Date, logger: Logger = logdown('assets:data:actions:updateAssetMaintenance')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    logger.log(`Updating asset (${assetId}) maintenance schedule (${maintenanceId}) end date to ${endDate}`);
    dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: true, error: None() })));
    axios
      .post(
        `${config.services.crawfish.api.url}/in-command/assets/${assetId}/maintenance`,
        {
          maintenanceId: maintenanceId,
          maintenanceEnd: endDate.toISOString(),
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: false, error: None() })));
        dispatch(assetMaintenanceDialogClose());
      })
      .catch((error) => {
        dispatch(assetMaintenanceDialogUpdateProgressState(Some({ isInProgress: false, error: Some(error) })));
        showErrorNotification(error)(dispatch);
      });
  };

export const addAssetMapping =
  (assetId: number, mappingsString: string, logger: Logger = logdown('assets:data:actions:addAssetMapping')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    logger.log(`Adding asset (${assetId}) mapping ${mappingsString}`);
    dispatch(assetsRequestMappingInsert(mappingsString));
    dispatch(updateAssetMappingDialogProgressState(Some({ isInProgress: true, error: None() })));

    axios
      .post(
        `${config.services.crawfish.api.url}/in-command/assets/${assetId}/mapping`,
        {
          mapping: mappingsString,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        dispatch(assetsReceiveMappingInsertSuccess());
        dispatch(updateAssetMappingDialogProgressState(Some({ isInProgress: false, error: None() })));
        dispatch(assetMappingDialogClose());
      })
      .catch((error) => {
        dispatch(assetsReceiveMappingInsertError(error));
        dispatch(updateAssetMappingDialogProgressState(Some({ isInProgress: false, error: Some(error) })));
        showErrorNotification(error)(dispatch);
      });
  };

export const deleteAssetMapping =
  (assetId: number, mappingId: number, logger: Logger = logdown('assets:data:actions:deleteAssetMapping')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    logger.log(`Adding asset (${assetId}) mapping ${mappingId}`);
    dispatch(assetMappingRequestDelete(Some(assetId), Some(mappingId)));

    axios
      .delete(`${config.services.crawfish.api.url}/in-command/assets/${assetId}/mapping/${mappingId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(assetMappingReceiveMappingDeleteSuccess());
        // dispatch(assetMappingDialogClose());
      })
      .catch((error) => {
        dispatch(assetMappingReceiveMappingDeleteError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const messageForUserReloadThePage =
  (logger: Logger = logdown('assets:data:actions:messageForUserReloadThePage')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    logger.log(`Adding asset ($) mapping $`);
    showWarningNotification('If login fails, clear your browser cache and cookies and retry')(dispatch);
  };
