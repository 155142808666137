import logdown from 'logdown';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { Table, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';

const logger = logdown('component:ServerfarmTable');

export interface SortProps {
  id: string;
  desc: boolean;
}

export interface ServerfarmTableProps {
  columns: any;
  data: any;
  exportData?: any;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  onSortChange: (sortBy: SortProps[]) => void;
  onColumnVisibilityChange: (hiddenColumns: string[]) => void;
  onReset?: () => void;
  total: number;
  initialState: {
    pageSize: number;
    pageIndex: number;
    sortBy?: SortProps[];
    hiddenColumns?: string[];
  };
  controlledPageCount: number;
  onDropdownToggle?: (isOpen: boolean) => void;
  isLoading?: boolean;
  renderRow?: (row: any) => any;
  renderCell?: (cell: any, row: any) => any;
  showTopManageHiddenCols?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ServerfarmTable({
  columns,
  data,
  exportData,
  onPageChange,
  onSortChange,
  onColumnVisibilityChange,
  onReset,
  total,
  controlledPageCount,
  initialState,
  onDropdownToggle,
  isLoading,
  renderRow,
  renderCell,
  showTopManageHiddenCols,
}: ServerfarmTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { pageIndex, pageSize, sortBy, hiddenColumns },
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // getRowProps,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      manualPagination: true,
      pageCount: controlledPageCount,
      // @ts-ignore
      initialState: initialState,
      useControlledState: (state: any) => {
        logger.log(`Controlled state`, state);
        return {
          ...state,
        };
      },
      manualSortBy: true,
      getRowProps: renderRow,
    },
    useFilters,
    useSortBy,
    usePagination,
  ) as any;

  const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
  const [showHideColumnsDropdownOpenUp, setShowHideColumnsDropdownOpenUp] = useState(false);
  const [showHideColumnsDropdownOpenDown, setShowHideColumnsDropdownOpenDown] = useState(false);

  React.useEffect(() => {
    onPageChange(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    onSortChange(sortBy);
  }, [sortBy]);

  React.useEffect(() => {
    onColumnVisibilityChange(hiddenColumns);
  }, [hiddenColumns]);

  const PagerDropdown = (props: { pageSize: number; pageSizeSelectHandler: any; disabled?: boolean }) => {
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    const PAGE_SIZES = [10, 25, 50, 100];

    return (
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
          {props.pageSize}
        </DropdownToggle>
        <DropdownMenu>
          {PAGE_SIZES.map((p) => {
            return (
              <DropdownItem key={p} onClick={(/*e: any*/) => props.pageSizeSelectHandler(p)}>
                {p}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const ExportDropdown = (props: { disabled?: boolean }) => {
    const toggleExportDropdown = () => {
      const isOpen = !exportDropdownOpen;
      onDropdownToggle && onDropdownToggle(isOpen);
      setExportDropdownOpen(isOpen);
    };

    let csvLink: any;

    return (
      <ButtonDropdown isOpen={exportDropdownOpen && !!exportData && !!exportData.length} toggle={toggleExportDropdown}>
        <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
          Export as {exportDropdownOpen && (!exportData || !exportData.length) ? <Spinner size="sm" /> : '...'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem key="export-to-csv" onClick={() => csvLink.link.click()}>
            <CSVLink data={exportData} filename="data.csv" className="hidden" ref={(r: any) => (csvLink = r)} target="_blank">
              CSV
            </CSVLink>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const ShowHideColumnsDropdown = (props: { columns: any[]; disabled?: boolean; up: boolean; className: string }) => {
    const toggleDropdown = () => {
      const isOpen = props.up ? !showHideColumnsDropdownOpenUp : !showHideColumnsDropdownOpenDown;
      onDropdownToggle && onDropdownToggle(isOpen);
      props.up ? setShowHideColumnsDropdownOpenUp(isOpen) : setShowHideColumnsDropdownOpenDown(isOpen);
    };

    return (
      <ButtonDropdown
        className={`${props.className}`}
        isOpen={props.up ? showHideColumnsDropdownOpenUp : showHideColumnsDropdownOpenDown}
        toggle={toggleDropdown}
      >
        <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
          Show/Hide Columns
        </DropdownToggle>
        <DropdownMenu>
          {props.columns
            .filter((column) => !column.disableVisibilityChange)
            .map((column: any) => {
              const columnToggleHiddenProps = column.getToggleHiddenProps();
              return (
                <DropdownItem
                  key={`column-to-show-hide-${column.id}`}
                  onClick={() => {
                    console.log(columnToggleHiddenProps.checked);
                    columnToggleHiddenProps.onChange({ target: { checked: !columnToggleHiddenProps.checked } });
                  }}
                >
                  {column.isVisible ? 'Hide' : 'Show'} {typeof column.Header === 'function' ? column.Header() : column.Header}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const ResetButton = (props: { onReset: () => void; disabled?: boolean }) => {
    return (
      <Button className="btn-sm reset-filter" onClick={() => props.onReset()} disabled={props.disabled}>
        Reset Filters
      </Button>
    );
  };

  const Pager = (props: {
    isLoading?: boolean;
    canPreviousPage: boolean;
    canNextPage: boolean;
    page: any[];
    pageCount: number;
    pageIndex: number;
    pageOptions: any[];
    pageSize: number;
    total: number;
    setPageSize: any;
    columns: any[];
  }) => {
    return (
      <div>
        <Button
          className="btn-sm page-all-left"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={props.isLoading || !props.canPreviousPage}
        >
          {'<<'}
        </Button>{' '}
        <Button
          className="btn-sm page-left"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={props.isLoading || !props.canPreviousPage}
        >
          {'<'}
        </Button>{' '}
        <Button
          className="btn-sm page-right"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={props.isLoading || props.total === props.pageSize || !props.canNextPage}
        >
          {'>'}
        </Button>{' '}
        <Button
          className="btn-sm page-all-right"
          onClick={() => {
            gotoPage(props.pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={props.isLoading || props.total === props.pageSize || !props.canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="d-inline-flex align-items-center px-2 page-number">
          Page {props.pageIndex + 1} of {props.total === props.pageSize ? 1 : props.pageOptions.length}
        </span>
        <PagerDropdown pageSize={props.pageSize} pageSizeSelectHandler={props.setPageSize} disabled={props.isLoading} />
        <span className="d-inline-flex align-items-center px-2 page-show">
          Showing {props.page.length} of {props.total} {pluralize('item', props.total)}
        </span>
        {exportData ? <ExportDropdown disabled={props.isLoading} /> : ''}
        <ShowHideColumnsDropdown className={''} disabled={props.isLoading} columns={props.columns} up={false} />
        {onReset ? <ResetButton disabled={props.isLoading} onReset={onReset} {...props} /> : ''}
      </div>
    );
  };

  // Render the UI for your table
  return (
    <div>
      {showTopManageHiddenCols && (
        <ShowHideColumnsDropdown className={'header-showhide-button'} disabled={isLoading} columns={allColumns} up={true} />
      )}
      <Table striped={true} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th key={column} className="align-top" {...column.getHeaderProps()} style={{ width: column.width }}>
                  <div className="sortable-header" {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any): any => {
            prepareRow(row);
            const rowProps = renderRow ? renderRow(row) : {};
            return (
              <tr key={row} {...{ ...row.getRowProps(), ...rowProps }}>
                {row.cells.map((cell: any) => {
                  const cellProps = renderCell ? renderCell(cell, row) : {};
                  return (
                    <td key={cell} {...{ ...cell.getCellProps(), ...cellProps }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pager
        isLoading={isLoading}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        page={page}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        total={total}
        setPageSize={setPageSize}
        columns={allColumns}
      />
    </div>
  );
}
