import { MonitoringSystem, MonitoringSystemIncident, MonitoringSystemType } from '@serverfarm/nocd-commons';
import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { monitoringSystemsClose, monitoringSystemsOpen } from '../../../alerts/redux/alerts/actions';
import { showErrorNotification } from '../../../notification';
import { ListSort, MonitoringSystemsListFilter } from '../../entities';
import {
  exportedMonitoringSystemsReceive,
  exportedMonitoringSystemsReset,
  monitoringSystemListingFilterUpdate,
  monitoringSystemListingHiddenColumnsUpdate,
  monitoringSystemsReceive,
  monitoringSystemsReceiveError,
  monitoringSystemsRequest,
  monitoringSystemsReset,
  monitoringSystemsCompleteEditing,
  monitoringSystemDoneDeleting,
  monitoringSystemDoneSaving,
  monitoringSystemEditName,
  monitoringSystemFailDeleting,
  monitoringSystemFailSaving,
  monitoringSystemStartDeleting,
  monitoringSystemStartEditing,
  monitoringSystemStartSaving,
  monitoringSystemEditSite,
  monitoringSystemEditCode,
  monitoringSystemEditType,
  monitoringSystemListingSortUpdate,
  monitoringSystemEditFrequency,
  monitoringSystemsStatusCheck,
  monitoringSystemsStatusCheckError,
  monitoringSystemEditSev1Def,
  monitoringSystemEditSev2Def,
  monitoringSystemEditSev3Def,
  monitoringSystemEditSev4Def,
  monitoringSystemEditSev5Def,
  monitoringSystemEditNormalDef,
  monitoringSystemEditOffnormalDef,
  monitoringSystemIncidentInformationOpen,
  monitoringSystemIncidentInformationClose,
  monitoringSystemIncidentStartAcknowledge,
  monitoringSystemIncidentDoneAcknowledge,
  monitoringSystemIncidentFailAcknowledge,
  monitoringSystemIncidentStartClosing,
  monitoringSystemIncidentDoneClosing,
  monitoringSystemIncidentFailClosing,
} from '../../redux/monitoring-systems/actions';

import {
  MonitoringSystemsListQueryResponse,
  monitoringSystemDeserialize,
  monitoringSystemSerialize,
  MonitoringSystemsStatusCheckResponse,
} from './entities';
import { monitoringSystemListingFilterSerialize } from './serdes';

export const updateMonitoringSystemListingFilter =
  (
    filter: Maybe<MonitoringSystemsListFilter>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('alerts:data:actions:updateMonitoringSystemListingFilter'),
  ) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(monitoringSystemListingFilterUpdate(filter, page, sizePerPage));
  };

export const updateMonitoringSystemListingSort =
  (sort: Maybe<ListSort>, logger: Logger = logdown('alerts:data:actions:updateMonitoringSystemListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
    dispatch(monitoringSystemListingSortUpdate(sort));
  };

export const updateMonitoringSystemListingHiddenColumns =
  (hiddenColumns: string[], logger: Logger = logdown('alerts:data:actions:updateMonitoringSystemListingHiddenColumns')) =>
  (dispatch: Dispatch<any> /*, getState: any*/): void => {
    dispatch(monitoringSystemListingHiddenColumnsUpdate(hiddenColumns));
  };

export const resetMonitoringSystems =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemsReset());
  };

export const resetExportedMonitoringSystems =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(exportedMonitoringSystemsReset());
  };

export const fetchMonitoringSystemsStatus =
  () =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/monitoring-systems/status`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.data as MonitoringSystemsStatusCheckResponse)
      .then((monitoringSystemsCheck) => {
        dispatch(monitoringSystemsStatusCheck(monitoringSystemsCheck.failures));
      })
      .catch((error) => {
        dispatch(monitoringSystemsStatusCheckError(error));
        showErrorNotification(error)(dispatch);
      });
  };
export const fetchMonitoringSystems =
  (
    filter: Maybe<MonitoringSystemsListFilter>,
    page: number,
    sizePerPage: number,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    dispatch(monitoringSystemsRequest(filter, sizePerPage, sort));
    const serializedFilter = monitoringSystemListingFilterSerialize(filter.getOrElse({}));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/monitoring-systems`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field.replace('receivedSiteLocal', 'updatedAt').replace('receivedUtc', 'updatedAt')}:${s.order}`).orUndefined(),
        providers: serializedFilter.providers,
        sites: serializedFilter.sites,
      },
    })
      .then((result) => result.data as MonitoringSystemsListQueryResponse)
      .then((monitoringSystemsData) => {
        dispatch(
          monitoringSystemsReceive(
            monitoringSystemsData.monitoringSystems.map((monitoringSystem) => monitoringSystemDeserialize(monitoringSystem)),
            page,
            monitoringSystemsData.total,
          ),
        );
      })
      .catch((error) => {
        dispatch(monitoringSystemsReceiveError(error));
        showErrorNotification(error)(dispatch);
      });
  };

export const fetchExportedMonitoringSystems =
  (
    filter: Maybe<MonitoringSystemsListFilter>,
    sort: Maybe<ListSort>,
    // logger: Logger = logdown('alerts:data:actions:fetchAlerts'),
  ) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
    // dispatch(alertsRequest(filter, 500, sort));
    const serializedFilter = monitoringSystemListingFilterSerialize(filter.getOrElse({}));
    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/monitoring-systems`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        page: 1,
        limit: 500,
        sort: sort.map((s) => `${s.field.replace('receivedSiteLocal', 'updatedAt').replace('receivedUtc', 'updatedAt')}:${s.order}`).orUndefined(),
        providers: serializedFilter.providers,
        sites: serializedFilter.sites,
      },
    })
      .then((result) => result.data as MonitoringSystemsListQueryResponse)
      .then((monitoringSystemsData) => {
        dispatch(
          exportedMonitoringSystemsReceive(
            monitoringSystemsData.monitoringSystems.map((monitoringSystem) => monitoringSystemDeserialize(monitoringSystem)),
          ),
        );
      })
      .catch((error) => {
        showErrorNotification(error)(dispatch);
      });
  };

export const editMonitoringSystem = (monitoringSystem: Maybe<MonitoringSystem>) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemStartEditing(monitoringSystem));
};

export const editMonitoringSystemName = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditName(name));
};

export const editMonitoringSystemSite = (site: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSite(site));
};
export const editMonitoringSystemType = (type: MonitoringSystemType) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditType(type));
};

export const editMonitoringSystemFrequency = (frequency: number) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditFrequency(frequency));
};

export const editMonitoringSystemCode = (code: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditCode(code));
};

export const editMonitoringSystemSev1Def = (sev1_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSev1Def(sev1_def));
};
export const editMonitoringSystemSev2Def = (sev2_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSev2Def(sev2_def));
};
export const editMonitoringSystemSev3Def = (sev3_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSev3Def(sev3_def));
};
export const editMonitoringSystemSev4Def = (sev4_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSev4Def(sev4_def));
};
export const editMonitoringSystemSev5Def = (sev5_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditSev5Def(sev5_def));
};

export const editMonitoringSystemNormalDef = (normal_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditNormalDef(normal_def));
};

export const editMonitoringSystemOffnormalDef = (offnormal_def: string) => (dispatch: Dispatch<any>) => {
  dispatch(monitoringSystemEditOffnormalDef(offnormal_def));
};

export const openMonitoringSystems =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemsOpen());
  };

export const closeMonitoringSystems =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemsClose());
  };

export const completeMonitoringSystemEdit =
  (monitoringSystem: Maybe<MonitoringSystem> = Maybe.None()) =>
  (dispatch: Dispatch<any>) => {
    dispatch(monitoringSystemsCompleteEditing(monitoringSystem));
  };

// Save MonitoringSystem

export const saveMonitoringSystem =
  (monitoringSystem: MonitoringSystem, createNew = false, logger: Logger = logdown('monitoringSystems:data:actions:saveMonitoringSystem')) =>
  (dispatch: Dispatch<any>) => {
    const monitoringSystemApiEntity = monitoringSystemSerialize(monitoringSystem);
    logger.log(`monitoringSystemApiEntity => ${JSON.stringify(monitoringSystemApiEntity)}`);
    dispatch(monitoringSystemStartSaving());
    const endpointIdSuffix = createNew ? '' : `/${monitoringSystem.id}`;
    const sender = monitoringSystem.code;
    if (!sender) {
      const error = `Missing Endpoint information`;
      dispatch(monitoringSystemFailSaving(new Error(error)));
      showErrorNotification(error)(dispatch);
      return;
    }
    const [domain] = sender.split('@');
    if (!domain || !domain.length) {
      const error = `Error parsing endpoint email`;
      dispatch(monitoringSystemFailSaving(new Error(error)));
      showErrorNotification(error)(dispatch);
      return;
    }
    const blocks = domain.split('_');
    if (!blocks || !blocks.length) {
      const error = `Error obtaining code`;
      dispatch(monitoringSystemFailSaving(new Error(error)));
      showErrorNotification(error)(dispatch);
      return;
    }
    const code = blocks[blocks.length - 1];
    const entity = {
      name: monitoringSystem.name,
      site: monitoringSystem.site,
      type: monitoringSystem.type,
      frequency: monitoringSystem.frequency,
      code: code,
      active: monitoringSystem.active,
      parser: monitoringSystem.parser,
    };
    axios({
      method: createNew ? 'post' : 'put',
      url: `${config.services.crawfish.api.url}/monitoring-systems${endpointIdSuffix}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...entity,
      },
    })
      .then((response) => response.data)
      .then(() => {
        dispatch(monitoringSystemDoneSaving(monitoringSystem));
        dispatch(monitoringSystemsCompleteEditing(Maybe.Some(monitoringSystem)));
      })
      .catch((error) => {
        dispatch(monitoringSystemFailSaving(error));
        showErrorNotification(error)(dispatch);
      });
  };

// Delete MonitoringSystem

export const deleteMonitoringSystem =
  (monitoringSystemId: string, logger: Logger = logdown('monitoringSystems:data:actions:deleteMonitoringSystem')) =>
  (dispatch: Dispatch<any>) => {
    logger.log(`monitoringSystemId => ${monitoringSystemId}`);
    dispatch(monitoringSystemStartDeleting(monitoringSystemId));
    axios({
      method: 'delete',
      url: `${config.services.crawfish.api.url}/monitoring-systems/${monitoringSystemId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(monitoringSystemDoneDeleting(monitoringSystemId));
      })
      .catch((error) => {
        dispatch(monitoringSystemFailDeleting(monitoringSystemId, Maybe.Some(error)));
        showErrorNotification(error)(dispatch);
      });
  };

// Monitoring System Incidents

// Acknowledge MonitoringSystem Incident

export const acknowledgeMonitoringSystemIncident =
  (alertId: string, logger: Logger = logdown('monitoringSystems:data:actions:acknowledgeMonitoringSystemIncident')) =>
  (dispatch: Dispatch<any>) => {
    logger.log(`alertId => ${alertId}`);
    dispatch(monitoringSystemIncidentStartAcknowledge(alertId));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/monitoring-systems/incidents/${alertId}/acknowledge`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(monitoringSystemIncidentDoneAcknowledge(alertId));
        dispatch(closeMonitoringSystemIncidentInformation());
      })
      .catch((error) => {
        dispatch(monitoringSystemIncidentFailAcknowledge(alertId, Maybe.Some(error)));
        dispatch(closeMonitoringSystemIncidentInformation());
        showErrorNotification(error)(dispatch);
      });
  };

// Close MonitoringSystem Incident

export const closeMonitoringSystemIncident =
  (alertId: string, logger: Logger = logdown('monitoringSystems:data:actions:closeMonitoringSystemIncident')) =>
  (dispatch: Dispatch<any>) => {
    logger.log(`alertId => ${alertId}`);
    dispatch(monitoringSystemIncidentStartClosing(alertId));
    axios({
      method: 'post',
      url: `${config.services.crawfish.api.url}/monitoring-systems/incidents/${alertId}/close`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(monitoringSystemIncidentDoneClosing(alertId));
        dispatch(closeMonitoringSystemIncidentInformation());
      })
      .catch((error) => {
        dispatch(monitoringSystemIncidentFailClosing(alertId, Maybe.Some(error)));
        dispatch(closeMonitoringSystemIncidentInformation());
        showErrorNotification(error)(dispatch);
      });
  };

export const openMonitoringSystemIncidentInformation =
  (monitoringSystem: MonitoringSystem) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemIncidentInformationOpen(monitoringSystem));
  };

export const closeMonitoringSystemIncidentInformation =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(monitoringSystemIncidentInformationClose());
  };
