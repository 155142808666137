// @ts-ignore: No declarations available
import logdown from 'logdown';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Badge } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import AlertWorkLog from './AlertWorkLog';
import {
  fetchAlertWorkLog,
  updateAlertWorkLogFilter,
  updateAlertWorkLogSort,
  updateAlertWorkLogPage,
  closeAlertWorkLogList,
} from './data/alert-worklog';
import { setSelectedAlert } from './data/alerts';

const logger = logdown('component:RenderedReportHistory');

class Component extends React.Component<Props, unknown> {
  reset() {
    // this.props.updateAlertNotificationListingFilter(Maybe.none(), 1, this.props.alertsListing.sizePerPage);
    // this.props.updateAlertNotificationListingSort(Maybe.none());
    // this.props.fetchAlertNotifications(
    //   this.props.alertNotificationsListing.alert.some(),
    //   Maybe.none(),
    //   Maybe.none(),
    //   1,
    //   this.props.alertNotificationsListing.sizePerPage,
    // );
  }
  public render() {
    logger.log(`Alert ${this.props.alertWorkLogListing.alert.isSome()}`);
    return (
      <Modal
        id="alert-notifications-modal"
        data-testid="alert-notifications-modal"
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.alertWorkLogListing.alert.isSome()}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="alert-notifications-modal-header">
          <EllipsisText
            className="alert-notifications-header"
            text={`${this.props.alertWorkLogListing.alert.map((a: any) => a.source).getOrElse('N/A')} Work Log`}
            length={75}
          />
        </ModalHeader>
        <ModalBody>
          <AlertWorkLog {...this.props} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="alert-notifications-cancel-button"
            data-testid="alert-notifications-modal-cancel-button"
            color="secondary"
            onClick={this.cancel.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.setSelectedAlert('');
    if (this.props.alertWorkLogListing.workLogs.isSome()) {
      return this.props.closeAlertWorkLogList();
    }
    this.props.closeAlertWorkLogList();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAlertWorkLog,
      updateAlertWorkLogFilter,
      updateAlertWorkLogSort,
      updateAlertWorkLogPage,
      closeAlertWorkLogList,
      setSelectedAlert,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  alertWorkLogListing: state.alertWorkLogListing,
  alertWorkLogAdding: state.alertWorkLogAdding,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
