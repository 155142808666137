// import logdown from 'logdown';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-timezone';
import { Maybe } from 'monet';
import React, { useState } from 'react';
import { Button, Popover, PopoverBody, Form, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';

import { doesTargetExist } from '../../services/utilities';

import { DATE_TIME_FORMAT_DEFAULT } from './dateTime';

export type DateRange = { from?: Date | null; to?: Date | null };
export type DateRangeFn = () => DateRange;
export type DateRangeFnCustom = {
  amount: number;
  type: 'h' | 'd' | 'w' | 'm';
};
export type onDateRangeFilterSave = (dateRange: DateRange | DateRangeFn) => void;
export type onDateRangeFilterReset = () => void;
export type StaticRange = {
  label: string;
  range: () => DateRange;
};

export interface DateRangeFilterProps {
  id: string;
  value: Maybe<DateRange | DateRangeFn>;
  onSave: onDateRangeFilterSave;
  onReset: onDateRangeFilterReset;
  disabled?: boolean;
  timeZoneRef?: string;
  showErrorNotification?: any;
}

const BUTTON_TEXT_DEFAULT = 'Set date range';
const TIME_ZONE_REF_DEFAULT = 'Etc/UTC';

export const parseDateRangeValue = (dateRange: DateRange | DateRangeFn): DateRange => {
  if (typeof dateRange === 'function') {
    return dateRange();
  }
  return dateRange;
};
export const DateRangeFilter = (props: DateRangeFilterProps): any => {
  const { timeZoneRef = TIME_ZONE_REF_DEFAULT } = props;
  const [showCustom, setShowCustom] = useState(false);

  const initialDateRangeState = { from: moment().subtract(1, 'hour').toDate(), to: moment().toDate() };

  const [popOverOpen, setOpen] = useState(false);
  const [selection, setSelection] = useState<DateRange | undefined>(initialDateRangeState);

  const toggle = () => setOpen(!popOverOpen);

  const onSave = () => {
    if (selection) {
      if (moment(selection.from).isAfter(moment(selection.to))) {
        return props.showErrorNotification('Start Date cannot begin after End Date', 5000);
      }
      if (moment(selection.to).diff(selection.from, 'days') > 30) {
        return props.showErrorNotification('You cannot select a time interval longer than 30 days', 5000);
      }
      props.onSave({
        from: selection.from,
        to: selection.to,
      });
      toggle();
    }
  };

  const handleChange = (range: DateRange) => {
    if (range) {
      setSelection({
        from: range.from ? moment(range.from).toDate() : null,
        to: range.to ? moment(range.to).toDate() : null,
      });
    }
  };

  let isCustomFromTo: { from: string; to: string } | null = null;

  const popoverButtonCaption = Maybe.fromUndefined(selection)
    .map((selection) => ({
      from: selection.from,
      to: selection.to,
    }))
    .map((dateRange: any): string => {
      dateRange = parseDateRangeValue(dateRange);
      const from = moment(dateRange.from).tz(timeZoneRef).format(DATE_TIME_FORMAT_DEFAULT);
      const to = moment(dateRange.to).tz(timeZoneRef).format(DATE_TIME_FORMAT_DEFAULT);
      // debugger;
      const t = moment(to).diff(from, 'minutes');
      if (moment(to).diff(from, 'hour') === 1) {
        return '1 hour';
      } else if (moment(to).diff(from, 'hour') === 24) {
        return '1 day';
      } else if (moment(to).diff(from, 'hour') === 168) {
        return '1 week';
      } else {
        isCustomFromTo = { from, to };
        return 'Custom';
      }
    })
    .getOrElse(BUTTON_TEXT_DEFAULT);

  return (
    <div id={`container-${props.id}`}>
      <Button
        className="date-range-filter-toggle"
        id={props.id}
        color={'success'}
        size="sm"
        onClick={() => {
          toggle();
        }}
      >
        {popoverButtonCaption}
        {isCustomFromTo !== null ? (
          <>
            {doesTargetExist(props.id) && (
              <UncontrolledTooltip target={props.id}>
                {/* @ts-ignore */}
                From {isCustomFromTo.from} to {isCustomFromTo.to}
              </UncontrolledTooltip>
            )}
          </>
        ) : (
          ''
        )}
      </Button>
      <Popover placement="left-end" isOpen={popOverOpen} target={props.id} toggle={toggle}>
        <PopoverBody>
          <Form>
            <FormGroup>
              <Button
                className="date-range-filter-custom"
                color="white"
                size="sm"
                onClick={() => {
                  setShowCustom(false);
                  handleChange({ from: moment().subtract(1, 'hour').toDate(), to: moment().toDate() });
                  props.onSave({
                    from: moment().subtract(1, 'hour').toDate(),
                    to: moment().toDate(),
                  });
                  toggle();
                }}
              >
                1 Hour
              </Button>
              <Button
                className="date-range-filter-custom"
                color="secondary"
                size="sm"
                onClick={() => {
                  setShowCustom(false);
                  handleChange({ from: moment().subtract(1, 'day').toDate(), to: moment().toDate() });
                  props.onSave({
                    from: moment().subtract(1, 'day').toDate(),
                    to: moment().toDate(),
                  });
                  toggle();
                }}
              >
                1 Day
              </Button>
              <Button
                className="date-range-filter-custom"
                color="secondary"
                size="sm"
                onClick={() => {
                  setShowCustom(false);
                  handleChange({ from: moment().subtract(1, 'week').toDate(), to: moment().toDate() });
                  props.onSave({
                    from: moment().subtract(1, 'week').toDate(),
                    to: moment().toDate(),
                  });
                  toggle();
                }}
              >
                1 Week
              </Button>
              <Button
                id="range-custom"
                className="date-range-filter-custom"
                color="secondary"
                size="sm"
                onClick={() => {
                  setShowCustom(true);
                }}
              >
                Custom
              </Button>
            </FormGroup>
            <FormGroup id="range-start-date" hidden={!showCustom}>
              <Label>Start</Label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    value={selection ? moment(selection.from) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'hour')}
                    closeOnSelect
                    reduceAnimations
                    timezone={timeZoneRef}
                    format="YYYY-MM-DD"
                    onChange={(date: any) => {
                      handleChange({ from: date, to: moment(date).add(30, 'days').toDate() });
                      setTimeout(() => {
                        setOpen(true);
                      }, 50);
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormGroup>
            <FormGroup id="range-end-date" hidden={!showCustom}>
              <Label>End</Label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    closeOnSelect
                    reduceAnimations
                    value={selection ? moment(selection.to) : moment()}
                    timezone={timeZoneRef}
                    format="YYYY-MM-DD"
                    onChange={(date: any) => {
                      handleChange({ from: moment(date).subtract(30, 'days').toDate(), to: date });
                      setTimeout(() => {
                        setOpen(true);
                      }, 50);
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormGroup>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button hidden={!showCustom} className="date-range-filter-save" color="primary" size="sm" onClick={() => onSave()}>
                Search
              </Button>
            </div>
            {doesTargetExist('range-custom') && <UncontrolledTooltip target={'range-end-date'}>UTC time</UncontrolledTooltip>}
          </Form>
        </PopoverBody>
      </Popover>
    </div>
  );
};
