import { Maybe } from 'monet';

import { IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';
import { DateRange } from '../../utils/dateRangeFilter';

import { AssetMappingDeleteState, AssetsListingState } from './entities';

const assetsListingState: AssetsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  assets: Maybe.None(),
  exportedAssets: Maybe.None(),
  filter: Maybe.Some({ updatedAt: Maybe.None<DateRange>() }),
  sort: Maybe.None(),
  hiddenColumns: [],
};

const assetMappingDeleteState: AssetMappingDeleteState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  assetId: Maybe.None(),
  mappingId: Maybe.None(),
};

export const ASSET_INITIAL_STATE = {
  assetsListingState,
  assetMappingDeleteState,
};
