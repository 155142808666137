import { NotificationGroup } from '../..';
import { CompanyApiEntity } from '../../../user';

export interface NotificationGroupApiEntity {
  id: number;
  type: 'noc:alert'; // todo : TBD
  name: string;
  description: string;
  company: CompanyApiEntity;
  users: any[];
  site: {
    id: number;
    name: string;
    monitorSiteId: string;
    timeZoneRef: string;
  };
}

export interface NotificationGroupsQueryResponse {
  userGroups: NotificationGroupApiEntity[];
}

export const notificationGroupDeserialize = (ss: NotificationGroupApiEntity): NotificationGroup => ({
  ...ss,
});
