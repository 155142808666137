import { None } from 'monet';

import { AssetsListFilter } from '../entities';

import { AssetsListFilterApiEntity } from './entities';

export const assetListingFilterSerialize = (filter: AssetsListFilter = { updatedAt: None() }): AssetsListFilterApiEntity => {
  const dsFilter: AssetsListFilterApiEntity = {
    name: filter.name,
  };
  return dsFilter;
};
