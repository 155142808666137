import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { RawNotification } from '../../entities';
import { RawNotificationsListingState } from '../entities';

/**
 * Entities SerDes
 */

const rawNotificationSerialize = (ds: RawNotification) => {
  return {
    updatedAt: ds.updatedAt.toISOString(),
    id: ds.id,
    provider: ds.provider,
    message: ds.message,
    status: ds.status,
    sender: ds.sender,
    recipient: ds.recipient,
  };
};

const rawNotificationDeserialize = (dss: any): RawNotification => ({
  updatedAt: moment(dss.updatedAt).toDate(),
  id: dss.id,
  provider: dss.provider,
  message: dss.message,
  status: dss.status,
  sender: dss.sender,
  recipient: dss.recipient,
});

/**
 * Transformations
 */

export const createRawNotificationsListingTransform = (
  logger: Logger = logdown('redux-persist:transform:rawNotificationsListing'),
): Transform<RawNotificationsListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key /*, state*/) => {
      const result = {
        ...subState,
        sort: subState.sort.orUndefined(),
        rawNotifications: subState.rawNotifications.map((rawNotifications) => rawNotifications.map(rawNotificationSerialize)).orUndefined(),
        exportedRawNotifications: subState.rawNotifications.map((rawNotifications) => rawNotifications.map(rawNotificationSerialize)).orUndefined(),
        filter: subState.filter
          .map((filter) => ({
            ...filter,
            ...(filter.updatedAt ? { updatedAt: filter.updatedAt.orUndefined() } : undefined),
          }))
          .orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialize alerts=> (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key /*, rawState*/) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize alerts=> (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        sort: Maybe.fromUndefined(state.sort),
        rawNotifications: Maybe.fromUndefined(state.rawNotifications).map((alerts) => alerts.map(rawNotificationDeserialize)),
        exportedRawNotifications: Maybe.fromUndefined(state.rawNotifications).map((alerts) => alerts.map(rawNotificationDeserialize)),
        // filter: Maybe.fromUndefined(state.filter),
        filter: state.filter
          ? Maybe.fromUndefined({
              ...(state.filter as any),
              updatedAt: Maybe.fromUndefined((state.filter as any).updatedAt),
            })
          : Maybe.None(),
        error: Maybe.fromUndefined(state.error).map((state) => new Error(state)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['rawNotificationsListing'] },
  );
