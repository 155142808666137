import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { AssetMaintenanceDialogActionType, AssetMaintenanceDialogAction } from './actions';
import { ASSET_MAINTENANCE_INITIAL_STATE } from './constants';
import { AssetMaintenanceDialogState } from './entities';

export const assetMaintenanceDialogReducer: Reducer<AssetMaintenanceDialogState, AssetMaintenanceDialogAction> = (
  state = ASSET_MAINTENANCE_INITIAL_STATE.assetMaintenanceDialogState,
  action,
) => {
  switch (action.type) {
    case AssetMaintenanceDialogActionType.OPEN_ASSET_MAINTENANCE_DIALOG:
      return {
        ...state,
        isOpen: true,
        asset: action.asset,
        alert: action.alert,
        acknowledgeAlert: action.acknowledgeAlert,
        startDate: Maybe.None(),
        endDate: Maybe.None(),
      };
    case AssetMaintenanceDialogActionType.UPDATE_ASSET_MAINTENANCE_DIALOG_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case AssetMaintenanceDialogActionType.UPDATE_ASSET_MAINTENANCE_DIALOG_PROGRESS_STATE:
      return {
        ...state,
        progressState: action.assetMaintenanceProgressState,
      };
    case AssetMaintenanceDialogActionType.CLOSE_ASSET_MAINTENANCE_DIALOG:
      return {
        ...ASSET_MAINTENANCE_INITIAL_STATE.assetMaintenanceDialogState,
      };
    default:
      return state;
  }
};
