// import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Transform, createTransform } from 'redux-persist';

import { AlertNotificationsListingState } from '../entities';

import { alertNotificationsListingFilterDeserialize, alertNotificationsListingFilterSerialize } from './serdes';

export const alertNotificationsListingTransform = (): // logger: Logger = logdown('redux-persist:transform:alertNotificationsListingTransform'),
Transform<AlertNotificationsListingState, any> =>
  createTransform(
    (subState /*, key, state*/) => {
      const result = {
        ...subState,
        alert: subState.alert.orUndefined(),
        notification: subState.notification.orUndefined(),
        commentEditing: subState.commentEditing.orUndefined(),
        notifications: subState.notifications.orUndefined(),
        filter: subState.filter.map((filter) => alertNotificationsListingFilterSerialize(filter)).orUndefined(),
        sort: subState.sort.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state /*, key, rawState*/) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        alert: Maybe.fromUndefined(state.alert),
        notification: Maybe.fromUndefined(state.notification),
        commentEditing: Maybe.fromUndefined(state.commentEditing),
        isInProgress: false,
        notifications: Maybe.fromUndefined(state.notifications),
        filter: Maybe.fromUndefined(state.filter).map((filter) => alertNotificationsListingFilterDeserialize(filter)),
        sort: Maybe.fromUndefined(state.sort),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['alertNotificationsListing'] },
  );
