import { Maybe, None } from 'monet';

import { parseDateRangeValue } from '../../../utils/dateRangeFilter';
import { RawNotificationsListFilter } from '../../entities';

import { RawNotificationsListFilterApiEntity } from './entities';

export const rawNotificationListingFilterSerialize = (
  filter: RawNotificationsListFilter = { updatedAt: None() },
): RawNotificationsListFilterApiEntity => {
  const dsFilter: RawNotificationsListFilterApiEntity = {
    from: filter.from,
    to: filter.to,
    providers: filter.providers,
    message: filter.message,
    status: filter.status,
  };
  if (filter.updatedAt) {
    dsFilter.from = filter.updatedAt
      .flatMap((updatedAt) => {
        const fromDate = parseDateRangeValue(updatedAt)?.from;
        if (fromDate instanceof Date) {
          return Maybe.fromUndefined(fromDate.toISOString());
        }
        return Maybe.some('');
      })
      .orUndefined();

    dsFilter.to = filter.updatedAt
      .flatMap((updatedAt) => {
        const toDate = parseDateRangeValue(updatedAt)?.to;
        if (toDate instanceof Date) {
          return Maybe.fromUndefined(toDate.toISOString());
        }
        return Maybe.some('');
      })
      .orUndefined();
  }
  return dsFilter;
};
