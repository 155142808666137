import * as React from 'react';
import { Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';

export enum IconType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
  SPINNER = 'spinner',
}

export interface NotificationProps extends React.HTMLAttributes<HTMLElement> {
  icon?: IconType;
  title?: string;
  text?: string;
  onCloseClick?: React.MouseEventHandler<any>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (props: NotificationProps) => {
  let iconComponent: React.ReactNode = props.icon;
  if (iconComponent === IconType.SPINNER) {
    iconComponent = <Spinner size="sm" />;
  }
  return (
    <Toast onClick={props.onCloseClick} fade={true}>
      <ToastHeader
        icon={iconComponent}
        toggle={() => {
          return;
        }}
      >
        {props.title}
      </ToastHeader>
      <ToastBody>{props.text}</ToastBody>
    </Toast>
  );
};
