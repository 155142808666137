import { Reducer } from 'redux';

import { ActionObject, ActionType, NotificationState } from './actions';

export const notificationsReducer: Reducer<NotificationState[], ActionObject> = (state = [], action): NotificationState[] => {
  switch (action.type) {
    case ActionType.NOTIFICATION_SHOW:
      return state.concat([{ id: action.id, icon: action.icon, title: action.title, text: action.text }]);
    case ActionType.NOTIFICATION_DISMISS:
      return state.filter((n) => n.id !== action.id);
    default:
      return state;
  }
};
