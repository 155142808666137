import logdown from 'logdown';
import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Route, Switch, RouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { Alerts } from '../alerts';
import { Assets } from '../assets';
import { DataDogDashboard } from '../dd';
import { Events } from '../events';
import { Home } from '../home';
import { MonitoringSystems } from '../monitoring-systems';
import { Navbar, routesList, Sidebar, setActiveRoute, toggleSideBarIsOpen } from '../navigation';
import { NotificationsStatus } from '../notifications-status';
import { Profile } from '../user';

const pages: { [param: string]: any } = {
  Home,
  Profile,
  Events,
  Alerts,
  NotificationsStatus,
  Assets,
  MonitoringSystems,
  DataDogDashboard,
};

const logger = logdown('component:Dashboard');

class Component extends React.Component<Props, unknown> {
  protected updateNavigation() {
    if (this.props.location) {
      const pathName = this.props.location.pathname;
      const route = routesList.find((route) => `${route.layout}${route.path}` === pathName);
      if (route) {
        this.props.setActiveRoute(route.name);
        this.props.toggleSideBarIsOpen(false);
      }
    }
  }
  componentDidMount() {
    logger.log(`Component did mount ${JSON.stringify(this.props.location)}`);

    this.updateNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    logger.log(`Component did update ${JSON.stringify(prevProps.location)} ${JSON.stringify(this.props.location)}`);

    const isPathChanged = prevProps.location !== this.props.location;

    logger.log(`Path changed: ${isPathChanged}`);
    if (isPathChanged) {
      this.updateNavigation();
    }
  }

  public render() {
    return (
      <div className={this.props.sideBar.isOpen ? 'nav-open' : ''}>
        <Sidebar />
        <div className="main-panel">
          <Navbar {...this.props} />
          <Switch>
            {routesList.map((r) => (
              <Route key={r.name} exact={true} path={r.layout + r.path} component={pages[r.componentName]} />
            ))}
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      setActiveRoute,
      toggleSideBarIsOpen,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sideBar: state.sideBar,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
