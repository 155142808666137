import { Site, SitesGroup } from '../redux/entities';
import { CompanyApiEntity } from '../user';

export interface SiteApiEntity {
  siteId: number;
  siteName: string;
  monitorSiteId: string;
  timeZoneRef: string;
}

export interface SitesGroupApiEntity {
  id: number;
  name: string;
  sites: SiteApiEntity[];
  company: CompanyApiEntity;
}

export interface SitesListQueryResponse {
  sites: SiteApiEntity[];
}

export interface SitesGroupsListQueryResponse {
  sitesGroups: SitesGroupApiEntity[];
}

export const siteDeserialize = (ss: SiteApiEntity): Site => ({
  id: ss.siteId,
  name: ss.siteName,
  monitorSiteId: ss.monitorSiteId,
  timeZoneRef: ss.timeZoneRef,
});

export const sitesGroupDeserialize = (ss: SitesGroupApiEntity): SitesGroup => ({
  id: ss.id,
  name: ss.name,
  sites: ss.sites.map(siteDeserialize),
  company: ss.company,
});
