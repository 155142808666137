import { RawNotification, RawNotificationStatus } from '@serverfarm/nocd-commons';
import moment from 'moment';

export interface RawNotificationsListFilterApiEntity {
  from?: string;
  to?: string;
  fromUpdated?: string;
  toUpdated?: string;
  providers?: string[];
  message?: string;
  status?: string;
}

export interface RawNotificationApiEntity {
  updatedAt: string;
  id: string;
  provider: string;
  message: string;
  sender: string;
  recipient: string;
  status: RawNotificationStatus;
}

export const rawNotificationDeserialize = (dss: RawNotificationApiEntity): RawNotification => {
  return {
    updatedAt: moment(dss.updatedAt).toDate(),
    id: dss.id,
    provider: dss.provider,
    message: dss.message,
    status: dss.status,
    sender: dss.sender,
    recipient: dss.recipient,
  };
};

export interface RawNotificationsListQueryResponse {
  rawNotifications: RawNotificationApiEntity[];
  page: number;
  perPage: number;
  total: number;
}
