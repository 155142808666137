/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */

// @ts-ignore
const env = ((typeof window !== 'undefined' && window.__ENV_VARS__) || (process && process.env) || {}) as { [name: string]: string };

const {
  REACT_APP_NODE_DEBUG = '*',
  REACT_APP_OAUTH_CLIENT_URL = 'http://localhost:3002',
  REACT_APP_SERVICES_AUTH_API_URL = 'http://localhost:3002',
  REACT_APP_SERVICES_CRAWFISH_API_URL = 'http://localhost:4000',
  REACT_APP_DD_WIDGETS_DASHBOARD_URL = 'https://p.datadoghq.com/sb/oejcga6aj657weku-919dcf74248b365790f962d3df12022a',
  REACT_APP_VERSION,
  ENV_NAME,
} = env;

export default {
  debug: REACT_APP_NODE_DEBUG,
  oauth: {
    client: {
      url: REACT_APP_OAUTH_CLIENT_URL,
    },
  },
  services: {
    auth: {
      api: {
        url: REACT_APP_SERVICES_AUTH_API_URL,
      },
    },
    crawfish: {
      api: {
        url: REACT_APP_SERVICES_CRAWFISH_API_URL,
      },
    },
    dd: {
      widgets: {
        dashboard: {
          url: REACT_APP_DD_WIDGETS_DASHBOARD_URL,
        },
      },
    },
  },
  client: { version: REACT_APP_VERSION },
  envName: ENV_NAME,
};
