import moment from 'moment-timezone';
import { Maybe } from 'monet';
import React, { HTMLAttributes } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { doesTargetExist } from '../../services/utilities';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const DATE_TIME_FORMAT_DEFAULT = `${DATE_FORMAT_DEFAULT} ${TIME_FORMAT_DEFAULT}`;

export interface DateTimeRenderProps extends HTMLAttributes<any> {
  date?: Maybe<Date> | Date | string;
  format?: string;
  timeZoneRef?: string;
  isUtc?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DateTimeRender = (props: DateTimeRenderProps) => {
  const { date, format = DATE_TIME_FORMAT_DEFAULT, timeZoneRef, id, className, isUtc = false } = props;
  const maybeDate = date && Maybe.isInstance(date) ? date : Maybe.fromUndefined(date);

  return (
    <span>
      <span id={id} className={className}>
        {maybeDate
          .map((date) => {
            const momentDate = moment(date);
            return timeZoneRef ? momentDate.tz(timeZoneRef).format(format) : isUtc ? momentDate.utc().format(format) : momentDate.format(format);
          })
          .getOrElse('N/A')}
      </span>
      {doesTargetExist(`${id}`) && (
        <UncontrolledTooltip target={`${id}`}>
          {timeZoneRef ? `Site local ${timeZoneRef} time` : isUtc ? 'UTC time' : 'Your local time'}
        </UncontrolledTooltip>
      )}
    </span>
  );
};
