import logdown, { Logger } from 'logdown';
import { Maybe, Some } from 'monet';
import { Dispatch } from 'redux';

import { AlertWorkLogFilter } from '../..';
import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { parseDateRangeValue } from '../../../utils/dateRangeFilter';
import { Alert, ListSort } from '../../entities';
import {
  alertWorkLogAddReceive,
  alertWorkLogAddReceiveError,
  alertWorkLogAddRequest,
  alertWorkLogClose,
  alertWorkLogEdit,
  alertWorkLogFilterUpdate,
  alertWorkLogIsSiteAwareEdit,
  alertWorkLogOpen,
  alertWorkLogReceive,
  alertWorkLogReceiveError,
  alertWorkLogRequest,
  alertWorkLogSortUpdate,
} from '../../redux/alert-worklog/actions';

import { alertWorkLogDeserialize, AlertWorkLogListQueryResponse } from './entities';

export const openAlertWorkLog =
  (alert: Alert) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertWorkLogOpen(alert));
  };

export const closeAlertWorkLogList =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertWorkLogClose());
  };

export const fetchAlertWorkLog =
  (
    alertId: string,
    filter: Maybe<AlertWorkLogFilter>,
    sort: Maybe<ListSort>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('reports:data:actions:fetchAlertWorkLog'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertWorkLogRequest(filter, sizePerPage, sort));

    if (!alertId) {
      throw new Error('Alert Work Log filter should have alertId defined');
    }

    logger.log(`filter => ${filter}`);

    const { from, to, userName, workLog, workLogMetadataIsSiteAware } = filter
      .map((f) => {
        return {
          alertId,
          ...(f.createdAt
            ? {
                from: f.createdAt.flatMap((createdAt) => Maybe.fromUndefined(parseDateRangeValue(createdAt)?.from?.toISOString())).orUndefined(),
                to: f.createdAt.flatMap((createdAt) => Maybe.fromUndefined(parseDateRangeValue(createdAt)?.to?.toISOString())).orUndefined(),
              }
            : undefined),
          userName: f.userName,
          workLog: f.workLog,
          workLogMetadataIsSiteAware: f.workLogMetadataIsSiteAware,
        };
      })
      .getOrElse({} as any);

    axios({
      method: 'get',
      url: `${config.services.crawfish.api.url}/alert/${alertId}/worklog`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        from,
        to,
        page: page + 1,
        limit: sizePerPage,
        sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
        userName,
        workLog,
        workLogMetadataIsSiteAware,
      },
    })
      .then((response) => response.data as AlertWorkLogListQueryResponse)
      .then((data) => {
        dispatch(alertWorkLogReceive(data.workLogs.map(alertWorkLogDeserialize), page, data.total));
      })
      .catch((err) => {
        dispatch(alertWorkLogReceiveError(err));
      });
  };

export const updateAlertWorkLogFilter =
  (
    filter: Maybe<AlertWorkLogFilter>,
    page: number,
    sizePerPage: number,
    logger: Logger = logdown('reports:data:actions:updateAlertWorkLogListFilter'),
  ) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
    dispatch(alertWorkLogFilterUpdate(filter, page, sizePerPage));
  };

export const updateAlertWorkLog =
  (alert: Maybe<Alert>, workLog: Maybe<string>, logger: Logger = logdown('reports:data:actions:updateAlertWorkLog')) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertWorkLogEdit(alert, workLog));
  };

export const updateAlertWorkLogIsSiteAware =
  (alert: Maybe<Alert>, isSiteAware: Maybe<boolean> = Some(true), logger: Logger = logdown('reports:data:actions:updateAlertWorkLogIsSiteAware')) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(alertWorkLogIsSiteAwareEdit(alert, isSiteAware));
  };

export const updateAlertWorkLogPage =
  (page: number, sizePerPage: number, logger: Logger = logdown('reports:data:actions:updateAlertWorkLogListingPages')) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    const filter = getState().alertWorkLogListing.filter;
    dispatch(alertWorkLogFilterUpdate(filter, page, sizePerPage));
  };

export const updateAlertWorkLogSort =
  (listingSort: Maybe<ListSort>, logger: Logger = logdown('reports:data:actions:updateAlertWorkLogListingSort')) =>
  (dispatch: Dispatch<any>): void => {
    logger.log(`listingSort => ${JSON.stringify(listingSort.orUndefined())}`);
    dispatch(alertWorkLogSortUpdate(listingSort));
  };

// export const openAlertWorkLogComments =
//   (notification: Notification) =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(alertWorkLogCommentsOpen(notification));
//   };

// export const closeAlertWorkLogComments =
//   () =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(alertWorkLogCommentsClose());
//   };

// export const editNotificationComment =
//   (comment: string) =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(notificationEditComment(comment));
//   };

export const addWorkLogEntry =
  (alertId: string, workLog?: string, isSiteAware = true) =>
  (dispatch: Dispatch<any>, getState: () => State): void => {
    if (!workLog) {
      return;
    }

    dispatch(alertWorkLogAddRequest(workLog, isSiteAware));

    axios({
      method: 'POST',
      url: `${config.services.crawfish.api.url}/alert/${alertId}/worklog`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        workLog,
        metadata: {
          isSiteAware,
        },
      },
    })
      .then((response) => response.data)
      .then((/*data*/) => {
        dispatch(alertWorkLogAddReceive());
      })
      .catch((err) => {
        dispatch(alertWorkLogAddReceiveError(err));
      });
  };

// export const ackNotification =
//   (notification: Notification) =>
//   (dispatch: Dispatch<any>, getState: () => State): void => {
//     const user = getState().user;
//     dispatch(ackNotificationRequest(notification));

//     axios({
//       method: 'POST',
//       url: `${config.services.crawfish.api.url}/notifications/${notification.id}/acknowledge`,
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       data: {},
//     })
//       .then((response) => response.data)
//       .then((/*data*/) => {
//         dispatch(ackNotificationReceive(user.profile.fullName));

//         const { alert, filter, page, sizePerPage, sort } = getState().alertWorkLogListing;
//         if (alert.isJust()) {
//           dispatch(fetchAlertWorkLog(alert.some(), filter, sort, page, sizePerPage));
//         } else {
//           const { filter: alertFilter, sort: alertSort, page: alertPage, sizePerPage: alertSizePerPage } = getState().alertsListing;
//           dispatch(fetchAlerts(alertFilter, alertPage, alertSizePerPage, alertSort));
//         }
//       })
//       .catch((err) => {
//         dispatch(ackNotificationReceiveError(err));
//       });
//   };

// export const toggleNotificationSelect =
//   (notificationId: string) =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(toggleNotificationSelected(notificationId));
//   };

// export const toggleAllNotificationSelect =
//   (selected: boolean) =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(toggleAllNotificationSelected(selected));
//   };

// export const bulkAckNotifications =
//   (notificationIds: string[]) =>
//   (dispatch: Dispatch<any>): void => {
//     dispatch(bulkAckNotificationRequest());

//     axios({
//       method: 'POST',
//       url: `${config.services.crawfish.api.url}/notifications/acknowledge`,
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       data: {
//         notificationIds,
//       },
//     })
//       .then((response) => response.data)
//       .then((/*data*/) => {
//         dispatch(bulkAckNotificationReceive(notificationIds));
//       })
//       .catch((err) => {
//         dispatch(bulkAckNotificationReceiveError(err));
//       });
//   };

// let resetGlowTimeout: any;
// export const alertWorkLogProcessNotification =
//   (notification: AlertWorkLog) =>
//   (dispatch: Dispatch<any>, getState: () => State): void => {
//     if (getState().alertsListing.isInProgress) {
//       return;
//     }
//     dispatch(alertWorkLogReceiveNotification(notification));
//     if (resetGlowTimeout) {
//       clearTimeout(resetGlowTimeout);
//     }
//     resetGlowTimeout = setTimeout(() => {
//       if (getState().alertsListing.isInProgress) {
//         return;
//       }
//       dispatch(alertWorkLogResetGlow(notification));
//     }, 1000);
//   };
