import { Maybe } from 'monet';
import { combineReducers, Reducer } from 'redux';

import { ActionType, ColleaguesFetchAction, CompaniesFetchActionObject, ProfileActionObject } from './actions';
import { INITIAL_USER_STATE } from './constants';
import { ColleaguesListingState, CompaniesListingState, UserProfile, UserState } from './entities';

const isLoggedInReducer: Reducer<boolean, any> = (state = false, action): boolean => {
  switch (action.type) {
    case ActionType.USER_LOGGED_IN:
      return true;
    case ActionType.USER_LOGGED_OUT:
      return false;
    case ActionType.RESET_USER:
      return false;
    default:
      return state;
  }
};

const isLoggingInReducer: Reducer<boolean, any> = (state = false, action): boolean => {
  switch (action.type) {
    case ActionType.USER_LOGIN_ATTEMPT:
      return true;
    case ActionType.USER_LOGIN_ATTEMPT_COMPLETE:
      return false;
    case ActionType.RESET_USER:
      return false;
    default:
      return state;
  }
};

const profileReducer: Reducer<UserProfile, ProfileActionObject> = (state = INITIAL_USER_STATE.userProfile, action): UserProfile => {
  switch (action.type) {
    case ActionType.RECEIVE_PROFILE:
      return {
        ...state,
        ...action.profile,
        fullName: `${action.profile.firstName} ${action.profile.lastName}`,
      };
    case ActionType.USER_LOGGED_OUT:
    case ActionType.RESET_USER:
      return INITIAL_USER_STATE.userProfile;
    default:
      return state;
  }
};

export const companiesListingReducer: Reducer<CompaniesListingState, CompaniesFetchActionObject> = (
  state = INITIAL_USER_STATE.companiesListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_COMPANIES:
      return {
        ...state,
        isInProgress: true,
        companies: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_COMPANIES:
      return {
        isInProgress: false,
        companies: Maybe.fromUndefined(action.companies),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_COMPANIES_ERROR:
      return {
        isInProgress: false,
        companies: Maybe.None(),
        error: Maybe.fromUndefined(action.error),
      };
    case ActionType.RESET_USER:
      return INITIAL_USER_STATE.companiesListingState;
    default:
      return state;
  }
};

export const colleaguesListingReducer: Reducer<ColleaguesListingState, ColleaguesFetchAction> = (
  state = INITIAL_USER_STATE.colleaguesListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_COLLEAGUES:
      return {
        ...state,
        isInProgress: true,
        colleagues: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_COLLEAGUES:
      return {
        isInProgress: false,
        colleagues: Maybe.fromUndefined(action.colleagues),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_COLLEAGUES_ERROR:
      return {
        isInProgress: false,
        colleagues: Maybe.None(),
        error: Maybe.fromUndefined(action.error),
      };
    case ActionType.RESET_USER:
      return INITIAL_USER_STATE.colleaguesListingState;
    default:
      return state;
  }
};

export const userReducer = combineReducers<UserState, ProfileActionObject>({
  isLoggingIn: isLoggingInReducer,
  isLoggedIn: isLoggedInReducer,
  profile: profileReducer,
});
