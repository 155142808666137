/**
 * Action types
 */

import { Action } from 'redux';

import { ErrorAction } from '../actions';
import { Site } from '../entities';

export enum ActionType {
  REQUEST_SITES = 'REQUEST_SITES',
  RECEIVE_SITES = 'RECEIVE_SITES',
  RECEIVE_SITES_ERROR = 'RECEIVE_SITES_ERROR',

  REQUEST_SITES_GROUPS = 'REQUEST_SITES_GROUPS',
  RECEIVE_SITES_GROUPS = 'RECEIVE_SITES_GROUPS',
  RECEIVE_SITES_GROUPS_ERROR = 'RECEIVE_SITES_GROUPS_ERROR',

  RESET_SITES_GROUPS = 'RESET_SITES_GROUPS',
  RESET_SITES = 'RESET_SITES',
}

type SitesRequestAction = Action<ActionType>;

interface SitesReceiveAction extends Action<ActionType> {
  sites: Site[];
}

type SitesReceiveErrorAction = ErrorAction<ActionType>;

type SitesResetAction = Action<ActionType>;

export type SitesFetchAction = SitesRequestAction & SitesReceiveAction & SitesReceiveErrorAction & SitesResetAction;

type SitesGroupsRequestAction = Action<ActionType>;

interface SitesGroupsReceiveAction extends Action<ActionType> {
  sitesGroups: any;
}

type SitesGroupsReceiveErrorAction = ErrorAction<ActionType>;

type SitesGroupsResetAction = Action<ActionType>;

export type SitesGroupsFetchAction = SitesGroupsRequestAction & SitesGroupsReceiveAction & SitesGroupsReceiveErrorAction & SitesGroupsResetAction;

export const sitesRequest = (): SitesRequestAction => ({
  type: ActionType.REQUEST_SITES,
});

export const sitesReceive = (sites: Site[]): SitesReceiveAction => ({
  type: ActionType.RECEIVE_SITES,
  sites,
});

export const sitesReceiveError = (error: Error): SitesReceiveErrorAction => ({
  type: ActionType.RECEIVE_SITES_ERROR,
  error,
});

export const sitesReset = (): SitesResetAction => ({
  type: ActionType.RESET_SITES,
});

export const sitesGroupsRequest = (): SitesGroupsRequestAction => ({
  type: ActionType.REQUEST_SITES_GROUPS,
});

export const sitesGroupsReceive = (sitesGroups: any): SitesGroupsReceiveAction => ({
  type: ActionType.RECEIVE_SITES_GROUPS,
  sitesGroups,
});

export const sitesGroupsReceiveError = (error: Error): SitesGroupsReceiveErrorAction => ({
  type: ActionType.RECEIVE_SITES_GROUPS_ERROR,
  error,
});

export const sitesGroupsReset = (): SitesResetAction => ({
  type: ActionType.RESET_SITES_GROUPS,
});
