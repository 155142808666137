import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { SITES_INITIAL_STATE } from '../constants';
import { SitesGroupsListingState, SitesListingState } from '../entities';

import { ActionType, SitesFetchAction, SitesGroupsFetchAction } from './actions';

export const sitesListingReducer: Reducer<SitesListingState, SitesFetchAction> = (state = SITES_INITIAL_STATE.sitesListingState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_SITES:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_SITES:
      return {
        ...state,
        isInProgress: false,
        sites: Maybe.Some(action.sites.sort((a, b) => a.name.localeCompare(b.name))),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_SITES_ERROR:
      return {
        ...state,
        isInProgress: false,
        sites: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case ActionType.RESET_SITES:
      return SITES_INITIAL_STATE.sitesListingState;
    default:
      return state;
  }
};

export const sitesGroupsListingReducer: Reducer<SitesGroupsListingState, SitesGroupsFetchAction> = (
  state = SITES_INITIAL_STATE.sitesGroupsListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_SITES_GROUPS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_SITES_GROUPS:
      return {
        ...state,
        isInProgress: false,
        sitesGroups: Maybe.Some(action.sitesGroups),
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_SITES_GROUPS_ERROR:
      return {
        ...state,
        isInProgress: false,
        sites: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case ActionType.RESET_SITES_GROUPS:
      return SITES_INITIAL_STATE.sitesGroupsListingState;
    default:
      return state;
  }
};
