import { Maybe, None } from 'monet';

import { SitesGroupsListingState } from '../alerts';

import { DialogInProgressState, InProgressState } from './actions';
import { SitesListingState } from './entities';

export const IN_PROGRESS_INITIAL_STATE: InProgressState = {
  isInProgress: false,
  error: Maybe.None(),
};

export const DIALOG_IN_PROGRESS_INITIAL_STATE: DialogInProgressState = {
  isOpen: false,
  progressState: None(),
};

export const PAGINATION_INITIAL_STATE = {
  page: 0,
  total: 0,
  sizePerPage: 10,
};

export const sitesListingState: SitesListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  sites: Maybe.None(),
};

export const sitesGroupsListingState: SitesGroupsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  sitesGroups: Maybe.None(),
};

export const SITES_INITIAL_STATE = {
  sitesListingState,
  sitesGroupsListingState,
};
