import { Maybe } from 'monet';

import { NocInCommandRequestDialogState, StakeholderNotificationDialogState } from '..';
import { DIALOG_IN_PROGRESS_INITIAL_STATE, IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';
import { DateRange } from '../../utils/dateRangeFilter';

import {
  AlertNotificationsListingState,
  AlertsListingState,
  AlertsTrackingTotalCountState,
  AlertWorkLogAddingState,
  AlertWorkLogListingState,
  NotificationGroupsListingState,
  AlertNotificationsFilterState,
  AlertSourceInformationDialogState,
  AssetsInMaintenanceDialogState,
} from './entities';

const alertsListingState: AlertsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  alerts: Maybe.None(),
  exportedAlerts: Maybe.None(),
  filter: Maybe.Some({ updatedAt: Maybe.None<DateRange>() }),
  hiddenColumns: [],
  sort: Maybe.None(),
  notification: Maybe.None(),
  notificationsNotShown: 0,
  selectedAlert: '',
  id: Maybe.None(),
};

const stakeholderNotificationDialogState: StakeholderNotificationDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  alert: Maybe.None(),
  notificationGroup: Maybe.None(),
  subject: Maybe.None(),
  content: Maybe.None(),
};

const alertSourceInformationDialogState: AlertSourceInformationDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  alert: Maybe.None(),
  subject: Maybe.None(),
  content: Maybe.None(),
};

const assetsInMaintenanceDialogState: AssetsInMaintenanceDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
};

const nocInCommandRequestDialogState: NocInCommandRequestDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  alert: Maybe.None(),
  assignee: Maybe.None(),
  alertDate: Maybe.None(),
  acknowledgedDate: Maybe.None(),
  notificationGroup: Maybe.None(),
  requestPriority: Maybe.None(),
  company: Maybe.None(),
  notes: Maybe.None(),
};

const alertsTrackingTotalCountState: AlertsTrackingTotalCountState = {
  ...IN_PROGRESS_INITIAL_STATE,
  total: Maybe.None(),
};

const alertWorkLogListingState: AlertWorkLogListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  alert: Maybe.None(),
  workLogs: Maybe.None(),
  filter: Maybe.None(),
  sort: Maybe.None(),
};

const alertWorkLogAddingState: AlertWorkLogAddingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  alert: Maybe.None(),
  workLog: Maybe.None(),
  isSiteAware: Maybe.Some(true),
};

const alertNotificationsListingState: AlertNotificationsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  alert: Maybe.None(),
  notification: Maybe.None(),
  notificationComment: Maybe.None(),
  commentEditing: Maybe.None(),
  notifications: Maybe.None(),
  filter: Maybe.None(),
  sort: Maybe.None(),
  notificationsNotShown: 0,
};

const alertNotificationsFiltersState: AlertNotificationsFilterState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  filter: Maybe.None(),
  sort: Maybe.None(),
};

const notificationGroupsListingState: NotificationGroupsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  notificationGroups: Maybe.None(),
};

export const ALERT_INITIAL_STATE = {
  alertsListingState,
  stakeholderNotificationDialogState,
  alertSourceInformationDialogState,
  nocInCommandRequestDialogState,
  alertsTrackingTotalCountState,
  alertNotificationsListingState,
  alertWorkLogListingState,
  alertWorkLogAddingState,
  alertNotificationsFiltersState,
};

export const NOTIFICATION_GROUPS_INITIAL_STATE = {
  notificationGroupsListingState,
};

export const ASSETS_IN_MAINTENANCE_INITIAL_STATE = {
  assetsInMaintenanceDialogState,
};
